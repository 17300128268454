import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ProjectMoreOptionsContainer } from './ProjectMoreOptionsStyles'

export default function ProjectMoreOptions() {

  return (
    <ProjectMoreOptionsContainer>
      <IframeConnect />
    </ProjectMoreOptionsContainer>
  )
}
