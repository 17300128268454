import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore, persistReducer, createMigrate, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import coreReducer from './coreSlice'
import clientsReducer from './clientsSlice'
import projectsReducer from './projectsSlice'
import stepsReducer from './stepsSlice'
import channelsReducer from './channelsSlice'
import sessionReducer from './sessionSlice';
import managerReducer from './managerSlice';
import usersReducer from './usersSlice';

const rootReducer = combineReducers({
  session: sessionReducer,
  core: coreReducer,
  manager: managerReducer,
  clients: clientsReducer,
  projects: projectsReducer,
  steps: stepsReducer,
  channels: channelsReducer,
  users: usersReducer
})

const migrations = {
  1: (state: any) => {
    const { stepsList, ...rest } = state.steps
    return {
      ...rest,
      stepsList: { data: stepsList, loading: false }
    }
  }
}

const persistConfig = {
  key: 'root',
  storage,
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: ['users']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch