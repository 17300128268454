import { routes } from '@/routes/Routes'
import Board from '@/components/board/Board'
import SubMenu from '@/components/menu/submenu/SubMenu'
import { tabTypes } from '@/constants/const';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { setCurrentTab } from '@/store/coreSlice';
import Subscriptions from './Subscriptions';

interface SubscriptionsLayoutProps {
  toggleTab: number;
}

export enum ToggleSubscriptionsEnum {
  Shopify
}

export default function SubscriptionsLayout({ toggleTab }: SubscriptionsLayoutProps) {

  const dispatch = useDispatch()
  const { currentTab } = useSelector((state: RootState) => state.core)
  const elements = [
    {
      name: 'Shopify',
      component: <Subscriptions />,
      url: routes.subscriptions
    },
  ]
  const { component } = elements[toggleTab]

  useEffect(() => {
    if (currentTab !== tabTypes.SUBSCRIPTIONS)
      dispatch(setCurrentTab(tabTypes.SUBSCRIPTIONS))
  }, [dispatch])

  return (
    <Board
      subMenu={<SubMenu
        currentSubTab={toggleTab}
        leftOption={<></>}
        elements={elements}
      />} >
      {component}
    </Board>
  )
}
