import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { StepMoreOptionsContainer } from './StepMoreOptionsStyles'

export default function StepMoreOptions() {

  return (
    <StepMoreOptionsContainer>
      <IframeConnect />
    </StepMoreOptionsContainer>
  )
}
