import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes/Routes";
import { RootState } from "@/store/store";

export function useAccountSelection() {
  const navigate = useNavigate()
  const { currentProject } = useSelector((state: RootState) => state.core);
  const isMenuHidden = !currentProject

  const redirectToHome = () => {
    navigate(routes.home())
  }

  const checkAndRedirect = (tabName: string = '') => {
    if (isMenuHidden && tabName !== '') {
      redirectToHome()
    }
  }

  return { checkAndRedirect };
}