import { css, styled } from "styled-components";
import { Label, Link, Span } from "@/components/text/Text";
import { BaseIcon } from "@/components/icons/Icons";

export const OverviewContainer = styled.div`
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const Title = styled(Label)`

`

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: min-content;
  padding-bottom: 2rem;
  gap: 1.5rem;
`

export const StepItemContainer = styled.div`
 display: flex;
 justify-content: space-between;
 background-color: var(--white);
 border-radius: 0.25rem;
 padding-right: 1rem;
 
 &:hover {
    background-color: var(--secondary-lighter);
  }
`

export const StepItemLink = styled(Link)`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.25rem;
  text-align: left;
  color: var(--secondary);
  gap: 0.25rem;
`

export const StepItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: var(--white);
  padding: 1rem;
  border-radius: 0.25rem;
  text-align: left;
  color: var(--secondary);
  gap: 0.25rem;

  &:hover {
    background-color: var(--secondary-lighter);
  }
`


export const Tags = styled.div`
  display: flex;
  margin: 0.5rem 0;
  gap: 0.5rem;
`

export const Tag = styled(Span) <{ $backgroundColor?: string }>`
  padding: 0.2rem 0.5rem;
  background-color: ${props => props.$backgroundColor ?? 'var(--secondary-light)'} ;
  color: var(--white);
  border-radius: 0.25rem;
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  > img {
    width: 55px; 
    height: 55px; 
    object-fit: contain;
    border-radius: 0.25rem; 
    border: 1px solid var(--grey);
    background-color: var(--white);
  }
`

export const InfoItemEmtpy = styled(Span)`
  display: flex;
  height: 2rem;
  padding: 0.2rem 0.5rem;
  min-width: 12vw;
`

export const InfoItem = styled(Span)`
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 1px solid var(--grey);
  color: var(--main);
  border-radius: 0.25rem;
  justify-content: space-evenly;
  min-width: 12vw;
  background-color: var(--white);
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--grey);
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ActionButton = styled.div`
  color: var(--white);
  font-weight:  600;
  background-color: var(--secondary);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: var(--main);
  }
  `

interface DropdownListProps {
  $open?: boolean;
}
export const DropdownList = styled.div<DropdownListProps>`
  position: absolute;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Adjust transition timing */
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 3333;
  border: 1px solid var(--grey);
  border-radius: 0 0 0.625rem 0.625rem;
  background-color: var(--white);



  
  ${props =>
    props.$open &&
    css`
      opacity: 1;
      visibility: visible;
      max-height: 50vh;
    `}
`;

export const MoreOptionsContainer = styled.div`
  margin-left: 1rem;
`;

export const MoreOptionsElement = styled.div`

  &:hover {
      ${DropdownList} {
        opacity: 1;
        visibility: visible;
        max-height: 50vh;
      }
    }
`;



export const MoreOptionsText = styled(Span) <{ $stepper?: boolean }>`
  vertical-align: middle;
  
  ${(props) =>
    props.$stepper &&
    css`
      padding-left: 0.417vw;
    `}
`;

export const MoreOptions = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0.25rem 0.833vw;
  border: 1px solid var(--grey);
  border-radius: 0.625rem;
  justify-content: space-between;
  min-width: 10vw;
  max-width: 15vw;

  ${MoreOptionsText}, ${BaseIcon} {
    color: var(--main);
  }
`;

export const ChevronContainer = styled.div`
  padding-left: 0.625vw;
`;

export const ListItemContainer = styled.div`
 position: relative; 

 &:hover  ${MoreOptions} {
    border-radius: 0.625rem 0.625rem 0 0;
    background-color: var(--white);
  }
`;

interface ItemProps {
  onClick?: any;
  disabled?: boolean;
}
export const Item = styled(Link) <ItemProps>`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  left: 0;
  padding: 0.25rem 0.833vw;
  text-align: center;
  background-color: var(--white);
  border-radius: 0.625rem;
  min-width: 10vw;
  max-width: 15vw;

  ${props => !props.disabled && css`

  &:hover {
    background-color: var(--secondary-lighter);
  }
` }

${props => props.disabled && css`
  color: var(--grey);
  cursor: not-allowed;
` }
`;