import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { routes, urls } from "@/routes/Routes";
import { RootState } from "@/store/store";
import { setCurrentTab } from "@/store/coreSlice";
import { DocsIcon, FolderIcon, GearIcon, GridIcon, GuideIcon, HelperIcon, HomeIcon, LogOutIcon, UserGroupIcon } from "@/components/icons/Icons";
import { useSessionContext } from "@/contexts/SessionContext";
import { useRequestHandler } from "./useRequestHandler";
import { IApiUsersResponse, apiClient } from "@/api/extendedAPI";
import { IApiUsers } from "@/api/endpointsRoutes";
import { setUsers, setUsersLoading } from "@/store/usersSlice";


interface TabParameters {
  [key: string]: any;
}

type LeftNavType = 'Projects' | 'Users' | 'Guide' | 'Subscriptions' | 'Settings' | 'Log out' | 'Delete token' | 'Select a project'

interface LeftNavItem {
  name: LeftNavType;
  icon: ReactElement;
  visible: boolean;
}

export function useLeftNavigation(): [Array<TabParameters>, Array<TabParameters>, boolean, Dispatch<SetStateAction<boolean>>] {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler({ needsRedirect: false })
  const { currentTab, currentProject } = useSelector((state: RootState) => state.core)
  const isAdmin = session.data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));
  const isPartner = session.data.roles.find((role) => role?.toLowerCase().includes('partner'))
  const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false)

  const getUsers = () => {
    dispatch(setUsersLoading(true))
    apiClient.listUsers(session.data.accessToken).then(
      (users: IApiUsersResponse) => {
        const { data, error } = users
        if (error === null) {
          const newUsersList = data?.map((user: IApiUsers) => { return { id: user.UserGuid, firstName: user.Firstname, lastName: user.Lastname, email: user.Email } })
          dispatch(setUsers(newUsersList))
        } else {
          dispatch(setUsersLoading(false))
          if (error.status === 401) {
            // NAVIGATE TO LOGOUT AND GET A PARAMETER WITH THE CURRENT URL TO REDIRECT AFTER LOGIN 
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching users:', error)
    })
  }


  const handleClick = (tabName: string) => {
    dispatch(setCurrentTab(tabName))
  }

  const checkIconSelected = (path: string) => {

    if (pathname === '/') {
      if (path !== routes.home()) {
        return 'var(--grey-dark)'
      }
      return 'var(--secondary)'
    }
    return path.includes(pathname) ? 'var(--secondary)' : 'var(--grey-dark)'

  }

  const topItems: Array<LeftNavItem> = [
    {
      name: t('navigation.home'),
      icon: <div className="step-left-menu-home">
        <HomeIcon
          $size={1.25}
          hover={'var(--secondary)'}
          cursor={'pointer'}
          color={checkIconSelected(routes.home())}
          onClick={() => { navigate(routes.home()); handleClick('') }}
        />
      </div>,
      visible: true
    },
    {
      name: currentProject? t('navigation.project', { projectName: currentProject?.name ?? 'Projects' }) : 'Select a project',
      icon:
        <div className="step-left-menu-project">
          <FolderIcon
            $size={1.25}
            hover={currentProject? 'var(--secondary)' : ''}
            cursor={currentProject? 'pointer' : 'not-allowed'}
            color={checkIconSelected(routes.project())}
            onClick={() => { currentProject?.id && navigate(urls.project({ projectId: currentProject?.id })) }}
          />
        </div>
      ,
      visible: true
    },
    {
      name: t('navigation.users'),
      icon: <UserGroupIcon
        $size={1.25}
        hover={'var(--secondary)'}
        cursor={'pointer'}
        color={checkIconSelected(routes.users())}
        onClick={() => { navigate(routes.users()); handleClick('users') }}
      />,
      visible: !isAdmin
    },
    {
      name: t('navigation.helpers'),
      icon: <HelperIcon
        $size={1.5}
        hover={'var(--secondary)'}
        cursor={'pointer'}
        color={checkIconSelected(routes.helpers())}
        onClick={() => { navigate(routes.helpers()); handleClick('helpers') }}
      />,
      visible: !!isAdmin || !!isPartner
    },
    {
      name: t('navigation.guide'),
      icon: <GuideIcon
        $size={1.25}
        hover={'var(--secondary)'}
        cursor={'pointer'}
        color={'var(--grey-dark)'}
        onClick={() => { window.open('https://support.wakeupdata.com/knowledge', '_blank'); handleClick('') }}
      />,
      visible: true
    },

  ]

  const bottomItems: Array<LeftNavItem> = [
    {
      name: t('navigation.subscriptions'),
      icon: <DocsIcon
        $size={1.25}
        hover={'var(--secondary)'}
        cursor={'pointer'}
        color={checkIconSelected(routes.subscriptions())}
        onClick={() => { navigate(routes.subscriptions()); handleClick('subscriptions') }}
      />,
      visible: true
    },
    {
      name: t('navigation.settings'),
      icon: <GearIcon
        $size={1.25}
        hover={'var(--secondary)'}
        cursor={'pointer'}
        color={checkIconSelected(routes.settings() + routes.preferences())}
        onClick={() => { navigate(urls.settings({ clientId: session.data.userGuid })); handleClick('settings') }}
      />,
      visible: true
    },
    {
      name: t('navigation.adminTools'),
      icon: <GridIcon
        $hover={'var(--secondary)'}
        onClick={() => setIsFloatingMenuVisible(true)}
      />,
      visible: !!isAdmin
    },
    {
      name: t('navigation.logout'),
      icon: <LogOutIcon
        $size={1.25}
        hover={'var(--secondary)'}
        cursor={'pointer'}
        color={'var(--grey-dark)'}
        onClick={() => logoutUser()}
      />,
      visible: true
    },

  ]

  useEffect(() => {
    getUsers()
  }, [])


  return [topItems, bottomItems, isFloatingMenuVisible, setIsFloatingMenuVisible];
}