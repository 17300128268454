import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { routes, urls } from '@/routes/Routes'
import { IApiChannelsForPartners, IApiClientsForPartners, IApiExports, IApiImportSteps, IApiPartners, IApiProjectsForPartners } from '@/api/endpointsRoutes'
import { IApiChannelsForPartnersResponse, IApiClientsForPartnersResponse, IApiPartnersResponse, IApiProjectDetailsResponse, IApiProjectsForPartnersResponse, apiClient } from '@/api/extendedAPI'
import { RootState } from '@/store/store'
import { setCurrentChannel, setCurrentClient, setCurrentPartner, setCurrentProject, setCurrentStep } from '@/store/coreSlice'
import { setManagerChannelsList, setManagerChannelsLoading, setManagerClientsList, setManagerClientsLoading, setManagerPartnersList, setManagerPartnersLoading, setManagerProjectsList, setManagerProjectsLoading } from '@/store/managerSlice'
import { IChannel, IClient, IPartner, IProject } from '@/interfaces/types'
import { accountConst } from '@/constants/const'
import { useSessionContext } from '@/contexts/SessionContext'
import { DashedLine } from './AccountSelectionStyles'
import SearchableDropdown from '@/components/searchableDropdown/SearchableDropdown'
import { setChannels, setChannelsLoading } from '@/store/channelsSlice'
import { setSteps, setStepsLoading } from '@/store/stepsSlice'
import { useRequestHandler } from '@/hooks/useRequestHandler'
import { useQuery } from '@/hooks/useQuery'
import { useNavigate } from 'react-router-dom'

export default function PartnerSelection() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const { importGuid } = useQuery()
  const { partnersList, clientsList, projectsList, channelsList } = useSelector((state: RootState) => state.manager)
  const { currentPartner, currentClient, currentProject, currentChannel } = useSelector((state: RootState) => state.core)
  const [isPartnersVisible, setIsPartnersVisible] = useState(false)
  const [isClientsVisible, setIsClientsVisible] = useState(false);
  const [isProjectsVisible, setIsProjectsVisible] = useState(false);
  const [isChannelsVisible, setIsChannelsVisible] = useState(false)

  const listPartners = () => {
    dispatch(setManagerPartnersLoading(true))
    apiClient.listPartners(session.data.accessToken).then(
      (partners: IApiPartnersResponse) => {
        const { data, error } = partners
        if (!error) {
          const newPartnersList = data?.filter(partner => partner.Key !== 'create').map((partner: IApiPartners) => { return { id: partner.Key, name: partner.Value } })
          dispatch(setManagerPartnersList(newPartnersList));
          if (!currentPartner) dispatch(setCurrentPartner(newPartnersList[1]))
        } else {
          dispatch(setManagerPartnersLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    )
  }

  const listClientsForPartners = (partnerId: string) => {
    dispatch(setManagerClientsLoading(true))
    apiClient.listClientsForPartners(partnerId, session.data.accessToken).then(
      (clientsForPartners: IApiClientsForPartnersResponse) => {
        const { data, error } = clientsForPartners
        if (!error) {
          const newClientsList = data?.filter(client => client.Key !== 'create').map((client: IApiClientsForPartners) => { return { id: client.Key, name: client.Value } })
          dispatch(setManagerClientsList(newClientsList))
        } else {
          dispatch(setManagerClientsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    )
  }

  const listProjectsForPartners = (userId: string) => {
    dispatch(setManagerProjectsLoading(true))
    apiClient.listProjectsForPartners(userId, session.data.accessToken).then(
      (projectsForPartners: IApiProjectsForPartnersResponse) => {
        const { data, error } = projectsForPartners
        if (!error) {
          const newProjectsList = data?.filter(project => project.Key !== 'create').map((project: IApiProjectsForPartners) => { return { id: project.Key, name: project.Value } })
          dispatch(setManagerProjectsList(newProjectsList))
        } else {
          dispatch(setManagerProjectsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    )
  }

  const listChannelsForPartners = (userId: string, projectId: string) => {
    dispatch(setManagerChannelsLoading(true))
    apiClient.listChannelsForPartners(userId, projectId, session.data.accessToken).then(
      (channelsForPartners: IApiChannelsForPartnersResponse) => {
        const { data, error } = channelsForPartners
        if (!error) {
          const newChannelsList = data?.filter(channel => channel.Key !== 'create').map((channel: IApiChannelsForPartners) => { return { id: channel.Key, name: channel.Value, exportType: true ? 'SetupTemplateGenerator' : 'SetupGenerator' } })
          dispatch(setManagerChannelsList(newChannelsList))
        } else {
          dispatch(setManagerChannelsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    )
  }

  const getProject = (projectId: string) => {
    dispatch(setStepsLoading(true))
    dispatch(setChannelsLoading(true))
    apiClient.getProject2(projectId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (project: IApiProjectDetailsResponse) => {
        const { data, error } = project
        if (!error && data) {
          dispatch(setChannels(data?.Exports.map((channel: IApiExports) => { return { id: channel.ExportGuid, name: channel.Title, type: channel.TemplateName ? `Template: ${channel.TemplateName}` : `Custom: ${channel.Type} - ${channel.SenderType}`, active: channel.Active, totalItems: channel.RowsExported, exportType: channel.TemplateName ? 'SetupTemplateGenerator' : 'SetupGenerator' } })))
          dispatch(setSteps(data?.ImportSteps.map((step: IApiImportSteps) => { return { id: step.ImportStepGuid, name: step.Title, sourceType: step.ParserType, stepNumber: step.StepNumber, merge: step.InsertMethod, importTypeOptionId: step.ImportTypeOptionId } })))
        } else {
          dispatch(setStepsLoading(false))
          dispatch(setChannelsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  const selectPartner = async (partner: IPartner) => {
    dispatch(setCurrentPartner(partner))
    dispatch(setCurrentClient(null))
    dispatch(setCurrentProject(null))
    dispatch(setCurrentChannel(null))
    dispatch(setCurrentStep(null))
  }

  const selectClient = async (client: IClient) => {
    dispatch(setCurrentClient(client))
    dispatch(setCurrentProject(null))
    dispatch(setCurrentStep(null))
    dispatch(setCurrentChannel(null))
    navigate(routes.home())
  }

  const selectProject = async (project: IProject) => {
    if (session.data.accessToken) {
      getProject(project.id) // I need to get the project so I can see the information of the steps and channels in the tabs
      dispatch(setCurrentProject(project))
      dispatch(setCurrentStep(null))
      dispatch(setCurrentChannel(null))
    }
  }

  const selectChannel = async (channel: IChannel) => {
    dispatch(setCurrentChannel(channel))
  }

  // LIST PARTNERS IN THE FIRST RENDER IF THERE IS A TOKEN
  useEffect(() => {
    if (session.data.accessToken && !currentPartner) listPartners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // WHEN I SELECT A PARTNER, I CALL THE CLIENTS, IMPORTS AND EXPORTS
  useEffect(() => {
    if (currentPartner && !currentClient) {
      listClientsForPartners(currentPartner.id)
      listProjectsForPartners(currentPartner.id)
      listChannelsForPartners(currentPartner.id, 'undefined')
    }
  }, [currentPartner])

  // WHEN I CHANGE CLIENT I HAVE TO DO THE SAME WITH IMPORTS AND EXPORTS
  useEffect(() => {
    if (currentClient && !currentProject) {
      listProjectsForPartners(currentClient.id)
      listChannelsForPartners(currentClient.id, '')
    }
  }, [currentClient])

  useEffect(() => {
    if (currentProject && !currentChannel) {
      if (currentClient) {
        listChannelsForPartners(currentClient.id, currentProject.id)
      } else if (currentPartner) {
        listChannelsForPartners(currentPartner.id, currentProject.id)
      }
    }
  }, [currentProject])


  useEffect(() => {
    if (importGuid()) {
      const project = projectsList?.data.find((item: IProject, index: number) => item.id === importGuid())
      if (project) {
        dispatch(setCurrentProject(project))
        selectProject(project)
      } else {
        dispatch(setCurrentProject(null))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsList])

  return (
    <div style={{ display: 'flex' }}>
      <SearchableDropdown
        type={accountConst.PARTNERS}
        isLink={false}
        items={partnersList.data}
        selected={currentPartner}
        setSelected={selectPartner}
        isOpen={isPartnersVisible}
        setIsOpen={setIsPartnersVisible}
        headerActions={[{ type: 'list', link: urls.home(), action: () => null, disabled: true }, { type: 'create', link: urls.home(), action: () => null, disabled: true, }]}
        isLoading={partnersList.loading}
        labelType='medium'
      />
      <DashedLine />
      <SearchableDropdown
        type={accountConst.CLIENTS}
        isLink={false}
        items={clientsList.data}
        selected={currentClient}
        setSelected={selectClient}
        isOpen={isClientsVisible}
        setIsOpen={setIsClientsVisible}
        headerActions={[{ type: 'list', link: urls.home(), action: () => null, disabled: true }, { type: 'create', link: urls.home(), action: () => null, disabled: true, }]}
        isLoading={clientsList.loading}
        labelType='medium'
      />
      <DashedLine />
      <SearchableDropdown
        type={accountConst.PROJECTS}
        isLink={true}
        getUrl={(item: any) => urls.project({ projectId: item.id })}
        items={projectsList.data}
        selected={currentProject}
        setSelected={selectProject}
        isOpen={isProjectsVisible}
        setIsOpen={setIsProjectsVisible}
        headerActions={[{ type: 'list', link: urls.home(), action: () => null, disabled: false }, { type: 'create', link: urls.project_new(), action: () => null, disabled: false, }]}
        isLoading={projectsList.loading}
        labelType='medium'
      />
      <DashedLine />
      <SearchableDropdown
        type={accountConst.CHANNELS}
        isLink={true}
        getUrl={(item: any) => urls.channel({ channelId: item.id })}
        items={channelsList.data}
        selected={currentChannel}
        setSelected={selectChannel}
        isOpen={isChannelsVisible}
        setIsOpen={setIsChannelsVisible}
        headerActions={[{ type: 'list', link: urls.channelList(), action: () => null, disabled: false }, { type: 'create', link: urls.home(), action: () => null, disabled: true, }]}
        isLoading={channelsList.loading}
        labelType='medium'
      />
    </div>
  )
}
