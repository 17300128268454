import { ChangeEvent } from 'react'
import { GearIcon } from '@/components/icons/Icons'
import { Label } from '@/components/text/Text'
import { WorkInProgressContainer, Logo, LogoContainer } from './WorkInProgressStyles'
import { useState } from 'react'
import { Button } from '@/components/button/Button'
import styled from 'styled-components'

export default function WorkInProgress() {

  const [pw, setPw] = useState('')

  const handleConfirm: () => void = () => {
    if (pw === 'WUDAdminEnabled') localStorage.setItem(pw, 'true')
    window.location.reload()
  }

  return (
    <WorkInProgressContainer>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <GearIcon $size={10} />
        <Label fontSize={5}>
          WORK IN PROGRESS
        </Label>
      </div>
      <div style={{ justifyContent: 'center', textAlign: 'center', padding: '0 5rem', marginBottom: '5rem' }}>
        <Label fontSize={2}>
          We're crafting an experience that exceeds expectations! Thank you for your patience as we work to bring you something truly exceptional.
        </Label>
        <div style={{ display: 'flex', margin: '1rem', gap: '1rem' }}  >
          <Input onKeyDown={(e) => {
            if (e.key === "Enter")
              handleConfirm();
          }}
            autoFocus={true}
            type='text'
            name='pwInput'
            placeholder={'Write down the password to access'}
            value={pw}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPw(event.target.value)} />
          <Button $buttonType='primary' text='Send' onClick={handleConfirm} />
        </div>
        <LogoContainer>
          <Logo src={require('../../assets/images/logo.png')} alt="logo" />
        </LogoContainer>
      </div>
    </WorkInProgressContainer>
  )
}


const Input = styled.input`
  flex: 1;
  border: 1px solid var(--grey);
  border-radius: 0.75rem;
  padding: 0.5rem 0.5rem;
  outline: none;
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 500;
  color: var(--main);

&::placeholder {
  color: var(--grey);

}
`