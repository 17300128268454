import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { setCurrentTab } from '@/store/coreSlice';
import { Tab } from './TabStyles';
import AdminTag from '@/components/tags/AdminTag';

interface MenuTabProps {
  link: string;
  text: string;
  type: string;
  adminTag?: boolean;
  icon?: any;
}

const MenuTab: React.FC<MenuTabProps> = (props) => {
  const {
    link,
    text,
    type,
    icon,
    adminTag = false
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const { currentTab } = useSelector((state: RootState) => state.core)
  const dispatch = useDispatch()

  const handleClick = (tabName: string) => {
    dispatch(setCurrentTab(tabName.toLowerCase()))
  };

  return (
    <div className={adminTag ? 'step-top-menu-admin-tag' : ''} style={{ display: 'flex' }} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      <Tab
        to={link}
        color={'var(--white)'}
        $lineHeight={2.5}
        $selected={currentTab === type}
        $hover={isVisible}
        onClick={() => handleClick(type)}>
        {icon} {text ?? type.toUpperCase()} 
        {adminTag && <AdminTag />}
      </Tab>

    </div>
  );
};

export default MenuTab;
