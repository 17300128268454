import React, { ReactElement, useState } from 'react';
import { TabParameters } from '@/hooks/useStepContent';
import {
  ChevronRightIcon,
  CheckIcon,
  CheckSelectedIcon,
  ChevronDownIcon
} from '@/components/icons/Icons';
import Dropdown from '@/components/dropdown/Dropdown';
import {
  SubMenuContainer,
  SubContainerLeft,
  SubContainerRight,
  SubContainerElement,
  Step,
  StepText,
  ArrowContainer,
  Divider,
  SubContainerSteps,
  ChevronContainer,
  DropdownList,
  Item,
  SubContainerStepsDrop
} from './SubMenuStyles';
import { useNavigate } from 'react-router-dom';
import AdminTag from '@/components/tags/AdminTag';
import WUDTooltip from '@/components/tooltip/Tooltip';

interface SubMenuProps {
  leftOption?: ReactElement;
  currentSubTab?: number;
  elements?: Array<TabParameters>;
  megaTabs?: Array<TabParameters>;
  type?: string;
  allowForward?: boolean;
}

const SubMenu: React.FC<SubMenuProps> = ({
  leftOption,
  currentSubTab = 0,
  elements = [],
  megaTabs = [],
  type = 'options',
  allowForward = true
}) => {

  const navigate = useNavigate()
  const [selectedDropdown, setSelectedDropdown] = useState<number | null>(0);

  const handleSelect = (event: React.PointerEvent<HTMLAnchorElement>, isDisabled: boolean) => {
    if (isDisabled) {
      event.preventDefault()
    }
  }

  const handleSelectOption = (event: React.PointerEvent<HTMLAnchorElement>, item: any, disabled = false) => {
    if (item.type === 'modal') {
      event.preventDefault()
      !disabled && item.onClick()
    }
  }

  return (
    <SubMenuContainer>
      <SubContainerLeft>
        {leftOption}
        <SubContainerSteps>
          {elements?.map((tab, index) =>
            ((index > 0 || type === 'options') && (!tab?.hidden)) &&
            (
              <SubContainerElement $isToggle={!!tab.toggleList} key={index} >
                <Step
                  to={tab.url(tab.parameters)}
                  $completed={allowForward ? true : currentSubTab > index}
                  $selected={currentSubTab === index}
                  $disabled={!allowForward && currentSubTab !== index}
                  $isToggle={!!tab.toggleList}
                  onClick={(event: React.PointerEvent<HTMLAnchorElement>) => handleSelect(event, !allowForward || !!tab.toggleList || (!allowForward && currentSubTab !== index))}
                >
                  {type !== 'options' &&
                    (!allowForward && currentSubTab < index + 1 ? (
                      <CheckIcon />
                    ) : (
                      <CheckSelectedIcon />
                    ))
                  }
                  <StepText
                    $lineHeight={1.5}
                    $stepper={type !== 'options'}
                  >
                    {tab.toggleList && currentSubTab === index ? tab.toggleList[tab.toggleSelected].name : tab.name}
                  </StepText>
                  {tab?.isAdmin &&
                    <AdminTag />
                  }
                  {tab.toggleList && <ChevronContainer><ChevronDownIcon /></ChevronContainer>}
                </Step>
                {tab.toggleList && <DropdownList role="listbox">
                  {tab.toggleList.map((item: any, index: number) => (
                    <Item to={item.parameters ? item.url(item.parameters) : item.url()} key={index} onClick={(event: React.PointerEvent<HTMLAnchorElement>) => item.parameters ? navigate(item.url(item.parameters)) : navigate(item.url())}>
                      {item.name}
                    </Item>
                  ))}
                </DropdownList>}
                {type !== 'options' && index !== elements.length - 1 ? (
                  <ArrowContainer>
                    <ChevronRightIcon color="var(--secondary-light2)" hover="var(--secondary-light2)" />
                  </ArrowContainer>
                ) : (
                  <Divider></Divider>
                )}

              </SubContainerElement>
            )
          )}
        </SubContainerSteps>
      </SubContainerLeft>

      <SubContainerRight>
        <SubContainerStepsDrop>
          {megaTabs?.map((tab, index) => (
            !tab?.hidden && tab.toggleList &&
            (
              <SubContainerElement key={index} >
                <Step
                  to={''}
                  $completed={allowForward ? true : currentSubTab > index}
                  $selected={currentSubTab === elements.length - 1}
                  $disabled={false}
                  $isToggle={true}
                  onClick={(event: React.PointerEvent<HTMLAnchorElement>) => handleSelect(event, !!tab.toggleList)}
                >
                  <StepText
                    $lineHeight={1.5}
                    $stepper={false}
                  >
                    {tab.toggleList && currentSubTab === elements.length - 1 ? tab.toggleList[tab.toggleSelected].name : tab.name}
                  </StepText>
                  {tab?.isAdmin &&
                    <AdminTag />
                  }
                  {tab.toggleList && <ChevronContainer><ChevronDownIcon /></ChevronContainer>}

                </Step>
                {tab.toggleList && <DropdownList role="listbox">
                  {tab.toggleList.map((item: any, index: number) => (
                    !item.hidden && <WUDTooltip key={index} id={`moreOptions-item-submenu-disabled-${item?.id}-${index}`} content={item?.soonAvailable ? 'Soon available' : ''} place='bottom' >
                      <Item disabled={item?.soonAvailable} to={item.parameters ? item.url(item.parameters) : item.url()}  onClick={(event: React.PointerEvent<HTMLAnchorElement>) => handleSelectOption(event, item, item?.soonAvailable)}>
                        {item.name}
                      </Item>
                    </WUDTooltip>
                  ))}
                </DropdownList>}
              </SubContainerElement>
            )
          ))}
        </SubContainerStepsDrop>

      </SubContainerRight>
    </SubMenuContainer >
  );
};

export default SubMenu;