import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { urls } from "@/routes/Routes";
import { clearSession } from "@/store/sessionSlice";
import { setCurrentChannel, setCurrentClient, setCurrentPartner, setCurrentProject, setCurrentStep } from "@/store/coreSlice";

interface RequestHandlerProps {
  needsRedirect?: boolean;
}

export function useRequestHandler(props: RequestHandlerProps = {}) {
  const { needsRedirect = true } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logoutUser = () => {
    navigate(urls.logout_connect(), needsRedirect ? { state: { from: window.location.pathname } } : {});
    dispatch(setCurrentClient(null))
    dispatch(setCurrentProject(null))
    dispatch(setCurrentPartner(null))
    dispatch(setCurrentChannel(null))
    dispatch(setCurrentStep(null))
    setTimeout(() => dispatch(clearSession()), 100);
  }

  return { logoutUser };
}