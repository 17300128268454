import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Label } from '@/components/text/Text';

export const ChannelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ChannelItem = styled(Link)`
  padding: 0.5rem 0;
  text-decoration: none;
  cursor: pointer;
  color: var(--main);
  font-weight: 700;
  font-size: 2rem;

  &:hover {
    color: var(--secondary);
  }
`

export const NewTagContainer = styled(Label)`
 background-color: var(--warning);
 padding: 0.25rem 0.5rem;
 border-radius: 2rem;
`