import styled from 'styled-components';
import { Label, Link } from '@/components/text/Text';

export const ItemContainer = styled(Link)`
  padding:  0.25rem 0.833vw;
  cursor: pointer;
  display: flex;
  background-color: var(--white);
  border-radius: 0.625rem;

  &:hover {
    background-color: var(--secondary-lighter);
  }
`;


export const LabelContent = styled.div`
  transition: color 0.3s;
  overflow: hidden;
  width: 100%;
  `;

export const MainLabel = styled(Label)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  `

export const TooltipContainer = styled.div`
  position: static;
  display: inline-block;
  padding: 0 0.2rem;
  cursor: pointer;
  z-index: 8;
  `;