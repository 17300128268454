import React, { ReactNode } from "react";
import { ButtonElement } from "./ButtonStyle";
import { BaseIcon } from '@/components/icons/Icons';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  $buttonType: 'primary' | 'secondary' | 'creation' | 'complementary';
  $buttonSize?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  disabled?: boolean;
  icon?: ReactNode;
}


export const Button = ({ text, $buttonType, onClick, icon, disabled = false, $buttonSize = 'large', ...props }: ButtonProps) => {
  return (
    <ButtonElement  {...props} onClick={onClick} disabled={disabled} $buttonType={$buttonType} $buttonSize={$buttonSize} >
      {icon}  {text}
    </ButtonElement>
  )
}
