import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { StepMappingsContainer } from './StepMappingsStyles'

export default function StepMappings() {

  return (
    <StepMappingsContainer>
      <IframeConnect />
    </StepMappingsContainer>
  )
}
