import { useEffect, PointerEvent, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { routes, urls } from '@/routes/Routes'
import { setCurrentChannel } from '@/store/coreSlice'
import { RootState } from '@/store/store'
import { IChannel } from '@/interfaces//types'
import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ActionButton, ActionsContainer, ChannelItem, ChannelItemContainer, ChannelOverviewContainer, Divider, HeaderContainer, InfoContainer, InfoItem, ListContainer, Tag, Tags, Title } from './ChannelOverviewStyles'
import { Button } from '@/components/button/Button'
import { AddIcon, ChannelsIcon, EditIcon } from '@/components/icons/Icons'
import { useLocation, useNavigate } from 'react-router'
import { useSessionContext } from '@/contexts/SessionContext'
import { Label, Span } from '@/components/text/Text'
import { useQuery } from '@/hooks/useQuery'
import WUDTooltip from '@/components/tooltip/Tooltip'
import { apiClient } from '@/api/extendedAPI'
import { useRequestHandler } from '@/hooks/useRequestHandler'
import { ModalProps } from '@/components/modal/Modal'

interface IChannelOverview {
  handleModal: (content: ModalProps) => void;
  handleHideModal: () => void;
  moreOptions: Array<{
    name: string;
    toggleList: Array<{
      name: string;
      type: string;
      component?: ReactNode;
      parameters?: Object;
      url?: Function;
      hidden?: boolean;
      isHidden: (channel: IChannel) => boolean;
    }>;
    hidden?: boolean;
  }>;
}

export default function ChannelOverview({ handleModal, handleHideModal, moreOptions }: IChannelOverview) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const { exportGuid } = useQuery()
  const { currentProject, currentClient, currentPartner, currentChannel } = useSelector((state: RootState) => state.core)
  const { channelsList } = useSelector((state: RootState) => state.channels)
  const adminLists = useSelector((state: RootState) => state.manager)
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const isAdmin = session.data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));

  const selectChannel = (channel: IChannel) => {
    dispatch(setCurrentChannel(channel))
  }

  const renameChannel = (title: string, projectId: string, channelId: string) => {
    apiClient.renameChannel(title, channelId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (renameChannel: { data: string, error: any }) => {
        const { data, error } = renameChannel
        if (!error && data) {
          session.getProject(projectId)
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  useEffect(() => {
    if (exportGuid() && exportGuid() !== currentChannel?.id) {
      let channel;
      if (isAdmin) {
        channel = adminLists?.channelsList?.data.find((channel: IChannel) => channel.id === exportGuid())
      } else {
        channel = channelsList?.data.find((channel: IChannel) => channel.id === exportGuid())
      }
      if (channel || location.search.includes('force')) {
        if (location.search.includes('force')) {
          dispatch(setCurrentChannel({ name: 'Unknown name', id: exportGuid() }))
        } else {
          dispatch(setCurrentChannel(channel))
        }
      }
    }
  }, [])

  return (
    (currentChannel || location.search.includes('force')) && (routes.channelList() !== pathname) ? <ChannelOverviewContainer>< IframeConnect />  </ChannelOverviewContainer > :
      <ChannelOverviewContainer>
        <HeaderContainer>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
            <Label fontSize={0.75} $lineHeight={0.75} style={{ backgroundColor: 'var(--export)', color: 'white', padding: '0.25rem', borderRadius: '0.25rem', width: '7rem', textAlign: 'center' }} >CHANNELS</Label>
            <Title fontSize={1.25} color='var(--main)' >
              {currentProject?.name}
            </Title>
          </div>
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }} >
            <Button $buttonType='creation' icon={<AddIcon />} text='Create a channel' onClick={() => navigate(urls.channel_new({ projectId: currentProject?.id ?? '', firstChannel: currentProject?.channels?.length ? true : null }))} />
          </div>
        </HeaderContainer>
        <ListContainer>
          {isAdmin ? adminLists.channelsList.data.map((channel: IChannel, index: number) =>
            <ChannelItemContainer>
              <ChannelItem
                title={channel.name}
                key={index}
                to={urls.channel({ channelId: channel.id })}
                onClick={() => selectChannel(channel)}>
                <Span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} ><div style={{ backgroundColor: 'var(--export-light)', borderRadius: '2rem', width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ><ChannelsIcon color={'var(--export)'} /></div> {channel.name}  {channel.name.includes('disabled') && <Tag fontSize={0.5} $lineHeight={0.5} $backgroundColor={'var(--error)'}>DISABLED</Tag>}</Span>
                {channel.totalItems && <InfoContainer>
                  <InfoItem fontWeight='500' fontSize={0.75} $lineHeight={1.5}>ITEMS INCLUDED <Divider /> {channel.totalItems ?? 0}</InfoItem>
                </InfoContainer>}
              </ChannelItem>
            </ChannelItemContainer>
          ) : channelsList?.data.map((channel: IChannel, index: number) =>
            <ChannelItemContainer>
              <ChannelItem
                title={channel.name}
                key={index}
                to={urls.channel({ channelId: channel.id })}
                onClick={() => selectChannel(channel)}>
                <Span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} ><div style={{ backgroundColor: 'var(--export-light)', borderRadius: '2rem', width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ><ChannelsIcon color={'var(--export)'} /></div>{channel.name} <Span fontSize={0.75} fontWeight='500' $lineHeight={1.5} fontStyle='italic'>({channel.type})</Span> {!channel.active && <Tag fontSize={0.5} $lineHeight={0.5} $backgroundColor={'var(--error)'}>DISABLED</Tag>}</Span>
                <InfoContainer>
                  <InfoItem fontWeight='500' fontSize={0.75} $lineHeight={1.5}>ITEMS INCLUDED <Divider /> {channel.totalItems ?? 0}</InfoItem>
                </InfoContainer>
              </ChannelItem>
              <ActionsContainer>
                <WUDTooltip id='disabled-not-implemented' content='' backgroundColor={'var(--main)'} >
                  <ActionButton onClick={(event: PointerEvent<HTMLDivElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleModal({
                      title: `Rename channel "${channel.name}"`,
                      description: '',
                      confirmAction: (value?: string) => renameChannel(value ?? '', currentProject?.id ?? '', channel.id),
                      cancelAction: () => handleHideModal(),
                      placeholder: 'Insert a name for your channel'
                    })
                  }} >
                    Rename
                    <EditIcon $size={1} cursor={'pointer'} />
                  </ActionButton>
                </WUDTooltip>
              </ActionsContainer>
            </ChannelItemContainer>
          )}
        </ListContainer>
      </ChannelOverviewContainer >
  )
}
