import React, { ReactNode, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { StepType, useTour } from '@reactour/tour';
import { routes, urls } from '@/routes/Routes';
import { useLeftNavigation } from '@/hooks/useLeftNavigation';
import { useQuery } from '@/hooks/useQuery';
import WUDTooltip from '@/components/tooltip/Tooltip';
import Menu from '@/components/menu/Menu';
import { LayoutContainer, LeftNav, Logo, ConnectAnimation, LogoContainer, TopNav, RightTopNav, ChildContainer, IconContainer, LeftTopNav, LeftBottomNav, AdminLayout } from './MainLayoutStyles';
import { useSessionContext } from '@/contexts/SessionContext';
import AdminBar from '@/components/menu/account/AdminBar';
import Modal, { ModalProps } from '@/components/modal/Modal';
import FloatingMenu from '@/components/modal/FloatingMenu';
import { BulbIcon } from '@/components/icons/Icons';
import { Button } from '@/components/button/Button';

const initialModalState: { modalContent: ModalProps, isVisible: boolean } = {
    modalContent: {
        type: 'confirmation',
        title: '',
        description: '',
        confirmAction: () => null,
        cancelAction: () => null
    }, isVisible: false
}

interface MainLayoutProps {

}

const MainLayout: React.FC<MainLayoutProps> = ({ }) => {

    const location = useLocation()
    const navigate = useNavigate()
    const { setIsOpen, setSteps, setCurrentStep } = useTour()
    const [topItems, bottomItems, isFloatingMenuVisible, setIsFloatingMenuVisible] = useLeftNavigation()
    const { session } = useSessionContext();
    const [modalProperties, setModalProperties] = useState(initialModalState)
    const isAdmin = session.data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking')) ? true : false
    const isPartner = session.data.roles.find((role) => role?.toLowerCase().includes('partner'))


    const getModalContent = (type: string) => {
        let modalContent: ModalProps = {
            type: 'confirmation',
            title: '',
            description: '',
            confirmAction: (param?: string) => { },
            cancelAction: () => { }
        }
        switch (type) {
            case 'loadGuid':
                modalContent = {
                    type: 'loadGuid',
                    title: 'Please enter the guid:',
                    description: '',
                    confirmAction: (param?: string) => navigateTo(urls.load_guid({ guid: param ?? '' })),
                    cancelAction: () => setModalProperties(initialModalState)
                }
                break
            default:
                break
        }
        setModalProperties({ modalContent, isVisible: true })
    }

    const navigateTo = (path: string = '') => {
        navigate(path)
    }

    const setMenuTour = () => {
        const steps: Array<StepType> = [
            {
                selector: '.step-top-menu',
                content: `Welcome to the new way of navigation! 
               In this area you will find tabs that will allow you to see different pages.
               `,
            },
            {
                selector: '.step-top-menu-project-selection',
                content: `In this area you can select the project you want to work with.
               `,
                padding: { mask: 12 }
            },
            {
                selector: '.step-left-menu',
                content: `Same left navigation bar, with a modification in the first two options.
               `,
                position: 'right',
            },
            {
                selector: '.step-left-menu-home',
                content: `Home icon: this button will send you to the Projects Dashboard.`,
                position: 'right',
                padding: { mask: 6 }
            },
            {
                selector: '.step-left-menu-project',
                content: `Folder icon: this button will send you to the project overview with tabs dedicated to the project you have selected.`,
                position: 'right',
                padding: { mask: 6 }
            }
        ]
        setSteps && setSteps(steps) // validation bc TS
        setCurrentStep(0)
        setIsOpen(true)
    }

    const setPartnerMenuTour = () => {
        const steps: Array<StepType> = [
            {
                selector: '.step-top-menu',
                content: `Welcome to the new way of navigation! 
               In this area you will find tabs that will allow you to see different pages.
               `,
            },
            {
                selector: '.step-top-menu-client-project-selection',
                content: `In this area you can select the client and project you want to work with.
               `,
                padding: { mask: 10 }
            },
            {
                selector: '.step-left-menu',
                content: `Same left navigation bar, with a modification in the first two options.
               `,
                position: 'right',
            },
            {
                selector: '.step-left-menu-home',
                content: `Home icon: this button will send you to the Projects Dashboard.`,
                position: 'right',
                padding: { mask: 6 }
            },
            {
                selector: '.step-left-menu-project',
                content: `Folder icon: this button will send you to the project overview with tabs dedicated to the project you have selected.`,
                position: 'right',
                padding: { mask: 6 }
            }
        ]
        setSteps && setSteps(steps) // validation bc TS
        setCurrentStep(0)
        setIsOpen(true)
    }

    const setAdminMenuTour = () => {
        if (!routes.home().includes(location.pathname)) {
            navigate(routes.home())
        }
        const steps: Array<StepType> = [
            /* {
                selector: '.step-admin',
                content: 'Same admin dsbar but with a new UI :) ',
                accentColor: 'var(--green)'
            }, */
            {
                selector: '.step-top-menu',
                content: `Welcome to the new way of navigation! 
               In this area you will find tabs that will allow you to see different pages.
               `,
            },
            {
                selector: '.step-top-menu-admin-tag',
                content: `Whenever you see this tag, it means it's only an ADMIN TOOL. FYI: You can find this tag inside other tabs as well.
               `,
            },
            {
                selector: '.step-left-menu',
                content: `Same left navigation bar, with a modification in the first two options.
               `,
                position: 'right',
            },
            {
                selector: '.step-left-menu-home',
                content: `Home icon: this button will send you to the Projects Dashboard. If you're an admin, you will
                additionally have access to: CSM and Sales Dashboard.`,
                position: 'right',
                padding: { mask: 6 }
            },
            {
                selector: '.step-left-menu-project',
                content: `Folder icon: this button will send you to the project overview with tabs dedicated to the project you have selected.`,
                position: 'right',
                padding: { mask: 6 }
            }
        ]
        setSteps && setSteps(steps) // validation bc TS
        setCurrentStep(0)
        setIsOpen(true)
    }

    return (
        <LayoutContainer>
            {isAdmin && <AdminLayout>
                <AdminBar openModal={getModalContent} />
            </AdminLayout>}
            <LeftNav $isAdmin={isAdmin} className='step-left-menu' >
                <LeftTopNav >
                    {topItems.map((item, index) =>
                        item.visible && <IconContainer key={index}>
                            <WUDTooltip id={`nav-left${item.name}`} content={item.name} place='right'>
                                {item.icon}
                            </WUDTooltip>
                        </IconContainer>
                    )}
                </LeftTopNav>
                <LeftBottomNav>
                    {bottomItems.map((item, index) =>
                        item.visible && <IconContainer key={index}>
                            <WUDTooltip id={`nav-left${item.name}`} content={item.name} place='right'>
                                {item.icon}
                            </WUDTooltip>
                        </IconContainer>
                    )}
                </LeftBottomNav>
            </LeftNav>
            <TopNav $isAdmin={isAdmin}>
                <LogoContainer>
                    <Logo src={require('../../assets/images/logo.png')} alt="logo" onClick={() => navigate(routes.home())} />
                    <ConnectAnimation src="https://www.wakeupdata.com/hubfs/Icons%20and%20Illustrations/Connect%20Circle%20(animation).svg" alt='connect' />
                </LogoContainer>
                {/*  <RightTopNav>
                    On-page help
                </RightTopNav> */}
                <RightTopNav>
                    <Button $buttonType='creation' $buttonSize='small' text='NAVIGATION TOUR' onClick={isAdmin ? setAdminMenuTour : isPartner ? setPartnerMenuTour : setMenuTour} icon={<BulbIcon $size={1} />} />
                </RightTopNav>
            </TopNav>
            <ChildContainer $isAdmin={isAdmin}>
                <Menu />
                <Outlet context={session} />
            </ChildContainer>
            {isFloatingMenuVisible && <FloatingMenu items={[]} onDismiss={() => setIsFloatingMenuVisible(false)} />}
            {modalProperties.isVisible && <Modal type={modalProperties.modalContent.type} title={modalProperties.modalContent.title} description={modalProperties.modalContent.description} confirmAction={modalProperties.modalContent.confirmAction} cancelAction={modalProperties.modalContent.cancelAction} />}
        </LayoutContainer>
    )
}

export default MainLayout;