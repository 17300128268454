import styled, { css } from 'styled-components'

interface ButtonElementProps {
  $buttonType: 'primary' | 'secondary' | 'creation' | 'complementary';
  $buttonSize: 'small' | 'medium' | 'large';
  disabled: boolean;
}

export const ButtonElement = styled.button<ButtonElementProps>`
  border: none;
  cursor: pointer;
  padding: 0.5rem 2.5rem;
  border-radius: 0.25rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  display: flex;
  gap: 0.5rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

${props =>
    !props.disabled && props.$buttonType === 'primary' &&
    css`
      background-color: var(--main);
      color: var(--white);

      &:hover {
        background-color: var(--secondary);
      }

      &:active {
        background-color: var(--main-dark);
      }
`}

${props =>
    !props.disabled && props.$buttonType === 'secondary' &&
    css`
      background-color: var(--white);
      color: var(--main);
      border: 1px solid var(--grey);

      &:hover {
        background-color: var(--grey-light);
      }

      &:active {
        background-color: var(--grey-light);
      }
`}

${props =>
    !props.disabled && props.$buttonType === 'complementary' &&
    css`
      background-color: var(--white);
      color: var(--warning-dark);
      border: 1px solid var(--warning);

      &:hover {
        background-color: var(--warning);
        color: var(--white);
      }

      &:active {
        background-color: var(--warning);
      }
`}

${props =>
    !props.disabled && props.$buttonType === 'creation' &&
    css`
      background-color: var(--warning);
      color: var(--main);

      &:hover {
        background-color: var(--warning-dark);
      }

      &:active {
        background-color: var(--warning-dark);
      }
`}


${props =>
    props.$buttonSize === 'medium' &&
    css`
      padding: 0.5rem 1.5rem;
`}

${props =>
    props.$buttonSize === 'small' &&
    css`
      padding: 0.5rem 0.8rem;
`}



`