import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes, urls } from '@/routes/Routes';
import { addIframeCtx } from '@/utils/index';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSession } from '@/store/sessionSlice';
import { IframeContainer, IframeContent } from './IframeConnectStyles';
import { useSessionContext } from '@/contexts/SessionContext';
import { useQuery } from '@/hooks/useQuery';
import { RootState } from '@/store/store';
import { setCurrentChannel, setCurrentProject, setCurrentStep } from '@/store/coreSlice';
import { setProjects, setProjectsLoading } from '@/store/projectsSlice';
import { IApiProjectDetailsResponse, IApiProjectsForPartnersResponse, IApiProjectsResponse, apiClient } from '@/api/extendedAPI';
import { IApiExports, IApiImportSteps, IApiProject, IApiProjectsForPartners } from '@/api/endpointsRoutes';
import { useRequestHandler } from '@/hooks/useRequestHandler';
import { setSteps, setStepsLoading } from '@/store/stepsSlice';
import { setChannels, setChannelsLoading } from '@/store/channelsSlice';
import { setManagerProjectsList, setManagerProjectsLoading } from '@/store/managerSlice';
import { IChannel } from '@/interfaces/types';

const { REACT_APP_BASE_LOCAL_URL, REACT_APP_BASE_CONNECT_URL } = process.env

interface IframeConnectProps {
  src?: string;
  login?: boolean;
  height?: number;
}

const IframeConnect = ({ src, login = false, height = 80 }: IframeConnectProps) => {

  const iframeRef = useRef<null | HTMLIFrameElement>(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const { session } = useSessionContext()
  const { importGuid, importStepGuid, exportGuid, projectStatus, firstChannel, guid } = useQuery()
  const { logoutUser } = useRequestHandler()
  const isAdmin = session.data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));
  const adminLists = useSelector((state: RootState) => state.manager)
  const { projectsList } = useSelector((state: RootState) => state.projects)
  const { stepsList } = useSelector((state: RootState) => state.steps)
  const { channelsList } = useSelector((state: RootState) => state.channels)
  const { currentProject, currentChannel, currentStep, currentClient, currentPartner } = useSelector((state: RootState) => state.core)


  const listProjectsForPartners = (userId: string) => {
    dispatch(setManagerProjectsLoading(true))
    apiClient.listProjectsForPartners(userId, session.data.accessToken).then(
      (projectsForPartners: IApiProjectsForPartnersResponse) => {
        const { data, error } = projectsForPartners
        if (!error) {
          const newProjectsList = data?.filter(project => project.Key !== 'create').map((project: IApiProjectsForPartners) => { return { id: project.Key, name: project.Value } })
          dispatch(setManagerProjectsList(newProjectsList))
        } else {
          dispatch(setManagerProjectsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    )
  }

  const listProjectsClient = (userId: string) => {
    dispatch(setProjectsLoading(true))
    apiClient.listProjects2(userId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (projects: IApiProjectsResponse) => {
        const { data, error } = projects
        if (error === null) { dispatch(setProjects(data?.map((project: IApiProject) => { return { id: project.ImportGuid, name: project.Title, active: project.Active, totalItems: project.Count ? project.Count.toFixed() : 0, totalChannels: project.Exports.length } }))) }
        else {
          dispatch(setProjectsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching projects:', error)
    })
  }

  const getProject = (projectId: string) => {
    dispatch(setStepsLoading(true))
    dispatch(setChannelsLoading(true))
    apiClient.getProject2(projectId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (project: IApiProjectDetailsResponse) => {
        const { data, error } = project
        if (!error) {
          dispatch(setChannels(data?.Exports.map((channel: IApiExports) => { return { id: channel.ExportGuid, name: channel.Title, type: channel.TemplateName ? `Template: ${channel.TemplateName}` : `Custom: ${channel.Type} - ${channel.SenderType}`, active: channel.Active, totalItems: channel.RowsExported, exportType: channel.TemplateName ? 'SetupTemplateGenerator' : 'SetupGenerator' } })))
          dispatch(setSteps(data?.ImportSteps.map((step: IApiImportSteps) => { return { id: step.ImportStepGuid, name: step.Title, sourceType: step.ParserType, stepNumber: step.StepNumber, merge: step.InsertMethod, importTypeOptionId: step.ImportTypeOptionId } })))
        } else {
          dispatch(setStepsLoading(false))
          dispatch(setChannelsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  useEffect(() => { // REACT => IFRAME
    console.log('****** React Location has changed here:', location);
    const iframeEl: HTMLIFrameElement | null = document.querySelector<HTMLIFrameElement>(`#connect-iframe`); // we get the iframe element
    if (iframeEl && !src) {
      if (iframeEl?.src !== REACT_APP_BASE_CONNECT_URL + location.pathname + location.search) // If the iFrame src is not the same than the new react location we update it (typically when I trigger the change from React)
      {
        iframeEl.src = addIframeCtx(REACT_APP_BASE_CONNECT_URL + location.pathname + location.search, location.search !== '')
      }
    }


    // This logic doesnt work for when you are creating a project
    // Now if theres no project creation or there is project edition/step edition we update the currents
    if ((!projectStatus() && !location.search.includes('status=merge')) || (projectStatus() && (projectStatus() === 'edit'))) {
      if (importGuid() && currentProject?.id !== importGuid()) {
        let project
        if (isAdmin) {
          project = adminLists?.projectsList?.data.find((item, index) => item.id === importGuid())
        } else {
          project = projectsList?.data.find((item, index) => item.id === importGuid())
        }
        if (project) {
          console.log('I SET A PROJECT!')
          dispatch(setCurrentProject(project))
        }
        else {
          navigate(routes.home())
        }
      }
      if (importStepGuid() && currentStep?.id !== importStepGuid()) {
        const step = stepsList?.data.find((step) => step.id === importStepGuid())
        if (step) { dispatch(setCurrentStep(step)) }
        else {
          navigate(routes.home())
        }
      }
      if (exportGuid() && currentChannel?.id !== exportGuid()) {
        let channel
        if (firstChannel()) {
          getProject(currentProject?.id ?? importGuid() ?? '')
        }
        if (isAdmin) {
          channel = adminLists?.channelsList?.data.find((channel: IChannel) => channel.id === exportGuid())
        } else {
          channel = channelsList?.data.find((channel: IChannel) => channel.id === exportGuid())
        }
        if (channel) { dispatch(setCurrentChannel(channel)) }
        else {
          //navigate(routes.home())
        }
      }

      if (guid()) {
        console.log('guid', guid())
      }
    } else {
      console.log('Validate new project', location.pathname + location.search, routes.project_new_validate())
      const pathname = location.pathname + location.search
      if (pathname.includes(routes.project_new_validate())) {
        if (isAdmin) {
          const guidToUse = currentClient?.id ?? currentPartner?.id ?? session.data.activeUserGuid
          listProjectsForPartners(guidToUse)
        } else {
          listProjectsClient(session.data.activeUserGuid)
        }
      } else if (pathname.includes(routes.step_new_validate())) {
        getProject(currentProject?.id ?? importGuid() ?? '')
      }
    }

  }, [location]);

  const handleLoadIframe = async (event: any) => {
    if (!event.data.source && event.data.event === 'load') {
      if (event.origin && event.origin === REACT_APP_BASE_LOCAL_URL) // if the origin is react we dont want to do anything
        return;

      if (event.data.path.toLowerCase().includes(routes.connect_login_success())) {
        await dispatch(setCurrentSession({ ...event.data.userSession }))
        const redirectToPreviousLocation = location.state?.from
        if (redirectToPreviousLocation) {
          navigate(redirectToPreviousLocation)
        } else {
          navigate(urls.home())
        }
        return
      } else {
        console.log('---------------------------- POST MESSAGE BEGIN ----------------------------')
        console.log({ PostMessage: event.data })

        /* LOGIC FOR SESSION MANAGEMENT IN EVERY LOAD POST MESSAGE*/
        if (!event.data.path.toLowerCase().includes(urls.logout_connect()) && event.data.userSession.accessToken !== session.data.accessToken || event.data.userSession.activeUserGuid !== session.data.activeUserGuid) {
          await dispatch(setCurrentSession({ ...event.data.userSession }))
        }

        const desiredPath = event.data.url.replace(REACT_APP_BASE_CONNECT_URL ?? '', '').replace('%20', '+'); // we remove the origin url and transform the react's spaces format

        if (window.location.pathname + window.location.search !== desiredPath) {
          console.log('Different URL, so we navigate to:', desiredPath);
          navigate(desiredPath)
        }
        console.log('---------------------------- POST MESSAGE END   ----------------------------')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleLoadIframe)
    return () => {
      window.removeEventListener('message', handleLoadIframe)
    };
  }, []);

  return (
    <IframeContainer height={height}>
      <IframeContent
        ref={iframeRef}
        title="Iframe for connect"
        id={`connect-iframe`}
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals" />
    </IframeContainer>
  )
}

export default IframeConnect;