import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { StepSourceContainer } from './StepSourceStyles'

export default function StepSource() {
  return (
    <StepSourceContainer>
      <IframeConnect />
    </StepSourceContainer>
  )
}
