import styled from 'styled-components';
import {  Label, Link, Span } from '@/components/text/Text';


export const OverviewContainer = styled.div`
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: min-content;
  padding-bottom: 2rem;
  gap: 1.5rem;
`

export const UserItemContainer = styled.div`
 display: flex;
 justify-content: space-between;
 background-color: var(--white);
 border-radius: 0.25rem;
 padding-right: 1rem;
 
 &:hover {
    background-color: var(--secondary-lighter);
  }
`

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 1rem;
`

export const UserItem = styled(Link)`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 0.5rem;
  padding-left: 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;

  &:hover {
    background-color: var(--secondary-lighter);
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ActionButton = styled(Link)`
  color: var(--white);
  font-weight:  600;
  background-color: var(--secondary);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: var(--main);
  }
  `

export const UserEmail = styled(Span)`

`