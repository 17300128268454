import styled from 'styled-components'


export const IframeContainer = styled.div<{ height?: number }>`
  display: flex;
  width: 100%;
  height: ${props => props.height ? props.height : 80}vh;

  @media (min-width: 1750px) {
    height: ${props => props.height ? props.height !== 80 && props.height !== 82 ? props.height : props.height * 0.95 : 80}vh;
  }

  @media (max-width: 1750px) {
    height: ${props => props.height ? props.height !== 80 && props.height !== 82 ? props.height : props.height * 0.9 : 80}vh;
  }

  @media (max-width: 1300px) {
    height: ${props => props.height ? props.height !== 80 && props.height !== 82 ? props.height*1.2 : props.height * 0.8 : 80}vh;
  }

  @media (max-width: 1100px) {
    height: ${props => props.height ? props.height !== 80 && props.height !== 82 ? props.height : props.height * 0.75 : 80}vh;
  }
`

export const IframeContent = styled.iframe`
  width: 100%;
  height: 100%;
  display: flex;
  border: none;
`