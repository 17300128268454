import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { routes, urls } from "@/routes/Routes";
import ProjectOverview from "@/containers/manageData/projectOverview/ProjectOverview";
import ProjectTableView from "@/containers/manageData/projectTableView/ProjectTableView";
import ProjectFieldConversions from "@/containers/manageData/projectFieldConversions/ProjectFieldConversions";
import ProjectActions from "@/containers/manageData/projectActions/ProjectActions";
import ProjectImageTransformation from "@/containers/manageData/projectImageTransformation/ProjectActions";
import { ModalProps } from "@/components/modal/Modal";
import WUDActionsLogo from '@/assets/images/actions.svg'
import ProjectMoreOptions from "@/containers/manageData/projectMoreOptions/ProjectMoreOptions";
import { IProject, IStep } from "@/interfaces/types";
import { apiClient } from "@/api/extendedAPI";
import { useQuery } from "./useQuery";
import { useSessionContext } from "@/contexts/SessionContext";
import { useRequestHandler } from "./useRequestHandler";


const initialModal = {
  title: '',
  description: '',
  confirmAction: () => null,
  cancelAction: () => null
}

export interface TabParameters {
  [key: string]: any;
}

export enum ToggleProjectReviewEnum {
  TableView,
  StoredData,
  DataAnalyzer
}

export enum ToggleProjectCustomFieldsEnum {
  FieldConversions,
  AiEnrichments
}

export enum ToggleProjectMoreOptionsEnum {
  FieldsVisualizer,
  IncrementalLoads,
  ApiAccess,
  Logs,
  Clone,
  Settings
}

export function useProjectContent(toggleSelected: ToggleProjectReviewEnum, toggleCFSelected: ToggleProjectCustomFieldsEnum, toggleMoreOptions: ToggleProjectMoreOptionsEnum): [Array<TabParameters>, Array<TabParameters>, { modalVisible: boolean, modalContent: ModalProps }] {

  const { importGuid } = useQuery()
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const { currentProject, currentStep } = useSelector((state: RootState) => state.core)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState<ModalProps>(initialModal)

  const handleModal = async (content: ModalProps) => {
    await setModalContent(content)
    setModalVisible(true)
  }

  const handleHideModal = () => {
    setModalVisible(false)
  }


  const megaTabs = [
    {
      name: 'More options',
      toggleList: [
        {
          name: 'Fields visualizer',
          type: 'page',
          component: <ProjectMoreOptions />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_fields_visualizer,
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },
        },
        {
          name: 'Incremental loads',
          type: 'page',
          component: <ProjectMoreOptions />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_incremental_loads,
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },
        },
        {
          name: 'API access',
          type: 'page',
          component: <ProjectMoreOptions />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_api_access,
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },
        },
        {
          name: 'Logs',
          type: 'page',
          component: <ProjectMoreOptions />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_logs,
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },

        },
        {
          name: 'Clone Project',
          type: 'page',
          component: <ProjectMoreOptions />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_clone,
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },
        },
        {
          name: 'Settings',
          type: 'page',
          component: <ProjectMoreOptions />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_settings,
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },
        },
        {
          name: `${currentProject?.active ? 'Disable' : 'Enable'} project`,
          type: 'modal',
          component: <ProjectMoreOptions />,
          parameters: {},
          url: () => '',
          onClick: (project?: IProject) => handleModal({
            title: project?.active || currentProject?.active ? 'Disable project' : 'Enable project',
            description: '',
            placeholder: 'Write the name of the project you want to disable',
            hasClipboard: false,
            initialInputValue: '',
            confirmAction: (confirmMessage?: string) => project?.active || currentProject?.active ? disableProject(project, confirmMessage) : enableProject(project, confirmMessage),
            cancelAction: () => handleHideModal()
          }),
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },
          getName: (project: IProject) => project.active ? 'Disable project' : 'Enable project',
        },
        {
          name: 'Delete project',
          type: 'modal',
          component: <ProjectMoreOptions />,
          parameters: {},
          url: () => '',
          onClick: (project?: IProject) => handleModal({
            title: 'Delete project',
            description: '',
            placeholder: 'Write the name of the project you want to delete',
            hasClipboard: false,
            initialInputValue: '',
            confirmAction: (confirmMessage?: string) => deleteProject(project, confirmMessage),
            cancelAction: () => handleHideModal()
          }),
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },
        },
      ],
      toggleSelected: toggleMoreOptions ?? 0,
    },
  ]

  const subTabs = [
    {
      name: 'Overview',
      component: <ProjectOverview />,
      parameters: { projectId: currentProject?.id },
      url: urls.project
    },
    {
      name: 'Review data',
      component: <ProjectTableView />,
      parameters: { projectId: currentProject?.id },
      url: urls.project_table,
      toggleList: [
        {
          name: 'Table view data',
          component: <ProjectTableView />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_table,
        },
        {
          name: 'Stored data',
          component: <ProjectTableView />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_stored,
        },
        {
          name: 'Data analyzer',
          component: <ProjectTableView />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_analyzer,
        }
      ],
      toggleSelected: toggleSelected ?? 0,
    },
    {
      name: 'Custom fields',
      component: <ProjectFieldConversions />,
      parameters: { projectId: currentProject?.id },
      url: urls.project_fields,
      toggleList: [
        {
          name: 'Field conversions',
          component: <ProjectFieldConversions />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_fields
        },
        {
          name: 'AI enrichments',
          component: <ProjectFieldConversions />,
          parameters: { projectId: currentProject?.id },
          url: urls.project_ai_enrichments
        },
      ],
      toggleSelected: toggleCFSelected ?? 0,
    },
    {
      name: <div>Actions <img src={WUDActionsLogo} alt='WUD Actions logo' width={'16'} height={'100%'} /></div>,
      component: <ProjectActions />,
      parameters: { projectId: currentProject?.id },
      url: urls.project_actions
    },
    {
      name: 'Image transformation',
      component: <ProjectImageTransformation />,
      parameters: { projectId: currentProject?.id },
      url: urls.project_imaging
    },
    {
      name: 'More Options',
      component: <ProjectMoreOptions />,
      parameters: {},
      url: () => '',
      hidden: true
    },
  ]

  const logProject = (project: IProject, area = "Project deleted", message = `Project has been deleted`) => {
    apiClient.logProjectModification(project.id ?? currentProject?.id ?? importGuid() ?? '', null, null, area,
      message, session.data.accessToken).then(
        (log: { data: {}, error: any }) => {
          const { data, error } = log
          if (error === null) {
            // Here I will have to update the list by calling the project again
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching logProject:', error)

      })
  }

  const deleteProject = (project?: IProject, confirmMessage = '') => {
    if ((project && confirmMessage === project?.name) || (!project && confirmMessage === currentProject?.name)) {
      apiClient.deleteProject(project?.id ?? currentProject?.id ?? importGuid() ?? '', session.data.accessToken).then(
        (projectDeleted: { data: boolean, error: any }) => {
          const { data, error } = projectDeleted
          if (error === null) {
            if (project) { logProject(project, "Project deleted", `Project has been deleted`) } else if (currentProject) { logProject(currentProject, "Project deleted", `Project has been deleted`) }
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching deleteProject:', error)

      })
    }
  }

  const disableProject = (project?: IProject, confirmMessage = '') => {
    if ((project && confirmMessage === project?.name) || (!project && confirmMessage === currentProject?.name)) {
      apiClient.disableProject(project?.id ?? currentProject?.id ?? importGuid() ?? '', session.data.accessToken).then(
        (projectDisabled: { data: boolean, error: any }) => {
          const { data, error } = projectDisabled
          if (error === null) {
            if (project) { logProject(project,"Project disabled",`Project has been disabled`) } else if (currentProject) { logProject(currentProject, "Project disabled", `Project has been disabled`) }
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching disableProject:', error)

      })
    }
  }

  const enableProject = (project?: IProject, confirmMessage = '') => {
    if ((project && confirmMessage === project?.name) || (!project && confirmMessage === currentProject?.name)) {
      apiClient.enableProject(project?.id ?? currentProject?.id ?? importGuid() ?? '', session.data.accessToken).then(
        (projectDisabled: { data: boolean, error: any }) => {
          const { data, error } = projectDisabled
          if (error === null) {
            if (project) { logProject(project,"Project enabled",`Project has been enabled`) } else if (currentProject) { logProject(currentProject, "Project enabled", `Project has been enabled`) }
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching enableProject:', error)
      })
    }
  }

  return [subTabs, megaTabs, { modalVisible, modalContent }];
}
