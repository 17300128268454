import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { StepValidateContainer } from './StepValidateStyles'

export default function StepValidate() {

  return (
    <StepValidateContainer>
      <IframeConnect />
    </StepValidateContainer>
  )
}
