import { useState } from "react";
import { useSelector } from "react-redux";
import { urls } from "@/routes/Routes";
import { RootState } from "@/store/store";
import StepSource from "@/containers/steps/stepSource/StepSource";
import StepValidate from "@/containers/steps/stepValidate/StepValidate";
import StepMappings from "@/containers/steps/stepMappings/StepMappings";
import StepCreate from "@/containers/steps/stepCreate/StepCreate";
import StepOverview from "@/containers/steps/stepOverview/StepOverview";
import { ModalProps } from "@/components/modal/Modal";
import { useQuery } from "./useQuery";
import { useLocation } from "react-router";
import Helpers from "@/containers/helpers/Helpers";
import StepMoreOptions from "@/containers/steps/stepMoreOptions/StepMoreOptions";
import { apiClient } from "@/api/extendedAPI";
import { useSessionContext } from "@/contexts/SessionContext";
import { useRequestHandler } from "./useRequestHandler";
import { IStep } from "@/interfaces/types";


const initialModal = {
  title: '',
  description: '',
  confirmAction: () => null,
  cancelAction: () => null
}

export interface TabParameters {
  [key: string]: any;
}

export enum ToggleStepMoreOptionsEnum {
  CrawledPages,
  FilterRows,
  FeedCompare,
  EditUrl,
  DefineMerge
}

export function useStepContent(toggleSelected: ToggleStepMoreOptionsEnum): [Array<TabParameters>, Array<TabParameters>, { modalVisible: boolean, modalContent: ModalProps }] {

  const location = useLocation()
  const { projectStatus, importGuid, importStepGuid, stepNumber } = useQuery()
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const { currentProject, currentStep } = useSelector((state: RootState) => state.core)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState<ModalProps>(initialModal)


  const handleModal = async (content: ModalProps) => {
    await setModalContent(content)
    setModalVisible(true)
  }

  const handleHideModal = () => {
    setModalVisible(false)
  }

  const megaTabs = [
    {
      name: 'More options',
      toggleList: [
        {
          name: 'View crawled pages',
          type: 'page',
          component: <StepMoreOptions />,
          parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
          url: urls.step_crawled,
          hidden: !['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? ''),
          isHidden: (step: IStep) => !['CrawlerSimple', 'PageScanner'].includes(step.sourceType ?? ''),
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id, stepId: step?.id }) },
        },
        {
          name: 'Edit data source',
          type: 'page',
          component: <StepSource />,
          parameters: { projectId: currentProject?.id, stepId: currentStep?.id, stepNumber: currentStep?.stepNumber ?? 1 },
          url: urls.step_source,
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id, stepId: step?.id }) },
        },
        {
          name: 'Edit mappings',
          type: 'page',
          component: <StepMappings />,
          parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
          url: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '') ? urls.step_new_crawl : urls.step_mappings,
          getUrl: (step: IStep) => ['CrawlerSimple', 'PageScanner'].includes(step?.sourceType ?? '') ? urls.step_new_crawl({ projectId: currentProject?.id ?? '', stepId: step?.id }) : urls.step_mappings({ projectId: currentProject?.id ?? '', stepId: step?.id }),
          hidden: false,
          isHidden: () => false,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id, stepId: step?.id }) }
        },
        {
          name: 'Filter out rows',
          type: 'page',
          component: <StepMoreOptions />,
          parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
          url: urls.step_filtering,
          hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? ''),
          isHidden: (step: IStep) => ['CrawlerSimple', 'PageScanner'].includes(step.sourceType ?? ''),
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id, stepId: step?.id }) },
        },
        {
          name: 'Feed compare',
          type: 'page',
          component: <Helpers />,
          parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
          url: urls.feed_compare_step,
          hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? ''),
          isHidden: (step: IStep) => ['CrawlerSimple', 'PageScanner'].includes(step.sourceType ?? ''),
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id, stepId: step?.id }) },
        },
        {
          name: 'Edit url',
          type: 'modal',
          component: <StepMappings />,
          parameters: {},
          url: () => '',
          onClick: (step?: IStep) => getStepUrl(step),
          hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? ''),
          isHidden: (step: IStep) => ['CrawlerSimple', 'PageScanner'].includes(step.sourceType ?? ''),
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id }) },
        },
        {
          name: 'Change merge criteria',
          type: 'page',
          component: <StepMappings />,
          parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
          url: urls.step_merge,
          hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '') || currentStep?.stepNumber === 1,
          isHidden: (step: IStep) => ['CrawlerSimple', 'PageScanner'].includes(step.sourceType ?? '') || step.stepNumber === 1,
          itemParameters: (step: IStep) => { return ({ projectId: currentProject?.id, stepId: step?.id }) },
        },
        {
          name: 'Delete step',
          type: 'modal',
          component: <StepMappings />,
          parameters: {},
          url: () => '',
          onClick: (step?: IStep) => handleModal({
            title: 'Delete step',
            description: '',
            placeholder: 'Write the name of the step you want to delete',
            hasClipboard: false,
            initialInputValue: '',
            confirmAction: (confirmMessage?: string) => deleteStep(step, confirmMessage),
            cancelAction: () => handleHideModal()
          }),
          hidden: false,
          isHidden: (step: IStep) => false,
          itemParameters: (step: IStep) => { },
          soonAvailable: true,
        },
      ],
      toggleSelected: toggleSelected ?? 0,
    },
  ]

  const subTabs = [
    {
      name: 'Home',
      component: <StepOverview handleModal={handleModal} handleHideModal={handleHideModal} moreOptions={megaTabs} />,
      parameters: { projectId: currentProject?.id },
      url: urls.stepList,
      hidden: true
    },
    {
      name: 'Source',
      component: <StepSource />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id, stepNumber: currentStep?.stepNumber ?? 1 },
      url: urls.step_source
    },
    {
      name: 'Validate',
      component: <StepValidate />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: urls.step_validate,
      hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '')
    },
    {
      name: 'Mappings',
      component: <StepMappings />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '') ? urls.step_new_crawl : urls.step_mappings
    },
    {
      name: 'Define Merge',
      component: <></>,
      parameters: {},
      url: () => '',
      hidden: true
    },
    {
      name: 'More Options',
      component: <StepMoreOptions />,
      parameters: {},
      url: () => '',
      hidden: true
    },
  ]

  const subTabsNoFirst = [
    {
      name: 'Home',
      component: <StepOverview handleModal={handleModal} handleHideModal={handleHideModal} moreOptions={megaTabs} />,
      parameters: { projectId: currentProject?.id },
      url: urls.stepList,
      hidden: true
    },
    {
      name: 'Source',
      component: <StepSource />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id, stepNumber: currentStep?.stepNumber },
      url: urls.step_source
    },
    {
      name: 'Validate',
      component: <StepValidate />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: urls.step_validate,
      hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '')
    },
    {
      name: `Mappings`,
      component: <StepMappings />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '') ? urls.step_new_crawl : urls.step_mappings
    },
    {
      name: 'Define merge',
      component: <StepMappings />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: urls.step_merge,
      hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '')
    },
    {
      name: 'More Options',
      component: <StepMoreOptions />,
      parameters: {},
      url: () => '',
      hidden: true
    },
  ]

  const subTabsNewProject = [
    {
      name: 'Home',
      component: <StepOverview handleModal={handleModal} handleHideModal={handleHideModal} moreOptions={megaTabs} />,
      url: urls.stepList,
      hidden: true
    },
    {
      name: 'Source',
      component: <StepSource />,
      url: urls.project_new
    },
    {
      name: 'Validate',
      component: <StepValidate />,
      parameters: { projectId: importGuid() ?? '', stepId: importStepGuid() ?? '' },
      url: urls.project_new_validate,
      hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '')
    },
    {
      name: 'Mappings',
      component: <StepMappings />,
      parameters: { projectId: importGuid() ?? '', stepId: importStepGuid() ?? '' },
      url: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '') ? urls.step_new_crawl : urls.project_new_mappings
    },
    {
      name: 'Define Merge',
      component: <></>,
      parameters: {},
      url: () => '',
      hidden: true
    },
    {
      name: 'More Options',
      component: <StepMoreOptions />,
      parameters: {},
      url: () => '',
      hidden: true
    },
  ]

  const subTabsNewStep = [
    {
      name: 'Home',
      component: <StepOverview handleModal={handleModal} handleHideModal={handleHideModal} moreOptions={megaTabs} />,
      url: urls.stepList,
      hidden: true
    },
    {
      name: 'Source',
      component: <StepSource />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: urls.step_new_source
    },
    {
      name: 'Validate',
      component: <StepValidate />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: urls.step_new_validate,
      hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '')
    },
    {
      name: 'Mappings',
      component: <StepMappings />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '') ? urls.step_new_crawl : urls.step_new_mappings
    },
    {
      name: 'Define merge',
      component: <StepMappings />,
      parameters: { projectId: currentProject?.id, stepId: currentStep?.id },
      url: urls.step_new_merge,
      hidden: ['CrawlerSimple', 'PageScanner'].includes(currentStep?.sourceType ?? '')
    },
    {
      name: 'More Options',
      component: <StepMoreOptions />,
      parameters: {},
      url: () => '',
      hidden: true
    },
  ]


  const getStepUrl = (step?: IStep) => {
    apiClient.getStepUrl(step?.id ?? currentStep?.id ?? importStepGuid() ?? '', session.data.accessToken).then(
      (stepUrl: { data: string, error: any }) => {
        const { data, error } = stepUrl
        if (error === null) {
          handleModal({
            title: 'Update feed url',
            description: '',
            placeholder: 'Write the feed url',
            hasClipboard: true,
            initialInputValue: data,
            confirmAction: (url?: string) => updateStepUrl(url ?? data, step),
            cancelAction: () => handleHideModal()
          })
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching getStepUrl:', error)

    })
  }

  const updateStepUrl = (url: string, step?: IStep) => {
    apiClient.updateStepUrl(url, step?.id ?? currentStep?.id ?? importStepGuid() ?? '', session.data.accessToken).then(
      (stepUrl: { data: string, error: any }) => {
        const { data, error } = stepUrl
        if (error === null) {
          console.log({ data })
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching updateStepUrl:', error)

    })
  }

  const deleteStep = (step?: IStep, confirmMessage = '') => {
    if ((step && confirmMessage === step?.name) || (!step && confirmMessage === currentStep?.name)) {
      apiClient.deleteStep(step?.id ?? currentStep?.id ?? importStepGuid() ?? '', session.data.accessToken).then(
        (stepDeleted: { data: boolean, error: any }) => {
          const { data, error } = stepDeleted
          if (error === null && data) {
            if (step) { logProject(step) } else if (currentStep) { logProject(currentStep) }
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching deleteStep:', error)

      })
    }
  }

  const logProject = (step: IStep) => {
    apiClient.logProjectModification(currentProject?.id ?? importGuid() ?? '', null, null, "Project step deleted",
      `Deleted import step: ${step.name}`, session.data.accessToken).then(
        (log: { data: {}, error: any }) => {
          const { data, error } = log
          if (error === null) {
            // Here I will have to update the list by calling the project again
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching deleteStep:', error)

      })
  }

  return [projectStatus() === 'new' ? subTabsNewProject : (projectStatus() === 'edit' || location.pathname.includes('merge') || location.pathname.includes('filtering') || location.pathname.includes('crawl')) ? ((currentStep?.stepNumber ?? 1) > 1 || location.pathname.includes('merge')) ? subTabsNoFirst : subTabs : subTabsNewStep, megaTabs, { modalVisible, modalContent }];
}
