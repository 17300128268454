import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ProjectOverviewContainer } from './ProjectOverviewStyles'

export default function ProjectOverview() {

  return (
    <ProjectOverviewContainer>
      <IframeConnect />
    </ProjectOverviewContainer>
  )
}
