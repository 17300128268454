import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ChannelSourceContainer } from './ChannelSourceStyles'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSteps, setStepsLoading } from '@/store/stepsSlice'
import { setChannels, setChannelsLoading } from '@/store/channelsSlice'
import { IApiProjectDetailsResponse, apiClient } from '@/api/extendedAPI'
import { useRequestHandler } from '@/hooks/useRequestHandler'
import { RootState } from '@/store/store'
import { IApiExports, IApiImportSteps } from '@/api/endpointsRoutes'
import { useSessionContext } from '@/contexts/SessionContext'
import { useQuery } from '@/hooks/useQuery'

export default function ChannelSource() {

  return (< ChannelSourceContainer >
    <IframeConnect />
  </ChannelSourceContainer >
  )
}
