import { Button } from '@/components/button/Button';
import { ClipboardIcon, CloseIcon } from '@/components/icons/Icons';
import { Label } from '@/components/text/Text';
import ModalLayout from './ModalLayout';
import { ModalContainer, HeaderContainer, FooterContainer, Content, Input, InputContainer, IconContainer } from './ModalStyle'
import { ChangeEvent, useState } from 'react';

export interface ModalProps {
  type?: 'loadGuid' | 'confirmation' | 'input';
  placeholder?: string;
  initialInputValue?: string;
  hasClipboard?: boolean;
  title: string;
  description: string;
  confirmAction: (param?: string) => void | null;
  cancelAction: () => void | null;
}

export default function Modal({ type = 'confirmation', placeholder = 'Insert a guid', initialInputValue = '', hasClipboard = false, title, description, confirmAction, cancelAction }: ModalProps) {

  const [modalInput, setModalInput] = useState(initialInputValue)

  const handleCancel = () => {
    cancelAction()
  }

  const handleConfirm = () => {
    confirmAction(modalInput)
    handleCancel()
  }

  const confirmComponent = () => {
    return (
      <>
        <Content>
          {description}
        </Content>
        <FooterContainer>
          <Button onClick={handleCancel} $buttonType='secondary' text={'Cancel'} />
          <Button onClick={handleConfirm} $buttonType='primary' text={'Confirm'} />
        </FooterContainer>
      </>
    )
  }

  const validationComponent = () => {
    return (
      <>
        <Content>
          {description}
          <InputContainer>
            <Input
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  handleConfirm();
              }} autoFocus={true}
              type='text'
              name='modalInput'
              placeholder={placeholder}
              value={modalInput}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setModalInput(event.target.value)} />
            {hasClipboard && <IconContainer>
              <ClipboardIcon title='Copy to clipboard' cursor={'pointer'} hover={'var(--secondary)'} $size={1.25} onClick={() => navigator.clipboard.writeText(modalInput ?? '')} />
            </IconContainer>}
          </InputContainer>
        </Content>
        <FooterContainer>
          <Button onClick={handleCancel} $buttonType='secondary' text={'Cancel'} />
          <Button onClick={handleConfirm} $buttonType='primary' text={'Confirm'} disabled={!modalInput} />
        </FooterContainer>
      </>
    )
  }

  return (
    <ModalLayout>
      <ModalContainer>
        <HeaderContainer>
          <Label fontSize={1.25}>
            {title}
          </Label>
          <CloseIcon cursor={'pointer'} onClick={handleCancel} $size={1.5} hover={'var(--secondary)'} />
        </HeaderContainer>
        {type === 'confirmation' ? confirmComponent() : validationComponent()}
      </ModalContainer>
    </ModalLayout>
  )
}
