import { css, styled } from "styled-components";
import { Label, Link, Span } from "@/components/text/Text";
import { BaseIcon } from "@/components/icons/Icons";

export const StepReorderContainer = styled.div`
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0 ;
  position: absolute;
  top: 2rem;
  left: 1rem;
  width: 92vw;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  //background-color: var(--secondary-lighter); 
  padding: 1.5rem 1rem 0 1rem;
  z-index: 1;
`

export const Description = styled(Label)`
  padding: 0.5rem 1rem 1rem 1rem;
`

export const ScrollContainer = styled.div`
  padding-top: 1rem;
  max-height: calc(60vh - 5rem); 
  overflow-y: auto; 
  overflow-x: hidden;
`;

export const ListItemsContainer = styled.div`
  display: grid;
  gap: 0.55rem;
  grid-template-columns: auto;
  grid-auto-rows: min-content;
  padding-bottom: 1rem;
`
export const Item = styled(Label)`
  background-color: var(--white);
  text-align: left;
  border: 1px solid var(--grey);
  color: var(--main);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  display: flex;
  gap: 1rem;
  touch-action: none;
  cursor: grab;
`

export const Note = styled(Label)`
  padding-left: 1rem;

  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const FooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding: 1.5rem 1rem;

  @media (max-width: 750px) {
    gap: 0.5rem;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
  }
`