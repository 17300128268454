import { routes } from '@/routes/Routes'
import Board from '@/components/board/Board'
import SubMenu from '@/components/menu/submenu/SubMenu'
import Tools from './tools/Tools'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { setCurrentTab } from '@/store/coreSlice'
import { tabTypes } from '@/constants/const'
import TemplateManagement from './templateManagement/TemplateManagement'
import Shopify from './shopify/Shopify'

interface AdminLayoutProps {
  toggleTab: number;
  toggleSubTab?: ToggleAdminMoreToolsEnum;
  tagName?: string;
}

export enum ToggleAdminLayoutEnum {
  TemplateManagementNew,
  TemplateManagementOld,
  MoveImports,
  Shopify,
  Css,
  ImageTransformation,
  MoreTools,
}

export enum ToggleAdminMoreToolsEnum {
  Tools,
  ManageActiveImports,
  ManageCrawlers,
  PlatformLog,
  HelpTexts,
  FindBasedUrl
}

export default function AdminLayout({ toggleTab, toggleSubTab }: AdminLayoutProps) {

  const dispatch = useDispatch()
  const { currentTab } = useSelector((state: RootState) => state.core)
  const elements = [
    {
      name: 'Template management [NEW]',
      component: <TemplateManagement />,
      url: routes.template_management_list,
    },
    {
      name: 'Template management [OLD]',
      component: <TemplateManagement />,
      url: routes.template_management,
    },
    {
      name: 'Move imports',
      component: <TemplateManagement />,
      url: routes.move_import,
    },
    {
      name: 'Shopify',
      component: <Shopify />,
      url: routes.shopify_projects,
    },
    {
      name: 'CSS',
      component: <TemplateManagement />,
      url: routes.css_management,
    },
    {
      name: 'Image Transformation',
      component: <TemplateManagement />,
      url: routes.image_management,
    },
    {
      name: 'More admin tools',
      component: <Tools />,
      url: routes.tools,
      toggleList: [
        {
          name: 'More admin tools',
          component: <Tools />,
          url: routes.tools,
        },
        {
          name: 'Manage active imports',
          component: <TemplateManagement />,
          url: routes.manage_active_imports,

        },
        {
          name: 'Manage crawlers',
          component: <TemplateManagement />,
          url: routes.manage_crawlers,
        },
        {
          name: 'Platform Log',
          component: <Tools />,
          url: routes.platform_log,
        },
        {
          name: 'Help texts',
          component: <Tools />,
          url: routes.help_texts,
        },
        {
          name: 'Find based on url',
          component: <Tools/>,
          url: routes.find_owner_export
        }
      ],
      toggleSelected: toggleSubTab ?? 0,
    },
  ]
  const { component } = elements[toggleTab]


  useEffect(() => {
    if (currentTab !== tabTypes.ADMIN)
      dispatch(setCurrentTab(tabTypes.ADMIN))
  }, [dispatch])

  return (
    <Board
      subMenu={<SubMenu
        currentSubTab={toggleTab}
        leftOption={<></>}
        elements={elements}
      />} >
      {component}
    </Board>
  )
}
