import { createSlice } from '@reduxjs/toolkit'
import { IManagerState } from '@/interfaces/types';

const initialState: IManagerState = {
  partnersList: { data: [], loading: false },
  clientsList: { data: [], loading: false },
  projectsList: { data: [], loading: false },
  channelsList: { data: [], loading: false },
}

const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    setManagerPartnersList: (state, action) => {
      state.partnersList = { data: action.payload, loading: false }
    },
    setManagerClientsList: (state, action) => {
      state.clientsList = { data: action.payload, loading: false }
    },
    setManagerProjectsList: (state, action) => {
      state.projectsList = { data: action.payload, loading: false}
    },
    setManagerChannelsList: (state, action) => {
      state.channelsList = {  data: action.payload, loading: false }
    },
    setManagerPartnersLoading: (state, action) => {
      state.partnersList = { ...state.partnersList, loading: action.payload }
    },
    setManagerClientsLoading: (state, action) => {
      state.clientsList = { ...state.clientsList, loading: action.payload }
    },
    setManagerProjectsLoading: (state, action) => {
      state.projectsList = { ...state.projectsList, loading: action.payload }
    },
    setManagerChannelsLoading: (state, action) => {
      state.channelsList = { ...state.channelsList, loading: action.payload }
    },
  }
})

export const {
  setManagerPartnersList,
  setManagerClientsList,
  setManagerProjectsList,
  setManagerChannelsList,
  setManagerPartnersLoading,
  setManagerClientsLoading,
  setManagerProjectsLoading,
  setManagerChannelsLoading
} = managerSlice.actions

export default managerSlice.reducer