import { routes, urls } from '@/routes/Routes'
import Board from '@/components/board/Board'
import { useSessionContext } from '@/contexts/SessionContext'
import SubMenu from '@/components/menu/submenu/SubMenu'
import BillingCsm from '../admin/billingCsm/BillingCsm';
import { tabTypes } from '@/constants/const';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { setCurrentTab } from '@/store/coreSlice';
import Users from './Users';
import UsersManagement from '../admin/users/management/UsersManagement';
import UsersCreate from '../admin/users/create/UsersCreate';

interface UsersLayoutProps {
  toggleTab: number;
}

export enum ToggleUsersEnum {
  Manage,
  Create,
}

export default function UsersLayout({ toggleTab }: UsersLayoutProps) {

  const dispatch = useDispatch()
  const { session } = useSessionContext()
  const { currentTab } = useSelector((state: RootState) => state.core)
  const isAdmin = session.data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));
  const elements = [
    {
      name: 'Manage',
      component: isAdmin ? <UsersManagement /> : <Users create={false} />,
      url: isAdmin ? routes.client_management : routes.users,
      isAdmin: isAdmin
    },
    {
      name: 'Create / Edit user',
      component: isAdmin? <UsersCreate /> : <Users create={true}/>, // We need to create and update to role management user creation
      url: isAdmin ? routes.client_management_new : urls.users_create, // We need to create and update to role management user creation,
      isAdmin: isAdmin
    },
  ]
  const { component } = elements[toggleTab]

  useEffect(() => {
    if (currentTab !== tabTypes.USERS)
      dispatch(setCurrentTab(tabTypes.USERS))
  }, [dispatch])

  return (
    <Board
      subMenu={<SubMenu
        currentSubTab={toggleTab}
        leftOption={<></>}
        elements={elements}
      />} >
      {component}
    </Board>
  )
}
