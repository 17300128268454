import React, { useRef, useEffect } from 'react';
import { SearchIcon } from '@/components/icons/Icons';
import { SearchBarContainer, SearchInput } from './SearchBarStyles';

interface SearchBarProps {
  getDisplayValue: () => string;
  placeholder: string;
  setQuery: (value: string) => void;
  disabled: boolean;
  isOpen: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ getDisplayValue, placeholder, setQuery, disabled, isOpen }) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 200);
    }
  }, [isOpen]);

  return (
    <SearchBarContainer>
      <SearchIcon $size={0.75} />
      <SearchInput
        ref={searchInputRef}
        type="text"
        placeholder={placeholder}
        value={getDisplayValue()}
        name="searchTerm"
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        disabled={disabled}
        autoComplete="off"
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
