import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IApiProjectDetailsResponse, IApiProjectsResponse, apiClient } from '@/api/extendedAPI';
import { RootState } from '@/store/store';
import { setCurrentChannel, setCurrentProject, setCurrentStep } from '@/store/coreSlice';
import { urls } from '@/routes/Routes';
import { useAccountSelection } from '@/hooks/useAccountSelection';
import { IProject } from '@/interfaces/types';
import { accountConst } from '@/constants/const';
import { IApiExports, IApiImportSteps, IApiProject, IApiProjectDetails } from '@/api/endpointsRoutes';
import { useSessionContext } from '@/contexts/SessionContext';
import { setProjects, setProjectsLoading } from '@/store/projectsSlice';
import { setChannels, setChannelsLoading } from '@/store/channelsSlice';
import { setSteps, setStepsLoading } from '@/store/stepsSlice';
import { clearSession } from '@/store/sessionSlice';
import SearchableDropdown from '@/components/searchableDropdown/SearchableDropdown';
import { useRequestHandler } from '@/hooks/useRequestHandler';
import { useQuery } from '@/hooks/useQuery';

export default function ProjectSelection() {

  const dispatch = useDispatch()
  const { importGuid } = useQuery()
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const { currentProject } = useSelector((state: RootState) => state.core)
  const { projectsList } = useSelector((state: RootState) => state.projects)
  const [isProjectsVisible, setIsProjectsVisible] = useState(false);


  const selectProject = async (project: IProject) => {

    if (session.data.accessToken) {
      getProject(project.id)
      dispatch(setCurrentProject(project))
      dispatch(setCurrentStep(null))
      dispatch(setCurrentChannel(null))
    }
  }

  const getProject = (projectId: string) => {
    dispatch(setStepsLoading(true))
    dispatch(setChannelsLoading(true))
    apiClient.getProject2(projectId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (project: IApiProjectDetailsResponse) => {
        const { data, error } = project
        if (!error) {
          dispatch(setChannels(data?.Exports.map((channel: IApiExports) => { return { id: channel.ExportGuid, name: channel.Title, type: channel.TemplateName ? `Template: ${channel.TemplateName}` : `Custom: ${channel.Type} - ${channel.SenderType}`, active: channel.Active, totalItems: channel.RowsExported, exportType: channel.TemplateName ? 'SetupTemplateGenerator' : 'SetupGenerator' } })))
          dispatch(setSteps(data?.ImportSteps.map((step: IApiImportSteps) => { return { id: step.ImportStepGuid, name: step.Title, sourceType: step.ParserType, stepNumber: step.StepNumber, merge: step.InsertMethod, importTypeOptionId: step.ImportTypeOptionId } })))
        } else {
          dispatch(setStepsLoading(false))
          dispatch(setChannelsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  const listProjects = (userId: string) => {
    dispatch(setProjectsLoading(true))
    apiClient.listProjects2(userId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (projects: IApiProjectsResponse) => {
        const { data, error } = projects
        if (error === null) { dispatch(setProjects(data?.map((project: IApiProject) => { return { id: project.ImportGuid, name: project.Title, active: project.Active, totalItems: project.Count ? project.Count.toFixed() : 0, totalChannels: project.Exports.length } }))) }
        else {
          dispatch(setProjectsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching projects:', error)
    })
  }


  useEffect(() => {
    if (session.data.accessToken) listProjects(session.data.activeUserGuid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (importGuid()) {
      const project = projectsList?.data.find((item: IProject, index: number) => item.id === importGuid())
      if (project) {
        dispatch(setCurrentProject(project))
        selectProject(project)
      }
    } else {
      dispatch(setCurrentProject(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsList])


  return (
    <div className='step-top-menu-project-selection' style={{ display: 'flex' }}>
      <SearchableDropdown
        type={accountConst.PROJECTS}
        isLink={true}
        getUrl={(item: any) => urls.project({ projectId: item.id })}
        items={projectsList.data}
        selected={currentProject}
        setSelected={selectProject}
        isOpen={isProjectsVisible}
        setIsOpen={setIsProjectsVisible}
        headerActions={[{ type: 'list', link: urls.home(), action: () => null, disabled: false }, { type: 'create', link: urls.project_new(), action: () => null, disabled: false, }]}
        isLoading={projectsList.loading}
      />
    </div>
  )
}
