import styled, { css } from 'styled-components';
import { Label, Link } from '@/components/text/Text';


export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 1rem;
`

export const SettingsTitle = styled(Label)`
  font-size: 2rem;
  padding: 2rem;
  display: block;
`

export const ToggleContainer = styled(Label)`
  display: flex;
  gap: 1rem;
`

interface ToggleTabProps {
  $selected: boolean;
}

export const ToggleTab = styled(Link) <ToggleTabProps>`
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  &:hover {
    color: var(--secondary);
    background-color: var(--secondary-light);
  }

  ${props => props.$selected &&
    css`
    background-color: var(--secondary-light);
     `
  }
`

export const SettingsSubContainer = styled.div`
  height: 100%;
  width: 100%;
`;
