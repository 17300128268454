import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routes, urls } from '@/routes/Routes';
import { RootState } from '@/store/store';
import { setCurrentStep, setCurrentTab } from '@/store/coreSlice';
import { ToggleStepMoreOptionsEnum, useStepContent } from '@/hooks/useStepContent';
import { useAccountSelection } from '@/hooks/useAccountSelection';
import { IStep } from '@/interfaces/types';
import { tabTypes } from '@/constants/const';
import Board from '@/components/board/Board';
import SubMenu from '@/components/menu/submenu/SubMenu';
import Modal from '@/components/modal/Modal';
import { NewTagContainer, StepsContainer } from './StepLayoutStyles';
import { useQuery } from '@/hooks/useQuery';
import { ClipboardIcon } from '@/components/icons/Icons';
import WUDTooltip from '@/components/tooltip/Tooltip';
import { Label } from '@/components/text/Text';
import SearchableDropdown from '@/components/searchableDropdown/SearchableDropdown';
import { useSessionContext } from '@/contexts/SessionContext';

interface StepLayoutProps {
  enumTab: StepTabs;
  toggleMoreOptions: ToggleStepMoreOptionsEnum;
}

export enum StepTabs {
  Home,
  Source,
  Validate,
  Mappings,
  DefineMerge,
  MoreOptions
}

const StepLayout = ({ enumTab, toggleMoreOptions }: StepLayoutProps) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { importGuid, importStepGuid, projectStatus } = useQuery()
  const { checkAndRedirect } = useAccountSelection()
  const { getProject } = useSessionContext().session
  const [subTabs, megaTabs, { modalVisible, modalContent }] = useStepContent(toggleMoreOptions)
  const { currentProject, currentTab, currentStep } = useSelector((state: RootState) => state.core)
  const { stepsList } = useSelector((state: RootState) => state.steps)
  const [isAccordionVisible, setIsAccordionVisible] = useState(false)
  const { component } = subTabs[enumTab]
  const guid = currentStep?.id ?? importStepGuid()

  const selectStep = (step: IStep) => {
    dispatch(setCurrentStep(step))
  }

  const copyGuidToClipboard = () => {
    navigator.clipboard.writeText(guid ?? '');
  }

  const handleHeaderClick = () => {
    currentStep && dispatch(setCurrentStep(null))
  }

  useEffect(() => {

    //getProject(currentProject?.id ?? importGuid() ?? '')
    if (currentTab !== tabTypes.STEPS) {
      dispatch(setCurrentTab(tabTypes.STEPS))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (importStepGuid() && importStepGuid() !== currentStep?.id) {
      const step = stepsList?.data.find((step: IStep) => step.id === importStepGuid())
      if (step) {
        dispatch(setCurrentStep(step))
      }
    }
  }, [])

  return (
    <>
      <Board subMenu={<SubMenu
        leftOption={
          (!projectStatus() || projectStatus() === 'edit') ? <SearchableDropdown
            labelType='hidden'
            isOpen={isAccordionVisible}
            setIsOpen={setIsAccordionVisible}
            type={tabTypes.STEPS}
            items={stepsList.data}
            selected={currentStep}
            setSelected={selectStep}
            iconSelected={pathname === routes.stepList()}
            setIconSelected={() =>
              navigate(routes.stepList())
            }
            isLink={true}
            getUrl={(item: any) => urls.step_source({ projectId: currentProject?.id ?? '', stepId: item.id, stepNumber: item.stepNumber })}
            headerActions={[{ type: 'list', link: urls.stepList(), action: () => handleHeaderClick(), disabled: false }, { type: 'create', link: urls.step_new_source({ projectId: currentProject?.id ?? '' }), action: () => null, disabled: false, }]}
            isLoading={stepsList.loading}
          /> : <NewTagContainer fontSize={0.75} color={'var(--main)'}  >{projectStatus() === 'new' ? 'NEW PROJECT' : 'NEW STEP'}</NewTagContainer>}
        currentSubTab={enumTab}
        elements={(currentStep || projectStatus()) && routes.stepList() !== pathname ? subTabs : []}
        megaTabs={((enumTab > 0 || currentStep) && (!projectStatus() || projectStatus() === 'edit')) && routes.stepList() !== pathname ? megaTabs : []}
        type={projectStatus() === 'edit' || (!projectStatus() && pathname.includes('merge')) || (!projectStatus() && pathname.includes('filtering')) || (!projectStatus() && pathname.includes('crawl')) ? 'options' : 'stepper'}
        allowForward={projectStatus() === 'edit' || (!projectStatus() && pathname.includes('merge')) || (pathname.includes('filtering')) || (!projectStatus() && pathname.includes('crawl'))}
      />}>

        <StepsContainer>
          {component}
        </StepsContainer>

      </Board>
      {modalVisible &&
        <Modal title={modalContent.title} description={modalContent.description} confirmAction={modalContent.confirmAction} cancelAction={modalContent.cancelAction} type='input' placeholder={modalContent.placeholder} initialInputValue={modalContent.initialInputValue} hasClipboard={modalContent.hasClipboard} />
      }
      {/* {guid &&
        <div style={{
          backgroundColor: 'var(--grey)',
          position: 'absolute',
          bottom: '5vh',
          left: '4vw',
          display: 'flex',
          gap: '0.5rem',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          zIndex: 200,
          alignItems: 'center'
        }}>
          <Label>
            KEY:
          </Label>
          <Label fontWeight='500'>
            {guid}
          </Label>
          <WUDTooltip id='clipboard-guid' content='Copy guid to clipboard' >
            <div style={{ cursor: 'pointer', borderRadius: '0.25rem', border: '1px solid var(--secondary)', padding: '0.25rem 0.5rem', display: 'flex' }}>
              <ClipboardIcon $size={1.25} color={'var(--secondary)'} onClick={copyGuidToClipboard} />
            </div>
          </WUDTooltip>
        </div>
      } */}
    </>
  )
}

export default StepLayout;