import { routes } from '@/routes/Routes';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IframeConnect from '../iframeConnect/IframeConnect';

export default function Logout() {

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!location.search.includes('cause=LogOff')) navigate(routes.home())
  }, [])

  return <IframeConnect height={100} />
}
