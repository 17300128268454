import styled from 'styled-components';

export const BoardContainer = styled.div`
  background-color: var(--background);
  width: 100%;
  height: 90vh;
  display: flex;
`;

export const ChildContainer = styled.div`
  margin: 4rem 0 0 0;
  width: 100%;
`