import styled from 'styled-components';

export const MenuContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--main);
  z-index: 20;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  top: 0;
  font-weight: 700;
  margin-top: 2.813rem;
`;

export const TabsLeftContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: fixed;
  z-index: 2;
`;

export const TabsRightContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: fixed;
  right: 0;
  flex: 0.25;
  z-index: 2;
  height: 2.813rem;
`;
