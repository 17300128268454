import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { routes, urls } from '@/routes/Routes';
import { RootState } from '@/store/store';
import { setCurrentChannel, setCurrentTab } from '@/store/coreSlice';
import { useQuery } from '@/hooks/useQuery';
import { ToggleChannelMoreOptionsEnum, ToggleChannelReviewEnum, useChannelContent } from '@/hooks/useChannelContent';
import { useAccountSelection } from '@/hooks/useAccountSelection';
import { IChannel } from '@/interfaces/types';
import { tabTypes } from '@/constants/const';
import SubMenu from '@/components/menu/submenu/SubMenu';
import Board from '@/components/board/Board';
import Modal from '@/components/modal/Modal';
import { ChannelContainer, NewTagContainer } from './ChannelLayoutStyles';
import { Label } from '@/components/text/Text';
import { ClipboardIcon } from '@/components/icons/Icons';
import WUDTooltip from '@/components/tooltip/Tooltip';
import SearchableDropdown from '@/components/searchableDropdown/SearchableDropdown';


interface ChannelLayoutProps {
  enumTab: ChannelTabs;
  toggleSubTab?: ToggleChannelReviewEnum;
  toggleMoreOptions: ToggleChannelMoreOptionsEnum;
}

export enum ChannelTabs {
  Home = 0,
  Source = 1,
  ReviewData = 1,
  Mappings = 2,
  Filtering = 3,
  ChannelReview = 4,
  MoreOptions
}


const ChannelLayout = ({ enumTab, toggleSubTab, toggleMoreOptions }: ChannelLayoutProps) => {
  const { exportGuid, firstChannel } = useQuery()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { checkAndRedirect } = useAccountSelection()
  const [subTabs, megaTabs, { modalVisible, modalContent }] = useChannelContent(toggleSubTab ?? 0, toggleMoreOptions)
  const { currentTab, currentChannel, currentProject } = useSelector((state: RootState) => state.core)
  const { channelsList } = useSelector((state: RootState) => state.channels)
  const [isAccordionVisible, setIsAccordionVisible] = useState(false)
  const { component } = subTabs[enumTab]
  const guid = currentChannel?.id ?? exportGuid()

  const selectChannel = (channel: IChannel) => {
    dispatch(setCurrentChannel(channel))
  }

  useEffect(() => {
    //checkAndRedirect(tabTypes.CHANNELS)
    if (currentTab !== tabTypes.CHANNELS)
      dispatch(setCurrentTab(tabTypes.CHANNELS))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleHeaderClick = () => {
    currentChannel && dispatch(setCurrentChannel(null))
  }

  const handleHomeRendering = () => {
    if (currentChannel) {
      navigate(urls.channel({ channelId: currentChannel.id }))
    }
    else { navigate(urls.channelList()) }
  }

  const copyGuidToClipboard = () => {
    navigator.clipboard.writeText(guid ?? '');
  }


  return (
    <>
      <Board subMenu={<SubMenu
        leftOption={!firstChannel() && !location.pathname.includes(routes.channel_new()) ?
          <SearchableDropdown
            labelType='hidden'
            type={tabTypes.CHANNELS}
            isLink={true}
            getUrl={(item: any) => urls.channel({ channelId: item.id })}
            items={channelsList.data}
            selected={currentChannel}
            setSelected={selectChannel}
            isOpen={isAccordionVisible}
            setIsOpen={setIsAccordionVisible}
            headerActions={[{ type: 'list', link: urls.channelList(), action: () => handleHeaderClick(), disabled: false }, { type: 'create', link: urls.channel_new({ projectId: currentProject?.id ?? '', firstChannel: currentProject?.channels?.length ? true : null }), action: () => null, disabled: false, }]}
            iconSelected={pathname === urls.channelList() || pathname === routes.channel()}
            setIconSelected={() =>
              handleHomeRendering()
            }
            isLoading={channelsList.loading}
          /> : <NewTagContainer fontSize={0.75} color={'var(--main)'}  >{currentChannel?.id === exportGuid() ? currentChannel.name : 'NEW CHANNEL'}</NewTagContainer>}
        currentSubTab={enumTab}
        elements={(currentChannel || firstChannel() || location.search.includes('force')) && (routes.channelList() !== pathname) ? subTabs : []}
        megaTabs={(routes.channelList() !== pathname) ? megaTabs : []}
        type={!firstChannel() && !location.pathname.includes(routes.channel_new()) ? 'options' : 'stepper'}
        allowForward={!firstChannel() && !location.pathname.includes(routes.channel_new())}
      />} >
        <ChannelContainer>
          {component}
        </ChannelContainer>
      </Board >
      {modalVisible &&
        <Modal title={modalContent.title} description={modalContent.description} confirmAction={modalContent.confirmAction} cancelAction={modalContent.cancelAction} type='input' placeholder={modalContent.placeholder} initialInputValue={modalContent.initialInputValue} hasClipboard={modalContent.hasClipboard} />
      }
      {/* {guid &&
        <div style={{
          backgroundColor: 'var(--grey)',
          position: 'absolute',
          bottom: '5vh',
          left: '4vw',
          display: 'flex',
          gap: '0.5rem',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          zIndex: 200,
          alignItems: 'center'
        }}>
          <Label>
            KEY:
          </Label>
          <Label fontWeight='500'>
            {guid}
          </Label>
          <WUDTooltip id='clipboard-guid' content='Copy guid to clipboard' >
            <div style={{ cursor: 'pointer', borderRadius: '0.25rem', border: '1px solid var(--secondary)', padding: '0.25rem 0.5rem', display: 'flex' }}>
              <ClipboardIcon $size={1.25} color={'var(--secondary)'} onClick={copyGuidToClipboard} />
            </div>
          </WUDTooltip>
        </div>
      } */}
    </>
  )
}

export default ChannelLayout;