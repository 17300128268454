import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorIcon, WarningIcon, ChevronRightIcon } from '@/components/icons/Icons';
import { TooltipContainer, LabelContent, ItemContainer, MainLabel } from './SearchableItemStyles';
import { accountConst, tabTypes } from '@/constants/const';
import WUDTooltip from '@/components/tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Label } from '../text/Text';

interface SearchableItemProps {
  item: any;
  type: string;
  isLink: boolean;
  getUrl: (parameter: any) => string;
  handleSelect: (item: any) => void;
}

const SearchableItem: FC<SearchableItemProps> = ({
  item, type, isLink, getUrl, handleSelect
}) => {

  const { t } = useTranslation()
  // THIS IS TEMPORARY, WITH THE NEW API WE SHOULDNT NEED TO CALL THE STORE BUT THIS IS THE FAST WAY
  const { errorsList } = useSelector((state: RootState) => state.users)

  const handleSelectItem = (event: React.MouseEvent<HTMLAnchorElement, PointerEvent>) => {
    if (!isLink) event?.preventDefault()
    handleSelect(item);
  };

  const alertIcon = (id: string) => {
    const projectWithIssues = errorsList.data.find((error) => item.id === error)
    if (projectWithIssues) {
      return (
        <TooltipContainer>
          <WUDTooltip id={`issue-tooltip-project-${id}`}
            customContent={<Label fontWeight='500' fontSize={0.75} style={{ textAlign: 'start' }}>
              {t('common.accordion.warning.issue', { count: 1, type: 'project' })}
            </Label>}
          >
            <WarningIcon
              $size={0.75}
              color={'var(--warning)'}
              style={{ paddingBottom: '0.2rem' }}
            />
          </WUDTooltip>
        </TooltipContainer>

      );
    }
    return null;
  };

  const getMergeMethod = (insertMethod: number) => {
    switch (insertMethod) {
      case 1:
        return 'Insert data rows'
      case 2:
        return 'Insert or merge data rows'
      case 3:
        return 'Insert or replace data row'
      case 4:
        return 'Merge data'
      case 5:
        return 'Replace data'
      case 6:
        return 'Insert or delete data rows'
      case 7:
        return 'Delete rows'
      case 8:
        return 'Insert row, if new'
      default:
        return 'No merge criteria defined'
    }
  }

  const getDescription = (type: string) => {
    switch (type) {
      case accountConst.CLIENTS:
        return `${item.email ?? ''}`
      case accountConst.PROJECTS:
        return `${item.totalItems ?? '?'} items | ${item.totalChannels ?? '?'} channels`
      case accountConst.CHANNELS:
        return item.type ? `${item.type} | ${item.totalItems ?? '?'} items exported` : ''
      case tabTypes.STEPS:
        return item.stepNumber ? `${item.sourceType ?? 'Unknown type'} ${item.stepNumber !== 1 ? `| ${getMergeMethod(item.merge ?? 0)}` : ''}` : ''
      default:
        return ``
    }
  }

  return (
    <ItemContainer
      role="option"
      to={getUrl(item)}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, PointerEvent>) => handleSelectItem(event)}
    >
      <LabelContent>
        <MainLabel fontSize={0.75}>
          {item.name} {type === accountConst.PROJECTS && alertIcon(item.id)}
        </MainLabel>
        <MainLabel
          fontSize={0.7}
          color={'var(--secondary)'}
          fontStyle={'italic'}
          fontWeight={'normal'}>
          {getDescription(type) ?? ''} 
        </MainLabel>
      </LabelContent>
      <ChevronRightIcon color={'var(--main)'} />
    </ItemContainer >
  );
};

export default SearchableItem;