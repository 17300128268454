import { urls } from '@/routes/Routes'
import Board from '@/components/board/Board'
import { useSessionContext } from '@/contexts/SessionContext'
import SubMenu from '@/components/menu/submenu/SubMenu'
import AccountSettings from './AccountSettings'
import PreferencesSettings from './PreferencesSettings'

interface SettingsLayoutProps {
  toggleTab: number;
}

export enum ToggleSettingsEnum {
  Account,
  Preferences
}

export default function SettingsLayout({ toggleTab }: SettingsLayoutProps) {

  const { session } = useSessionContext()
  const elements = [
    {
      name: 'Account',
      component: <AccountSettings />,
      parameters: { clientId: session.data.userGuid },
      url: urls.settings
    },
    {
      name: 'Preferences',
      component: <PreferencesSettings />,
      url: urls.preferences
    },
  ]
  const { component } = elements[toggleTab]

  return (
    <Board
      subMenu={<SubMenu
        currentSubTab={toggleTab}
        leftOption={<></>}
        elements={elements}
      />} >
      {component}
    </Board>
  )
}
