import styled from 'styled-components'
import { Label, Link } from '../text/Text'

export const FloatingMenuContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`

export const FloatingMenuGrid = styled.div`
  flex: 0.5;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(15rem,1fr));
  grid-gap: 0.5rem; 
`

export const FloatingMenuGridItem = styled(Link)`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 1rem;
  border-radius: 1rem;
  background-color: var(--light-blue);
  color: var(--main);
  padding: 0.5rem;
  min-width: 6vw;
  cursor: pointer;
  
  &:hover, &:hover ${Label} {
    background-color:  var(--light-blue-lighter); 
    color: var(--secondary);
  }

  &:hover path {
    fill: var(--secondary);
  }
`;

export const FloatingElement = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border-radius: 4rem;
  background-color: var(--green);
  color: var(--main);
  padding: 1rem;
  cursor: pointer;
  
  &:hover {
    background-color: var(--green-light);
    color: var(--secondary);
  }

  &:hover path {
    fill: var(--secondary);
  }
`
export const SecondaryFloatingElement = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border-radius: 4rem;
  background-color: var(--light-blue); 
  color: var(--main);
  padding: 1rem;
  cursor: pointer;
  z-index: 100;
  
  &:hover, &:hover ${Label} {
    background-color:  var(--light-blue-lighter); 
    color: var(--secondary);
  }

  &:hover path {
    fill: var(--secondary);
  }
`

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;

  &:hover {
    color: var(--secondary);
  }
`

export const RightContent = styled.div`
  display: flex;
`
