import { createSlice } from '@reduxjs/toolkit'
import { IClientsState } from '@/interfaces/types';

const initialState: IClientsState = {
  clientsList: { data: [], loading: false }
}

const clientsSlice = createSlice({
  name: 'clientsList',
  initialState,
  reducers: {
    setClients: (state, action) => {
      state.clientsList = { data: action.payload, loading: false }
    },
    setClientsLoading: (state, action) => {
      state.clientsList = { ...state.clientsList, loading: action.payload }
    }
  }
})

export const {
  setClients,
  setClientsLoading
} = clientsSlice.actions

export default clientsSlice.reducer