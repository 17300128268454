import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { SettingsSubContainer } from './SettingsStyles'

export default function AccountSettings() {
  return (
    <SettingsSubContainer>
      <IframeConnect />
    </SettingsSubContainer>
  )
}
