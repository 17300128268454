import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ChannelMoreOptionsContainer } from './ChannelMoreOptionsStyles'

export default function ChannelMoreOptions() {

  return (
    <ChannelMoreOptionsContainer>
      <IframeConnect />
    </ChannelMoreOptionsContainer>
  )
}
