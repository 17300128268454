import { AdminTagContainer } from './AdminTagStyles';
import { Label } from '@/components/text/Text';
import { BaseText } from './../text/Text';

interface AdminTagProps {

}

export default function AdminTag() {
  return (
    <AdminTagContainer fontSize={0.5}>
      ADMIN
    </AdminTagContainer>
  )
}
