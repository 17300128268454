import { routes, urls } from '@/routes/Routes'
import Board from '@/components/board/Board'
import { useSessionContext } from '@/contexts/SessionContext'
import SubMenu from '@/components/menu/submenu/SubMenu'
import Helpers from './Helpers';
import { tabTypes } from '@/constants/const';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { setCurrentTab } from '@/store/coreSlice';
import { HelpersLayoutContainer } from './HelpersLayoutStyles';
import Modal from '@/components/modal/Modal';

interface HelpersLayoutProps {
  toggleTab: number;
}

export enum ToggleHelpersEnum {
  Home,
  FeedCompare,
  CustomIntegration
}

export default function HelpersLayout({ toggleTab }: HelpersLayoutProps) {

  const dispatch = useDispatch()
  const { data, modal } = useSessionContext().session
  const { currentTab } = useSelector((state: RootState) => state.core)
  const elements = [
    {
      name: 'Overview',
      component: <Helpers  />,
      url: routes.helpers
    },
    {
      name: 'Feed compare',
      component: <Helpers  />,
      url: urls.feed_compare_helpers
    },
    {
      name: 'Custom integration',
      component: <Helpers  />,
      url: routes.custom_integration_helpers
    },
  ]
  const { component } = elements[toggleTab]

  useEffect(() => {
    if (currentTab !== tabTypes.HELPERS)
      dispatch(setCurrentTab(tabTypes.HELPERS))
  }, [])

  return (
    <Board
      subMenu={<SubMenu
        currentSubTab={toggleTab}
        leftOption={<></>}
        elements={elements}
      />} >
      <HelpersLayoutContainer>
        {component}
      </HelpersLayoutContainer>
      {modal.modalVisible &&
        <Modal title={modal.modalContent.title} description={modal.modalContent.description} confirmAction={modal.modalContent.confirmAction} cancelAction={modal.modalContent.cancelAction} type='input' placeholder={modal.modalContent.placeholder} />
      }
    </Board>
  )
}
