import Board from '@/components/board/Board'
import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ShopifyContainer, ShopifyTitle } from './ShopifyStyles'

export default function Shopify() {

  return (
    <Board>
      <ShopifyContainer>
        <IframeConnect />
      </ShopifyContainer>
    </Board>
  )
}
