import styled from 'styled-components';
import { Label } from '@/components/text/Text';


export const SubscriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 1rem;
`

export const SubscriptionsTitle = styled(Label)`
  font-size: 2rem;
  padding: 2rem;
  display: block;
`

export const SubscriptionsDescription = styled(Label)`
  display: block;
`
