import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ChannelReviewContainer } from './ChannelReviewStyles'

export default function ChannelReview() {
  return (
    <ChannelReviewContainer>
      <IframeConnect />
    </ChannelReviewContainer>
  )
}
