import { createSlice } from '@reduxjs/toolkit'
import { IProjectsState } from '@/interfaces/types';

const initialState: IProjectsState = {
  projectsList: { data: [], loading: false }
}

const projectsSlice = createSlice({
  name: 'projectsList',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projectsList = { data: action.payload, loading: false }
    },
    setProjectsLoading: (state, action) => {
      state.projectsList = { ...state.projectsList, loading: action.payload }
    }
  }
})

export const {
  setProjects,
  setProjectsLoading
} = projectsSlice.actions

export default projectsSlice.reducer