import Board from '@/components/board/Board'
import { ActionButton, ActionsContainer, HeaderContainer, ListContainer, OverviewContainer, UserEmail, UserItem, UserItemContainer, UsersContainer } from './UsersStyles'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { routes, urls } from '@/routes/Routes'
import { useQuery } from '@/hooks/useQuery'
import IframeConnect from '../iframeConnect/IframeConnect'
import { Button } from '@/components/button/Button'
import { AddIcon, EditIcon } from '@/components/icons/Icons'
import { Label } from '@/components/text/Text'
import { useSessionContext } from '@/contexts/SessionContext'
import { useNavigate } from 'react-router-dom'

export default function Users(props: { create?: boolean } = {}) {

  const navigate = useNavigate()
  const { subaccountGuid } = useQuery()
  const { usersList } = useSelector((state: RootState) => state.users)
  const { session } = useSessionContext()
  const { currentClient } = useSelector((state: RootState) => state.core)
  const isAdmin = session.data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));

  return (
    subaccountGuid() || props.create ? <IframeConnect /> :
      <OverviewContainer>
        <HeaderContainer>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
            <Label fontSize={1.25}>{currentClient?.name}</Label>
          </div>
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }} >
            <div>
              <Button $buttonType='creation' $buttonSize='medium' icon={<AddIcon />} text='Create an user' onClick={() => navigate(isAdmin ? routes.client_management_new() : urls.users_create())} />
            </div>
          </div>
        </HeaderContainer>
        <ListContainer>
          {usersList?.data?.map((user, index) =>
            <UserItemContainer>
              <UserItem key={index} to={urls.users_edit({ subaccountGuid: user.id })}>
                {`${user.firstName} ${user.lastName}`}
                <UserEmail fontWeight='500'>
                  {user.email}
                </UserEmail>
              </UserItem>
              <ActionsContainer>
                <ActionButton to={urls.users_edit({ subaccountGuid: user.id })} >
                  Edit
                  <EditIcon $size={1} cursor={'pointer'} />
                </ActionButton>
              </ActionsContainer>
            </UserItemContainer>
          )}
        </ListContainer>
      </OverviewContainer>
  )
}
