import styled, { css } from 'styled-components';
import { ItemsContainer } from './SearchableListStyles';
import { Label } from '@/components/text/Text';

interface AccordionContainerProps { }
export const AccordionContainer = styled.div<AccordionContainerProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;  
  cursor: pointer;
`;


export const AccordionContent = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: 1rem;
`;

export const AccordionSingleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid var(--grey);
  border-radius: 0.675rem;
  padding: 0.25rem 0.75rem;
`;

interface AccordionLabelProps {
  $custom?: boolean;
}
export const AccordionLabel = styled(Label) <AccordionLabelProps>`
  margin: 0;
  padding-top: 0.2rem;

  ${props =>
    props.$custom &&
    css`
      padding-left: 0.5rem;
      max-width: 50vw;
      min-width: 12vw;
  `}

  @media (max-width: 1600px) {
    padding: 0.1rem;
  }

  @media (max-width: 960px) {
    padding: 0.28rem;
  }
`;

interface ArrowContainerProps { }
export const ArrowContainer = styled.div<ArrowContainerProps>`
  display: flex;
  padding-right: 1rem;
`;

interface LeftIconContainerProps {
  $selected?: boolean;
}
export const LeftIconContainer = styled.div<LeftIconContainerProps>`
  border: 1px solid var(--grey);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-color: var(--white);
  padding: 0.5rem;
  cursor: pointer;
  color: var(--main);

  &:hover {
    background-color: var(--secondary-lighter);
    color: var(--secondary);
  }

  ${props =>
    props.$selected &&
    css`
    background-color: var(--secondary-lighter);
    color: var(--secondary);
  `}

  @media (max-width: 1600px) {
    padding: 0.3rem;
  }

  @media (max-width: 960px) {
    padding: 0.28rem;
  }
`;

interface RightIconContainerProps { }
export const RightIconContainer = styled.div<RightIconContainerProps>`
  border: 1px solid var(--grey);
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: var(--white);
  padding: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1600px) {
    padding: 0.3rem;
  }

  @media (max-width: 960px) {
    padding: 0.28rem;
  }
`;

interface CustomSelectProps {
  $labelType?: 'default' | 'hidden' | 'medium' | 'none';
}
export const CustomSelect = styled.div<CustomSelectProps>`
  position: relative;

  &:hover {
    ${ItemsContainer} {
      opacity: 1;
      visibility: visible;
      max-height: 50vh;
    }
  }
  
  ${props =>
    props.$labelType === 'default' &&
    css`
      min-width: 17vw;

      &:hover {
        ${AccordionLabel} {
          color: var(--grey);
        }
      }

      @media (max-width: 1600px) {
        min-width: 17vw;
      }

      @media (max-width: 1300px) {
        min-width: 14vw;
      }

      @media (max-width: 1100px) {
        min-width: 14vw;
      }
  `}

  ${props =>
    props.$labelType === 'medium' &&
    css`
      min-width: 12vw;

      &:hover {
        ${AccordionLabel} {
          color: var(--grey);
        }
      }

      @media (max-width: 1300px) {
        min-width: 11rem;
      }

      @media (max-width: 800px) {
        min-width: 11.5rem;
      }
  `}
`;

interface CustomAccordionContainerProps { }
export const CustomAccordionContainer = styled.div<CustomAccordionContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${AccordionLabel}, ${RightIconContainer} {
      color: var(--secondary);
    }
  }  
`;

