import { createSlice } from '@reduxjs/toolkit'
import { ISessionState } from '@/interfaces/types';

const initialState: ISessionState = {
  currentSession: {
    accessToken: '',
    active: false,
    activeUserGuid: '',
    isSubaccount: false,
    roles: [],
    subaccounts: [],
    userGuid: '',
    userName: '',
    connectJwt: ''
  }
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setCurrentSession: (state, action) => {
      state.currentSession = {
        ...state.currentSession,
        ...action.payload
      }
    },
    clearSession: (state) => {
      state.currentSession = {
        accessToken: '',
        active: false,
        activeUserGuid: '',
        isSubaccount: false,
        roles: [],
        subaccounts: [],
        userGuid: '',
        userName: '',
        connectJwt: ''
      }
    },
  }
})

export const {
  setCurrentSession,
  clearSession
} = sessionSlice.actions

export default sessionSlice.reducer