export const accountConst = {
  SELECTOR_TYPE: 'account',
  PARTNER: 'partner',
  PARTNERS: 'partners',
  CLIENT: 'client',
  CLIENTS: 'clients',
  PROJECT: 'project',
  PROJECTS: 'projects',
  CHANNELS: 'channels',
  CHANNEL: 'channel',
  STATUS_OK: 'ok',
  STATUS_WARNING: 'warning',
  STATUS_ERROR: 'error'
}

export const accountType = {
  CLIENT: 'Client',
  AGENCY: 'Integration Partner',
  ADMIN: ''
}

export const tabTypes = {
  STEP: 'step',
  STEPS: 'steps',
  MANAGE: 'manage',
  CHANNEL: 'channel',
  CHANNELS: 'channels',
  STEPS_TITLE: 'IMPORT STEPS',
  MANAGE_TITLE: 'MANAGE DATA',
  CHANNELS_TITLE: 'CHANNELS',
  DASHBOARD: 'dashboard',
  DASHBOARD_TITLE: 'DASHBOARD',
  HOME: 'home',
  HOME_TITLE: 'DASHBOARD',
  USERS: 'users',
  USERS_TITLE: 'USERS',
  HELPERS: 'helpers',
  HELPERS_TITLE: 'HELPERS',
  SUBSCRIPTIONS: 'subscriptions',
  SUBSCRIPTIONS_TITLE: 'SUBSCRIPTIONS',
  SETTINGS: 'settings',
  SETTINGS_TITLE: 'SETTINGS',
  ADMIN: 'admin',
  ADMIN_TITLE: 'TOOLS'
}