import { styled } from "styled-components";
import { Label, Link, Span } from "@/components/text/Text";

export const ChannelOverviewContainer = styled.div`
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const Title = styled(Label)`
 
`

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: auto; /* Specify only one column */
  grid-auto-rows: min-content;
  padding-bottom: 2rem;
  gap: 1.5rem;
`;

export const ChannelItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  border-radius: 0.25rem;
  padding-right: 1rem;
  
  &:hover {
      background-color: var(--secondary-lighter);
    }
`

export const ChannelItem = styled(Link)`
 display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.25rem;
  text-align: left;
  color: var(--secondary);
  gap: 0.25rem;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ActionButton = styled.div`
  color: var(--white);
  font-weight:  600;
  background-color: var(--secondary);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: var(--main);
  }
  `

export const Tags = styled.div`
  display: flex;
  margin: 0.5rem 0;
  gap: 0.5rem;
`

export const Tag = styled(Span) <{ $backgroundColor?: string }>`
  padding: 0.25rem 0.5rem;
  background-color: ${props => props.$backgroundColor ?? 'var(--secondary-light)'} ;
  color: var(--white);
  border-radius: 0.25rem;
`


export const InfoContainer = styled.div`
  display: flex;
  margin: 0.5rem 0 0 0;
`

export const InfoItem = styled(Span)`
  display: flex;
  gap: 0.5rem;
  padding: 0.2rem 0.5rem;
  border: 1px solid var(--grey);
  color: var(--main);
  border-radius: 0.25rem;
  justify-content: space-evenly;
  min-width: 12vw;
`

export const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: var(--grey);
`