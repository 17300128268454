import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ProjectImageTransformationContainer } from './ProjectImageTransformationStyles'

export default function ProjectImageTransformation() {
  return (
    <ProjectImageTransformationContainer>
      <IframeConnect />
    </ProjectImageTransformationContainer>
  )
}
