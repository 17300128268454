import React from 'react';
import ClientSelection from './ClientSelection';
import ProjectSelection from './ProjectSelection';
import { AccountsContainer } from './AccountSelectionStyles';
import { useSessionContext } from '@/contexts/SessionContext';

interface AccountSelectionProps {
}

const AccountSelection: React.FC<AccountSelectionProps> = ({
}) => {
  const session = useSessionContext().session.data
  const isAdmin = session.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));
  const isPartner = session.roles.find((role) => role?.toLowerCase().includes('partner'))

  return (
    <AccountsContainer>
      {isAdmin ?
        <></>
        : isPartner ?
          <ClientSelection /> :
          <ProjectSelection />
      }
    </AccountsContainer>
  );
};

export default AccountSelection;