import styled, { css } from 'styled-components';

interface AdminProps {
  $isAdmin?: boolean;
}

export const AdminLayout = styled.div`
  background-color: var(--grey-darker);
  color: var(--main-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 52;
  height: 2.813rem;

  @media (max-width: 1500px) {
    height: 40px;
  }
`

export const LayoutContainer = styled.div`
  background-color: var(--main);
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  
`

export const LeftNav = styled.nav<AdminProps>`
  background-color: var(--white);
  color: var(--main-dark);
  width: 3rem;
  margin-top: 2.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 30;

  ${props => props.$isAdmin &&
    css`
    top: 2.813rem;

    @media (max-width: 1500px) {
      top: 40px;
  }
   `
  }

  @media (max-height: 530px) {
    overflow-y: auto;
    overflow-x: hidden;
 
    height: 100vh - 2.8rem; /* Set height to full viewport height */

    transition: width 0.3s ease;

    &:hover {
      width: 8rem;
    }

    &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--grey-light);
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary-light);
    border-radius: 25px;
  }
  }

  @media (max-width: 1500px) {
    width: 40px;
    margin-top: 40px;
  }
`

export const TopNav = styled.header<AdminProps>`
  background-color: var(--white);
  color: var(--main-dark);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 32;
  height: 2.813rem;

  ${props => props.$isAdmin &&
    css`
    top: 2.813rem;

    @media (max-width: 1500px) {
      top: 40px;
  }
  `}

  @media (max-width: 1500px) {
    height: 40px;
  }
`

export const LogoContainer = styled.a`
  padding: 0.1rem ;
  min-width: 8.75rem;
`

export const Logo = styled.img`
  max-height: 2.4rem; 
  cursor: pointer;

  @media (max-width: 1500px) {
    max-height: 30px;
  }
`

export const ConnectAnimation = styled.img`
  height: 1.92rem;

  @media (max-width: 1500px) {
    height: 22px;
  }
`

export const RightTopNav = styled.div`
  text-align: right;
  padding: 0.5rem;
`


export const ChildContainer = styled.main<AdminProps>`
  margin-top: 2.3rem;
  margin-left: 3.3rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: calc(100% - 3.3rem);

  @media (min-width: 1700px) {
    ${props => !props.$isAdmin &&
      css`
      margin-top: 2.5rem;
    `}
  }


  @media (min-width: 1900px) {
    ${props => !props.$isAdmin &&
      css`
      margin-top: 3.1rem;
    `}
  }

  ${props => props.$isAdmin &&
    css`

    margin-top: 96px;

    @media (max-width: 1750px) {
      margin-top: 85px;
    }
    
    @media (max-width: 1600px) {
      margin-top: 80px;
      margin-left: 44px;
    }

    @media (max-width: 1300px) {
      margin-top: 70px;
    }
  `}

  @media (max-width: 1500px) {
    width: calc(100% - 44px);
    ${props => !props.$isAdmin &&
      css`
      margin-left: 44px;
      margin-top: 32px;
    `}
  }
  
`

export const LeftTopNav = styled.div`
  width: 100%;
  flex: 1;
`

export const LeftBottomNav = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-height: 400px) {
    justify-content: flex-start;
  }
`

export const IconContainer = styled.div`
  padding: 1rem 0;
  text-align: center;
`