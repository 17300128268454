import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTab } from '@/store/coreSlice';
import { RootState } from '@/store/store';
import { Tab } from './TabStyles';
import SearchableList from '@/components/searchableDropdown/SearchableList';

interface MenuTabListProps {
  text?: string;
  link: string;
  icon?: any;
  hasValue?: boolean;
  isLink?: boolean;
  getUrl?: (params?: any) => string;
  items: any[]; // Replace 'any' with the actual type of items
  type: string; // Replace 'string' with the actual type
  setSelected: (selected: any) => void; // Replace 'any' with the actual type
  headerActions?: Array<{ type: 'create' | 'list', link: string, action: () => void, disabled: boolean }>;
  isLoading?: boolean;
}

const MenuTabList: React.FC<MenuTabListProps> = (props) => {
  const {
    link,
    text,
    items,
    type,
    icon,
    hasValue = false,
    isLink = false,
    getUrl = () => '',
    setSelected = () => null,
    headerActions = [],
    isLoading = false
  } = props;

  const [isVisible, setIsVisible] = useState(false);
  const { currentTab } = useSelector((state: RootState) => state.core)
  const dispatch = useDispatch()

  const handleClick = (event: React.PointerEvent<HTMLAnchorElement>, tabName: string) => {
    /* if (hasValue) {
      dispatch(setCurrentTab(tabName.toLowerCase()))
    } else {
      event?.preventDefault()
      setIsVisible(true)
    } */
    setIsVisible(true)
  };

  return (
    <div onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      <Tab color={'var(--white)'} $lineHeight={2.5} to={link} $selected={currentTab === type}
        $hover={isVisible} onClick={(event: React.PointerEvent<HTMLAnchorElement>) => handleClick(event, type)}>
        {icon} {text ?? type.toUpperCase()}
      </Tab>
      <div>
        <SearchableList
          isOpen={isVisible}
          setIsOpen={setIsVisible}
          items={items}
          labelToSearch="name"
          setSelected={setSelected}
          type={type}
          isLink={isLink}
          getUrl={getUrl}
          headerActions={headerActions}
          isLoading={isLoading}
        />
      </div>

    </div>
  );
};

export default MenuTabList;