import Board from '@/components/board/Board'
import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ToolsContainer, ToolsTitle } from './ToolsStyles'

export default function Tools() {

  return (
    <Board>
      <ToolsContainer>
        <IframeConnect />
      </ToolsContainer>
    </Board>
  )
}
