import Board from '@/components/board/Board'
import IframeConnect from '@/containers/iframeConnect/IframeConnect'

export default function UsersCreate() {

  return (
    <Board>
      <IframeConnect />
    </Board>
  )
}
