import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainLayout from './containers/mainLayout/MainLayout';
import IframeConnect from './containers/iframeConnect/IframeConnect';
import ProjectLayout, { ProjectTabs } from './containers/manageData/projectLayout/ProjectLayout';
import StepLayout, { StepTabs } from './containers/steps/stepLayout/StepLayout';
import ChannelLayout, { ChannelTabs } from './containers/channels/channelLayout/ChannelLayout';
import { routes } from './routes/Routes';
import SettingsLayout, { ToggleSettingsEnum } from './containers/settings/SettingsLayout';
import WorkInProgress from './containers/specialCases/WorkInProgress';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { SessionProvider } from './contexts/SessionContext';
import Logout from './containers/session/Logout';
import AdminLayout, { ToggleAdminLayoutEnum, ToggleAdminMoreToolsEnum } from './containers/admin/AdminLayout';
import HomeLayout, { ToggleHomeEnum } from './containers/home/HomeLayout';
import UsersLayout, { ToggleUsersEnum } from './containers/users/UsersLayout';
import SubscriptionsLayout, { ToggleSubscriptionsEnum } from './containers/subscriptions/SubscriptionsLayout';
import { ToggleProjectCustomFieldsEnum, ToggleProjectMoreOptionsEnum, ToggleProjectReviewEnum } from './hooks/useProjectContent';
import { ToggleChannelMoreOptionsEnum, ToggleChannelReviewEnum } from './hooks/useChannelContent';
import { ToggleStepMoreOptionsEnum } from './hooks/useStepContent';
import HelpersLayout, { ToggleHelpersEnum } from './containers/helpers/HelpersLayout';

const { REACT_APP_BASE_CONNECT_URL } = process.env

const App = () => {

  const [isEnabled, setIsEnabled] = useState(localStorage.getItem('WUDAdminEnabled'))

  return (
    <Router>
      <SessionProvider>
        <Routes>
          {!isEnabled ? <Route path='*' element={<WorkInProgress />} /> : <><Route element={<MainLayout />}>
            {/* LEFT NAVIGATION */}
            <Route path={routes.users()} element={<PrivateRoute element={<UsersLayout toggleTab={ToggleUsersEnum.Manage} />} />} />
            <Route path={routes.users_create()} element={<PrivateRoute element={<UsersLayout toggleTab={ToggleUsersEnum.Create} />} />} />
            <Route path={routes.users_edit()} element={<PrivateRoute element={<UsersLayout toggleTab={ToggleUsersEnum.Manage} />} />} />
            <Route path={routes.helpers()} element={<PrivateRoute element={<HelpersLayout toggleTab={ToggleHelpersEnum.Home} />} />} />
            <Route path={routes.custom_integration_helpers()} element={<PrivateRoute element={<HelpersLayout toggleTab={ToggleHelpersEnum.CustomIntegration} />} />} />
            <Route path={routes.feed_compare_helpers()} element={<PrivateRoute element={<HelpersLayout toggleTab={ToggleHelpersEnum.FeedCompare} />} />} />
            <Route path={routes.subscriptions()} element={<PrivateRoute element={<SubscriptionsLayout toggleTab={ToggleSubscriptionsEnum.Shopify} />} />} />
            <Route path={routes.settings()} element={<PrivateRoute element={<SettingsLayout toggleTab={ToggleSettingsEnum.Account} />} />} />
            <Route path={routes.preferences()} element={<PrivateRoute element={<SettingsLayout toggleTab={ToggleSettingsEnum.Preferences} />} />} />
            {/* HOME WITH AND WITHOUT SESSION ROUTES */}
            <Route path='/' element={<PrivateRoute element={<HomeLayout toggleTab={ToggleHomeEnum.Home} />} />} />
            <Route path={routes.home()} element={<PrivateRoute element={<HomeLayout toggleTab={ToggleHomeEnum.Home} />} />} />
            {/* STEP ROUTES */}
            <Route path={routes.stepList()} element={<PrivateRoute element={<StepLayout enumTab={StepTabs.Home} toggleMoreOptions={0} />} />} />
            <Route path={routes.step_source()} element={<PrivateRoute element={<StepLayout enumTab={StepTabs.Source} toggleMoreOptions={0} />} />} />
            <Route path={routes.step_validate()} element={<PrivateRoute element={<StepLayout enumTab={StepTabs.Validate} toggleMoreOptions={0} />} />} />
            <Route path={routes.step_mappings()} element={<PrivateRoute element={<StepLayout enumTab={StepTabs.Mappings} toggleMoreOptions={0} />} />} />
            <Route path={routes.step_new_merge()} element={<PrivateRoute element={<StepLayout enumTab={StepTabs.DefineMerge} toggleMoreOptions={0} />} />} />
            <Route path={routes.step_crawled()} element={<PrivateRoute element={<StepLayout enumTab={StepTabs.MoreOptions} toggleMoreOptions={ToggleStepMoreOptionsEnum.CrawledPages} />} />} />
            <Route path={routes.step_new_crawl()} element={<PrivateRoute element={<StepLayout enumTab={StepTabs.Mappings} toggleMoreOptions={ToggleStepMoreOptionsEnum.CrawledPages} />} />} />
            <Route path={routes.step_filtering()} element={<PrivateRoute element={<StepLayout enumTab={StepTabs.MoreOptions} toggleMoreOptions={ToggleStepMoreOptionsEnum.FilterRows} />} />} />
            {/* MANAGE DATA / PROJECT ROUTES */}
            <Route path={routes.project()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.Home} toggleMoreOptions={0} />} />} />
            <Route path={routes.project_stored()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.ReviewData} toggleSubTab={ToggleProjectReviewEnum.StoredData} toggleMoreOptions={0} />} />} />
            <Route path={routes.project_table()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.ReviewData} toggleSubTab={ToggleProjectReviewEnum.TableView} toggleMoreOptions={0} />} />} />
            <Route path={routes.project_analyzer()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.ReviewData} toggleSubTab={ToggleProjectReviewEnum.DataAnalyzer} toggleMoreOptions={0} />} />} />
            <Route path={routes.project_fields()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.FieldConversions} toggleCFSubTab={ToggleProjectCustomFieldsEnum.FieldConversions} toggleMoreOptions={0} />} />} />
            <Route path={routes.project_ai_enrichments()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.FieldConversions} toggleCFSubTab={ToggleProjectCustomFieldsEnum.AiEnrichments} toggleMoreOptions={0} />} />} />
            <Route path={routes.project_actions()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.Actions} toggleMoreOptions={0} />} />} />
            <Route path={routes.project_imaging()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.ImageTransformation} toggleMoreOptions={0} />} />} />
            <Route path={routes.project_fields_visualizer()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.MoreOptions} toggleMoreOptions={ToggleProjectMoreOptionsEnum.FieldsVisualizer} />} />} />
            <Route path={routes.project_incremental_loads()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.MoreOptions} toggleMoreOptions={ToggleProjectMoreOptionsEnum.IncrementalLoads} />} />} />
            <Route path={routes.project_fields_visualizer()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.MoreOptions} toggleMoreOptions={ToggleProjectMoreOptionsEnum.FieldsVisualizer} />} />} />
            <Route path={routes.project_api_access()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.MoreOptions} toggleMoreOptions={ToggleProjectMoreOptionsEnum.ApiAccess} />} />} />
            <Route path={routes.project_logs()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.MoreOptions} toggleMoreOptions={ToggleProjectMoreOptionsEnum.Logs} />} />} />
            <Route path={routes.project_clone()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.MoreOptions} toggleMoreOptions={ToggleProjectMoreOptionsEnum.Clone} />} />} />
            <Route path={routes.project_settings()} element={<PrivateRoute element={<ProjectLayout enumTab={ProjectTabs.MoreOptions} toggleMoreOptions={ToggleProjectMoreOptionsEnum.Settings} />} />} />
            {/* CHANNEL ROUTES */}
            <Route path={routes.channelList()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.Home} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.Home} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_new()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.Source} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_table()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.ReviewData} toggleSubTab={ToggleChannelReviewEnum.TableView} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_single()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.ReviewData} toggleSubTab={ToggleChannelReviewEnum.SingleView} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_mappings()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.Mappings} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_mappings_xml_template()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.Mappings} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_mappings_xml_custom()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.Mappings} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_mappings_csv_custom()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.Mappings} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_filtering()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.Filtering} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_review()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.ChannelReview} toggleMoreOptions={0} />} />} />
            <Route path={routes.channel_compare()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.MoreOptions} toggleMoreOptions={ToggleChannelMoreOptionsEnum.CompareChannel} />} />} />
            <Route path={routes.channel_clone()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.MoreOptions} toggleMoreOptions={ToggleChannelMoreOptionsEnum.Clone} />} />} />
            <Route path={routes.channel_settings()} element={<PrivateRoute element={<ChannelLayout enumTab={ChannelTabs.MoreOptions} toggleMoreOptions={ToggleChannelMoreOptionsEnum.Settings} />} />} />
            {/* ADMIN ROUTES */}
            <Route path={routes.admin_board()} element={<PrivateRoute element={<HomeLayout toggleTab={ToggleHomeEnum.Board} />} />} />
            <Route path={routes.template_management_list()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.TemplateManagementNew} />} />} />
            <Route path={routes.tools()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.MoreTools} toggleSubTab={ToggleAdminMoreToolsEnum.Tools} />} />} />
            <Route path={routes.dashboard_csm()} element={<PrivateRoute element={<HomeLayout toggleTab={ToggleHomeEnum.CsmDashboard} />} />} />
            <Route path={routes.dashboard_sales()} element={<PrivateRoute element={<HomeLayout toggleTab={ToggleHomeEnum.SalesDashboard} />} />} />
            <Route path={routes.find_owner_export()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.MoreTools} toggleSubTab={ToggleAdminMoreToolsEnum.FindBasedUrl} />} />} />
            <Route path={routes.client_management()} element={<PrivateRoute element={<UsersLayout toggleTab={ToggleUsersEnum.Manage} />} />} />
            <Route path={routes.shopify_projects()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.Shopify} />} />} />
            <Route path={routes.css_management()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.Css} />} />} />
            <Route path={routes.image_management()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.ImageTransformation} />} />} />
            <Route path={routes.move_import()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.MoveImports} />} />} />
            <Route path={routes.client_management_new()} element={<PrivateRoute element={<UsersLayout toggleTab={ToggleUsersEnum.Create} />} />} />
            <Route path={routes.manage_active_imports()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.MoreTools} toggleSubTab={ToggleAdminMoreToolsEnum.ManageActiveImports} />} />} />
            <Route path={routes.manage_crawlers()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.MoreTools} tagName={'Crawlers'} toggleSubTab={ToggleAdminMoreToolsEnum.ManageCrawlers} />} />} />
            <Route path={routes.template_management()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.TemplateManagementOld} />} />} />
            <Route path={routes.platform_log()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.MoreTools} toggleSubTab={ToggleAdminMoreToolsEnum.PlatformLog} />} />} />
            <Route path={routes.help_texts()} element={<PrivateRoute element={<AdminLayout toggleTab={ToggleAdminLayoutEnum.MoreTools} toggleSubTab={ToggleAdminMoreToolsEnum.HelpTexts} />} />} />
            <Route path={routes.load_guid()} element={<PrivateRoute element={<HomeLayout toggleTab={ToggleHomeEnum.Board} />} />} />
            {/* CONNECT-REACT HACKS ROUTES */}
            <Route path={routes.connect_active_user()} element={<PrivateRoute element={<div style={{
              height: '100vh',
              backgroundColor: 'var(--background)'
            }}><IframeConnect height={100} /> </div>} />} />
            <Route path={routes.connect_active_user_done()} element={<PrivateRoute element={<HomeLayout toggleTab={ToggleHomeEnum.Home} />} />} />
            {/* NOT FOUND IN REACT ROUTES */}
            {isEnabled && <Route path='*' element={<PrivateRoute element={<>
              <IframeConnect />
            </>} />} />}
          </Route>
            {/*  PUBLIC ROUTES */}
            <Route path={routes.login()}
              element={<PublicRoute
                element={<div style={{
                  height: '100vh',
                }}><IframeConnect height={100} /></div>} />}
            />
            <Route path={routes.logout_connect()}
              element={<div style={{
                height: '100vh',
              }}><Logout /></div>}
            /></>}

        </Routes>
      </SessionProvider>
    </Router>
  );
}

export default App;


/*Routes for Basic Users */
/*  <Route path='/' element={<Projects />} />
          <Route path='/projects/new' element={<ManageData />} />
          <Route path='/projects/:projectId/manage' element={<ManageData />} />
          <Route path='/projects/:projectId/steps' element={<Steps />} />
          <Route path='/projects/:projectId/steps/new' element={<Steps />} />
          <Route path='/projects/:projectId/steps/:stepId' element={<Steps />} />
          <Route path='/projects/:projectId/channels' element={<Channels />} />
          <Route path='/projects/:projectId/channels/new' element={<Channels />} />
          <Route path='/projects/:projectId/channels/:channelId' element={<Channels />} /> */

/*Routes for Agency Users */
/*           <Route path='/' element={<Clients />} />
          <Route path='/clients' element={<Clients />} />
          <Route path='/clients/:clientId/projects' element={<Projects />} />
          <Route path='/clients/:clientId/projects/new' element={<NewProject />} />
          <Route path='/clients/:clientId/projects/:projectId/manage' element={<ManageData />} />
          <Route path='/clients/:clientId/projects/:projectId/steps' element={<Steps />} />
          <Route path='/clients/:clientId/projects/:projectId/steps/new' element={<Steps />} />
          <Route path='/clients/:clientId/projects/:projectId/steps/:stepId' element={<Steps />} />
          <Route path='/clients/:clientId/projects/:projectId/channels' element={<Channels />} />
          <Route path='/clients/:clientId/projects/:projectId/channels/new' element={<Channels />} />
          <Route path='/clients/:clientId/projects/:projectId/channels/:channelId' element={<Channels />} /> */
/* <Route path='/Management/Dashboard' element={<Steps />} /> */
