import React from "react";
import { useLocation } from "react-router-dom";

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new WudUrlQueryValues(new URLSearchParams(search)), [search]);
}

class WudUrlQueryValues {
  private query: URLSearchParams;

  constructor(urlSearchParams: URLSearchParams) {
    const newParams = new URLSearchParams();
    for (const [name, value] of Array.from(urlSearchParams.entries())) {
      newParams.append(name?.toLowerCase(), value);
    }
    this.query = newParams;
  }

  importStepGuid = () => this.query.get('importstepguid');
  projectStatus = () => this.query.get('status');
  importGuid = () => this.query.get('importguid');
  exportGuid = () => this.query.get('exportguid');
  url = () => this.query.get('url');
  subaccountGuid = () => this.query.get('subaccountguid');
  firstChannel = () => this.query.get('firstchannel');
  stepNumber = () => this.query.get('stepnumber')
  guid = () => this.query.get('guid');


}


