import styled from 'styled-components';

/* Diagonal Border */
//clip-path: polygon(2% 0, 100% 0, 100% 100%, 0% 100%);
export const AccountsContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--secondary);
  border-bottom-left-radius: 0.625rem;
  z-index: 5;
  height: 100%;
`

export const DashedLine = styled.div`
  border: 1px dashed var(--white);
  height: 2.344vw;
`