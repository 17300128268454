import { Link as ReactLink } from 'react-router-dom';
import styled from 'styled-components';

interface BaseTextProps {
  fontSize?: number;
  $lineHeight?: number;
  color?: string;
  fontWeight?: string;
  fontStyle?: string;
}

export const BaseText = styled.p<BaseTextProps>`
  font-family: 'Montserrat', sans-serif;
  font-size: ${(props) => props.fontSize? props.fontSize*0.833 : 0.833}vw;
  line-height: ${(props) => (props.$lineHeight ? props.$lineHeight*0.833 :  (props.fontSize ? props.fontSize * 1.042 : 1.042))}vw;
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || '700'};
  font-style: ${(props) => props.fontStyle || 'normal'};
  cursor: inherit;
  vertical-align: middle;
  align-items: center;

  @media (max-width: 1600px) {
    font-size: ${(props) => (props.fontSize ?? 1) * 0.75}rem;
    line-height: ${(props) => (props.$lineHeight ?? (props.fontSize ? props.fontSize * 1.5 : 1.5)) * 0.75}rem;
  }

  @media (max-width: 1500px) {
    font-size: ${(props) => (props.fontSize ?? 1) * 0.7}rem;
    line-height: ${(props) => (props.$lineHeight ?? (props.fontSize ? props.fontSize * 1.5 : 1.5)) * 0.75}rem;
  }
`;

export const Link = styled(BaseText).attrs({ as: ReactLink })`
  text-decoration: none;
`;

export const Label = styled(BaseText).attrs({ as: 'label' })`
  display: block;
`;

export const Span = styled(BaseText).attrs({ as: 'span' })`

`;

export const Paragraph = styled(BaseText).attrs({ as: 'p' })`
  display: block;
`;

// To improve when we know more about  the sizes we will use
export const H1 = styled(BaseText).attrs({ as: 'h1' })`
  display: flex;
  font-size: 2rem;
`;
