import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ChannelFilteringContainer } from './ChannelFilteringStyles'

export default function ChannelFiltering() {
  return (
    <ChannelFilteringContainer>
      <IframeConnect />
    </ChannelFilteringContainer>
  )
}
