import { Label, Link, Span } from '@/components/text/Text';
import styled from 'styled-components';

export const HomeContainer = styled.div`
  background-color: var(--background);
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const Title = styled(Label)`
`

// CarouselContainer for a nice grid -> DONT DELETE
export const CarouselContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem,1fr));
  grid-auto-rows: min-content;
  padding-bottom: 2rem;
  gap: 1.5rem;
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: auto; /* Specify only one column */
  grid-auto-rows: min-content;
  padding-bottom: 2rem;
  gap: 1.5rem;
`

export const ProjectItem = styled.div`
 display: flex;
 justify-content: space-between;
 background-color: var(--white);
 border-radius: 0.25rem;
 padding-right: 1rem;
 
 &:hover {
    background-color: var(--secondary-lighter);
  }
`

export const ProjectItemLink = styled(Link)`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.25rem;
  text-align: left;
  color: var(--secondary);
  gap: 0.25rem;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`

/* 
export const ProjectItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: var(--white);
  padding: 1rem;
  border-radius: 0.25rem;
  text-align: left;
  color: var(--secondary);

  &:hover {
    background-color: var(--secondary-lighter);
  }
` */

export const TooltipContainer = styled.div`
  position: static;
  display: inline-block;
  padding: 0 0.2rem;
  cursor: pointer;
  z-index: 8;
  `;

export const Tags = styled.div`
  display: flex;
  margin: 0.5rem 0;
  gap: 0.5rem;
` 

export const Tag = styled(Span) <{ $backgroundColor?: string }>`
  padding: 0.25rem 0.5rem;
  background-color: ${props => props.$backgroundColor ?? 'var(--secondary-light)'} ;
  color: var(--white);
  border-radius: 0.25rem;
`


export const InfoContainer = styled.div`
  display: flex;
  margin: 0.5rem 0 0 0;
  gap: 1rem;
`

export const InfoItem = styled(Span)`
  display: flex;
  gap: 0.5rem;
  padding: 0.2rem 0.5rem;
  border: 1px solid var(--grey);
  color: var(--main);
  border-radius: 0.25rem;
  justify-content: space-evenly;
  min-width: 12vw;
`

export const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: var(--grey);
`

export const ActionButton = styled.div`
  color: var(--white);
  font-weight:  600;
  background-color: var(--secondary);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: var(--main);
  }

  `

/* export const GridContainer = styled.div`
  display: grid;
  margin-top: 14vh;
  border-radius: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.1rem 2rem;
  grid-auto-rows: min-content(100px, auto);
  grid-template-areas: 
    "header header header"
    "item1 item2 item3"
    "bigItem1 bigItem2 bigItem3";

  @media (max-width: 1300px) {
    margin-top: 4vh;
  }

`; */

export const SquareButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  width: 6vw; /* Set width relative to viewport width */
  height: 6vw; /* Set height relative to viewport width */
  border: none; 
  color: var(--main);
  background-color: var(--white); 
  border: 1px solid var(--grey)  ;
  border-radius: 0.5rem;
  cursor: pointer; /* Add cursor pointer */
  transition: background-color 0.3s; /* Add transition for smoother hover effect */
  /* Additional styles for button text */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.01);
  
  &:hover {
    background-color: var(--secondary-lighter); /* Change background color on hover */
  }
`;

/* export const DashedSquaredButton = styled(SquareButton)`
  border: 0.1rem dashed var(--grey);
  background-color: transparent;
  box-shadow: none;
  cursor: default;
  &:hover {
    background-color: transparent; 
    
  }
` */

/* export const DescriptionContainer = styled.div`
  display: flex;
  position: relative;
  background-color: var(--white); 
  border-radius: 0.5rem;
  text-align: center; 
`; */

/* export const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-lighter); 
  border: 1px solid var(--grey)  ;
  border-radius: 0.5rem;
  padding: 0.625rem;
  text-align: center; 
  align-items: center;
  grid-area: header; 
`; */

/* export const GridItem = styled.div`
  background-color: var(--white); 
  border: 1px solid var(--grey)  ;
  border-radius: 0.5rem;
  padding: 0.625rem;
`; */

/* export const BigGridItem = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white); 
  border: 1px solid var(--grey)  ;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  grid-area: bigItem;
`;
 */

/* export const CounterContainer = styled.div`
  animation: countAnimation 5s infinite alternate ease-in-out;
  font: 800 40px system-ui;
  padding: 2rem;
`; */