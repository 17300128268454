import React, { ReactNode, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { BoardContainer, ChildContainer } from './BoardStyles';

interface BoardProps {
    children: ReactNode;
    subMenu?: ReactElement;
}

const Board: React.FC<BoardProps> = ({ children, subMenu }) => {
    const { currentTab } = useSelector((state: RootState) => state.core)

    return (
        <BoardContainer>
            {currentTab && subMenu ? <>
                {subMenu}
                <ChildContainer>
                    {children}
                </ChildContainer>
            </>
                : children
            }
        </BoardContainer>
    )
}

export default Board;