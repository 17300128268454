import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { ISession } from "@/interfaces/types";
import { setSteps, setStepsLoading } from "@/store/stepsSlice";
import { setChannels, setChannelsLoading } from "@/store/channelsSlice";
import { IApiProjectDetailsResponse, apiClient } from "@/api/extendedAPI";
import { IApiExports, IApiImportSteps } from "@/api/endpointsRoutes";
import { useRequestHandler } from "./useRequestHandler";
import { ModalProps } from "@/components/modal/Modal";

const initialModal = {
  title: '',
  description: '',
  confirmAction: () => null,
  cancelAction: () => null
}

export interface SessionHookResult {
  data: ISession;
  loading: boolean;
  getProject: (projectId: string) => void;
  modal: {
    modalVisible: boolean;
    modalContent: ModalProps;
    confirmModal: (content: ModalProps) => void;
    hideModal: () => void;
  }
}

export const useSession = (): SessionHookResult => {

  const dispatch = useDispatch()
  const { logoutUser } = useRequestHandler()
  const { currentSession } = useSelector((state: RootState) => state.session)
  const [loading, setLoading] = useState<boolean>(false); // Specify the type for loading
  const [modalVisible, setModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState<ModalProps>(initialModal)

  const handleModal = async (content: ModalProps) => {
    await setModalContent(content)
    setModalVisible(true)
  }

  const handleHideModal = () => {
    setModalVisible(false)
  }

  const getProject = (projectId: string) => {
    dispatch(setStepsLoading(true))
    dispatch(setChannelsLoading(true))
    apiClient.getProject2(projectId, currentSession.accessToken).then( // activeUserGuid as a parameter ?
      (project: IApiProjectDetailsResponse) => {
        const { data, error } = project
        if (!error && data) {
          dispatch(setChannels(data?.Exports.map((channel: IApiExports) => { return { id: channel.ExportGuid, name: channel.Title, type: channel.TemplateName ? `Template: ${channel.TemplateName}` : `Custom: ${channel.Type} - ${channel.SenderType}`, active: channel.Active, totalItems: channel.RowsExported, exportType: channel.TemplateName ? 'SetupTemplateGenerator' : 'SetupGenerator' } })))
          dispatch(setSteps(data?.ImportSteps.map((step: IApiImportSteps) => { return { id: step.ImportStepGuid, name: step.Title, sourceType: step.ParserType, stepNumber: step.StepNumber, merge: step.InsertMethod, importTypeOptionId: step.ImportTypeOptionId } })))
        } else {
          dispatch(setStepsLoading(false))
          dispatch(setChannelsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  return { data: { ...currentSession }, loading, getProject, modal: { modalVisible, modalContent, confirmModal: handleModal, hideModal: handleHideModal } };
};