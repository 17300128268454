import { Navigate, Outlet } from "react-router";
import { urls } from "./routes/Routes";
import { useSessionContext } from "./contexts/SessionContext";
import { useLocation } from "react-router-dom";

interface PrivateRouteProps {
  element?: any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { session } = useSessionContext()
  const location = useLocation()

  return session.data.accessToken ? (
    element || <Outlet />
  ) : (
    <Navigate to={urls.login()} state={{ from: location }} replace />
  );
};
