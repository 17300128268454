import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ProjectTableViewContainer } from './ProjectTableViewStyles'

export default function ProjectTableView() {
  return (
    <ProjectTableViewContainer>
      <IframeConnect />
    </ProjectTableViewContainer>
  )
}
