import styled, { css } from 'styled-components';
import { Link, Span } from '@/components/text/Text';
import { BaseIcon } from '@/components/icons/Icons';

const stepSharedStyles = css`
  color: var(--secondary);
  background-color: var(--secondary-lighter);
  border: 1px solid var(--secondary);
`;

const stepAdminSharedStyles = css`
  color: var(--accent-02);
  background-color: var(--accent-02-light);
  border: 1px solid var(--accent-02);
`;


interface ItemProps {
  onClick?: any;
  disabled?: boolean;
}
export const Item = styled(Link) <ItemProps>`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;
  left: 0;
  padding: 0.25rem 0.833vw;
  text-align: center;
  background-color: var(--white);
  border-radius: 0.625rem;
  min-width: 10vw;
  max-width: 15vw;

  ${props => !props.disabled && css`

&:hover {
  background-color: var(--secondary-lighter);
}
` }

${props => props.disabled && css`
color: var(--grey);
cursor: not-allowed;
` }
`;

export const SubMenuContainer = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  z-index: 1;
`;

export const SubContainerLeft = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`;


export const StepText = styled(Span) <{ $stepper?: boolean }>`
  vertical-align: middle;

  ${(props) =>
    props.$stepper &&
    css`
      padding-left: 0.417vw;
    `}
`;

export const Step = styled(Link) <{
  $completed?: boolean;
  $selected?: boolean;
  $disabled?: boolean;
  $isAdmin?: boolean;
  $isToggle?: boolean;
}>`
  display: flex;
  cursor: pointer;
  padding: 0.25rem 0.833vw;
  border: 1px solid var(--grey);
  border-radius: 0.625rem;

  ${StepText}, ${BaseIcon} {
    color: var(--main);
  }

  ${(props) =>
    props.$completed &&
    css`
      ${BaseIcon} {
        color: var(--secondary);
      }
    `}

  ${(props) =>
    props.$selected && !props.$isAdmin &&
    css`
      color: var(--secondary);
      background-color: var(--secondary-lighter);
      border: 1px solid var(--secondary);
      ${StepText}, ${BaseIcon} {
        color: var(--secondary);
      }
    `}

    ${(props) =>
    props.$selected && props.$isAdmin &&
    css`
      color: var(--accent-02);
      background-color: var(--accent-02-light);
      border: 1px solid var(--accent-02);
      ${StepText}, ${BaseIcon} {
        color: var(--accent-02);
      }
    `}



  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      ${StepText}, ${BaseIcon} {
        color: var(--grey);
      }
    `}

  ${(props) =>
    !props.$disabled && !props.$isAdmin &&
    css`
      &:hover {
        color: var(--secondary);

        ${StepText}, ${BaseIcon} {
          color: var(--secondary);
        }

        ${stepSharedStyles}
      }
    `}

    ${(props) =>
    !props.$disabled && props.$isAdmin &&
    css`
      &:hover {
        color: var(--accent-02);

        ${StepText}, ${BaseIcon} {
          color: var(--accent-02);
        }

        ${stepAdminSharedStyles}
      }
    `}

    ${(props) =>
    props.$isToggle &&
    css`
      justify-content: space-between;
      min-width: 10vw;
      max-width: 15vw;

      &:hover {
        border-radius: 0.625rem 0.625rem 0 0;
        background-color: var(--secondary-lighter);
        border: 1px solid var(--grey);
      }
  `
  }
`;

export const SubContainerSteps = styled.div`
  display: flex;
  margin-left: 1rem;

  &:hover  ${Item} {
    justify-content: flex-start;
  }
`;

export const SubContainerStepsDrop = styled.div`
  display: flex;
  margin-left: 1rem;

  &:hover  ${Step} {
    border-radius: 0.625rem 0.625rem 0 0;
    background-color: var(--secondary-lighter);
    border: 1px solid var(--grey);
  }

  &:hover  ${Item} {
    justify-content: flex-start;
  }
`;

interface DropdownListProps {
  $open?: boolean;
}

export const DropdownList = styled.ul<DropdownListProps>`
  position: absolute;
  margin: 0;
  list-style: none;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Adjust transition timing */
  top: calc(100% - 0.75rem);
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid var(--grey);
  border-radius: 0 0 0.625rem 0.625rem;
  background-color: var(--white);

  ${props =>
    props.$open &&
    css`
      opacity: 1;
      visibility: visible;
      max-height: 50vh;
    `
  }
`;

export const SubContainerRight = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  position: fixed;
  right: 0;

  ${DropdownList} {
    margin-top: 0.75rem;
  }
`;

export const SubContainerElement = styled.div<{ $isToggle?: boolean }>`
  display: flex;
  &:hover {
      ${DropdownList} {
        opacity: 1;
        visibility: visible;
        max-height: 50vh;
      }
    }

    ${(props) =>
    props.$isToggle &&
    css`
      justify-content: space-between;
      min-width: 10vw;
      max-width: 15vw;

      &:hover ${Step} {
        border-radius: 0.625rem 0.625rem 0 0;
        background-color: var(--secondary-lighter);
        border: 1px solid var(--grey);
      }
  `
  }
`;


export const ChevronContainer = styled.div`
  padding-left: 0.625vw;
`;

export const ArrowContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0 0.625vw;
`;

export const Divider = styled.div`
  border-left: 1px solid transparent;
  margin: 0 0.625vw;
`;

export const Overview = styled(Link) <{ $selected?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border: 1px solid var(--grey);
  border-radius: 0.625rem;
  min-width: 8vw;

  ${(props) =>
    props.$selected &&
    css`
      color: var(--secondary);
      background-color: var(--secondary-lighter);
      border: 1px solid var(--secondary);
      ${StepText}, ${BaseIcon} {
        color: var(--secondary);
      }
    `}

  &:hover {
    ${stepSharedStyles}
    ${StepText}, ${BaseIcon} {
      color: var(--secondary);
    }
  }
`;

export const IconContainer = styled.div`
  margin-right: 0.25rem;
  display: flex;
  align-items: center;
  flex: 0.5;
`;


