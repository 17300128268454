import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routes, urls } from '@/routes/Routes';
import { setCurrentChannel, setCurrentStep } from '@/store/coreSlice';
import { RootState } from '@/store/store';
import { IChannel, IStep } from '@/interfaces/types';
import { tabTypes } from '@/constants/const';
import TabList from './TabList';
import Tab from './Tab';
import { useSessionContext } from '@/contexts/SessionContext';
import { ChannelsIcon, ChevronLeftIcon, ImportStepsIcon, ManageDataIcon } from '@/components/icons/Icons';


interface TabSelectionProps {
}

const TabSelection: React.FC<TabSelectionProps> = () => {
  const dispatch = useDispatch()
  const { session } = useSessionContext()
  const { stepsList } = useSelector((state: RootState) => state.steps)
  const { channelsList } = useSelector((state: RootState) => state.channels)
  const { currentProject, currentStep, currentChannel, currentTab } = useSelector((state: RootState) => state.core)
  const projectTabs = ['manage', 'steps', 'channels']
  const isAdmin = session.data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));
  const isPartner = session.data.roles.find((role) => role?.toLowerCase().includes('partner'))


  const selectStep = async (step: IStep) => {
    dispatch(setCurrentStep(step))
  }

  const selectChannel = async (channel: IChannel) => {
    dispatch(setCurrentChannel(channel))
  }

  const handleHeaderClick = (type: string) => {
    if (type === tabTypes.STEPS) {
      currentStep && dispatch(setCurrentStep(null))
    } else {
      currentChannel && dispatch(setCurrentChannel(null))
    }
  }

  const ProjectTabs = () => {
    return (
      <>
        <TabList
          text={tabTypes.STEPS_TITLE}
          icon={<div style={{ backgroundColor: 'var(--import-light)', borderRadius: '2rem', width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ><ChannelsIcon $horizontalFlip={true} color={'var(--import)'} /></div>}
          type={tabTypes.STEPS}
          link={urls.stepList()}
          //hasValue={!!currentStep}
          hasValue={true}
          items={stepsList.data}
          setSelected={selectStep}
          isLink={true}
          getUrl={(item: any) => urls.step_source({ projectId: currentProject?.id ?? '', stepId: item.id, stepNumber: item.stepNumber })}
          headerActions={[{ type: 'list', link: urls.stepList(), action: () => handleHeaderClick(tabTypes.STEPS), disabled: false }, { type: 'create', link: urls.step_new_source({ projectId: currentProject?.id ?? '' }), action: () => null, disabled: false, }]}
          isLoading={stepsList.loading}
        />
        <Tab
          text={tabTypes.MANAGE_TITLE}
          icon={<div style={{ backgroundColor: 'var(--secondary)', borderRadius: '2rem', width: '1.5rem', height: '1.5rem' }} ><ManageDataIcon color={'var(--white)'} /></div>}
          type={tabTypes.MANAGE}
          link={urls.project({ projectId: currentProject?.id ?? '' })}
        />
        <TabList
          text={tabTypes.CHANNELS_TITLE}
          icon={<div style={{ backgroundColor: 'var(--export-light)', borderRadius: '2rem', width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ><ChannelsIcon color={'var(--export)'} /></div>}
          type={tabTypes.CHANNELS}
          link={urls.channelList()}
          //hasValue={!!currentChannel}
          hasValue={true}
          items={channelsList.data}
          setSelected={selectChannel}
          isLink={true}
          getUrl={(item: any) => urls.channel({ channelId: item.id })}
          headerActions={[{ type: 'list', link: urls.channelList(), action: () => handleHeaderClick(tabTypes.CHANNELS), disabled: false }, { type: 'create', link: urls.channel_new({ projectId: currentProject?.id ?? '', firstChannel: currentProject?.channels?.length ? true : null }), action: () => null, disabled: false, }]}
          isLoading={channelsList.loading}
        />
      </>
    )
  }

  const AccountTabs = () => {
    return (
      <>
        <Tab
          text={tabTypes.HOME_TITLE}
          type={tabTypes.HOME}
          link={routes.home()}
        />
        <Tab
          text={tabTypes.USERS_TITLE}
          type={tabTypes.USERS}
          link={isAdmin ? routes.client_management() : routes.users()}
        />
        {(isAdmin || isPartner) && <Tab
          text={tabTypes.HELPERS_TITLE}
          type={tabTypes.HELPERS}
          link={routes.helpers()}
        />}
        <Tab
          text={tabTypes.SUBSCRIPTIONS_TITLE}
          type={tabTypes.SUBSCRIPTIONS}
          link={routes.subscriptions()}
        />
        <Tab
          text={tabTypes.SETTINGS_TITLE}
          type={tabTypes.SETTINGS}
          link={urls.settings({ clientId: session.data.userGuid })}
        />
        {isAdmin && <Tab
          text={tabTypes.ADMIN_TITLE}
          type={tabTypes.ADMIN}
          link={routes.template_management_list()}
          adminTag={true}
        />}
      </>
    )
  }

  return (
    <>
      {projectTabs.includes(currentTab) ? <ProjectTabs /> : <AccountTabs />}
    </>
  );
};

export default TabSelection;