import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { SubscriptionsContainer } from './SubscriptionsStyles'

export default function Subscriptions() {

  return (
    <SubscriptionsContainer>
      <IframeConnect />
    </SubscriptionsContainer>
  )
}
