
import { PointerEvent, ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { routes, urls } from '@/routes/Routes'
import { RootState } from '@/store/store'
import { setCurrentStep } from '@/store/coreSlice'
import { IStep } from '@/interfaces/types'
import { ActionButton, ActionsContainer, ChevronContainer, Divider, DropdownList, HeaderContainer, InfoContainer, InfoItem, InfoItemEmtpy, Item, ListContainer, ListItemContainer, MoreOptions, MoreOptionsContainer, MoreOptionsElement, MoreOptionsText, OverviewContainer, StepItem, StepItemContainer, StepItemLink, Tag, Tags, Title } from './StepOverviewStyles'
import { Button } from '@/components/button/Button'
import { AddIcon, ChannelsIcon, ChevronDownIcon, EditIcon, ReorderIcon, WarningIcon } from '@/components/icons/Icons'
import { useNavigate } from 'react-router'
import { Label, Link, Span } from '@/components/text/Text'
import { useEffect } from 'react'
import { ModalProps } from '@/components/modal/Modal'
import { apiClient } from '@/api/extendedAPI'
import { useSessionContext } from '@/contexts/SessionContext'
import { useRequestHandler } from '@/hooks/useRequestHandler'
import StepMoreOptions from '../stepMoreOptions/StepMoreOptions'
import ModalLayout from '@/components/modal/ModalLayout'
import StepReorder from './StepReorder'
import WUDTooltip from '@/components/tooltip/Tooltip'
import { setStepsLoading } from '@/store/stepsSlice'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'


interface IStepOverview {
  handleModal: (content: ModalProps) => void;
  handleHideModal: () => void;
  moreOptions: Array<{
    name: string;
    toggleList: Array<{
      name: string;
      type: string;
      component?: ReactNode;
      parameters?: Object;
      url?: Function;
      hidden?: boolean;
      isHidden: (step: IStep) => boolean;
    }>;
    hidden?: boolean;
  }>;
}


export default function StepOverview({ handleModal, handleHideModal, moreOptions }: IStepOverview) {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { logoutUser } = useRequestHandler()
  const { session } = useSessionContext()
  const { stepsList } = useSelector((state: RootState) => state.steps)
  const { currentProject, currentStep } = useSelector((state: RootState) => state.core)
  const [reorderMode, setReorderMode] = useState(false)
  const [temporaryDetails, setTemporaryDetails] = useState<Array<{ [key: string]: any }>>([])
  const [isLoading, setIsLoading] = useState(false)

  const selectStep = (step: IStep) => {
    dispatch(setCurrentStep(step))
  }

  const getStepDetails = (importTypeOptionId: number) => {
    apiClient.getStepDetails(importTypeOptionId, session.data.accessToken).then(
      (stepDetails: { data: { [key: string]: any }, error: any }) => {
        const { data, error } = stepDetails
        if (!error && data) {
          if (!temporaryDetails.includes(data))
            setTemporaryDetails(prevState => [...prevState, data])
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  const renameStep = (title: string, projectId: string, stepId: string) => {
    apiClient.renameStep(title, projectId, stepId, session.data.accessToken).then(
      (renameStep: { data: string, error: any }) => {
        const { data, error } = renameStep
        if (!error && data) {
          session.getProject(projectId)
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  const getMergeMethod = (insertMethod: number) => {
    switch (insertMethod) {
      case 1:
        return 'Insert data rows'
      case 2:
        return 'Insert or merge data rows'
      case 3:
        return 'Insert or replace data row'
      case 4:
        return 'Merge data'
      case 5:
        return 'Replace data'
      case 6:
        return 'Insert or delete data rows'
      case 7:
        return 'Delete rows'
      case 8:
        return 'Insert row, if new'
      default:
        return <Label fontWeight='500' fontSize={0.75} $lineHeight={1.5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }} >No merge criteria defined <WUDTooltip id={`merge-criteria-not${insertMethod}`} content='You have not defined a merge criteria for this step' ><WarningIcon $size={0.75} color={'var(--warning)'} /></WUDTooltip></Label>
    }
  }

  const getTypeName = (stepDetail: { [key: string]: any; } | undefined): string => {
    return stepDetail?.Field1 ? stepDetail?.Field1?.toLowerCase() : stepDetail?.DisplayType?.toLowerCase()
  }

  const handleSelectOption = (event: React.PointerEvent<HTMLAnchorElement>, item: any, step: IStep, disabled = false) => {
    if (item.type === 'modal') {
      event.stopPropagation()
      event.preventDefault()
      !disabled && item.onClick(step)
    } else {
      selectStep(step)
    }
  }

  useEffect(() => {
    if (!currentProject) navigate(routes.home())
  }, [])

  useEffect(() => {
    getExtraData()
  }, [])

  const getExtraData = () => {
    stepsList?.data.map((step) => {
      if (!temporaryDetails.find((detail) => detail.ImportTypeOptionId === step.importTypeOptionId))
        getStepDetails(step.importTypeOptionId)
    })
  }

  return (
    /*  currentStep ?
       <StepMoreOptions /> : */
    <OverviewContainer>
      <HeaderContainer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
          <Label fontSize={0.75} $lineHeight={0.75} style={{ backgroundColor: 'var(--import)', color: 'white', padding: '0.25rem', borderRadius: '0.25rem', width: '7rem', textAlign: 'center' }} >IMPORT STEPS</Label>
          <Title fontSize={1.25}>
            {currentProject?.name}
          </Title>
        </div>
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }} >
          {stepsList.data.length > 0 && <div>
            <Button $buttonType='secondary' $buttonSize='small' icon={<ReorderIcon />} text='Reorder steps' onClick={() => setReorderMode(true)} />
          </div>}
          <div>
            <Button $buttonType='creation' $buttonSize='medium' icon={<AddIcon />} text='Create a step' onClick={() => navigate(urls.step_new_source({ projectId: currentProject?.id ?? '' }))} />
          </div>
        </div>
      </HeaderContainer>
      <ListContainer>
        {
          stepsList?.data.map((step: IStep, index: number) => {
            const stepDetail = temporaryDetails.find((detail) => detail.ImportTypeOptionId === step.importTypeOptionId)
            return (
              <StepItemContainer key={index}>
                <StepItemLink to={urls.step_source({ projectId: currentProject?.id ?? '', stepId: step.id, stepNumber: step?.stepNumber?.toString() ?? '0' })}>
                  <Span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} >
                    <div style={{ backgroundColor: 'var(--import-light)', borderRadius: '2rem', width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                      <ChannelsIcon $horizontalFlip={true} color={'var(--import)'} /></div>{step.name}
                    {stepDetail ? <Span fontSize={0.75} fontWeight='500' $lineHeight={1.5} fontStyle='italic'>({`${step.sourceType && (!step.sourceType?.includes('Crawl') && !step.sourceType?.includes('PageScanner')) ? step.sourceType.replaceAll('Parser', ' ').toLowerCase() : ''}${getTypeName(stepDetail)}`})
                    </Span> :
                      <div style={{ width: '55px' }}>
                        <SkeletonTheme baseColor={'var(--grey-light)'} highlightColor={'var(--import-light)'}>
                          <Skeleton height={'1rem'} count={1} style={{ margin: '0' }} />
                        </SkeletonTheme>
                      </div>}
                  </Span>
                  <InfoContainer>
                    {stepDetail ? <img src={stepDetail && stepDetail?.FrontImage ? stepDetail?.FrontImage : ''} alt={`${step.sourceType} icon`} /> :
                      <div style={{ width: '55px' }}>
                        <SkeletonTheme baseColor={'var(--grey-light)'} highlightColor={'var(--import-light)'}>
                          <Skeleton height={'55px'} count={1} style={{ margin: '0' }} />
                        </SkeletonTheme>
                      </div>}
                    {(step?.stepNumber !== 1 && !['CrawlerSimple', 'PageScanner'].includes(step?.sourceType ?? '')) ?
                      <InfoContainer>
                        <InfoItem fontWeight='500' fontSize={0.75} $lineHeight={1.5}>
                          MERGE CRITERIA
                          <Divider />
                          {getMergeMethod(step?.merge ?? 0)}
                        </InfoItem>
                      </InfoContainer> : <InfoContainer><InfoItemEmtpy></InfoItemEmtpy></InfoContainer>}
                  </InfoContainer>

                </StepItemLink>
                <ActionsContainer>
                  <ActionButton onClick={(event: PointerEvent<HTMLDivElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleModal({
                      title: `Rename import step "${step.name}"`,
                      description: '',
                      confirmAction: (value?: string) => renameStep(value ?? '', currentProject?.id ?? '', step.id),
                      cancelAction: () => handleHideModal(),
                      placeholder: 'Insert a name for your step'
                    })
                  }} >
                    Rename
                    <EditIcon $size={1} cursor={'pointer'} />
                  </ActionButton>
                  <MoreOptionsContainer >
                    {moreOptions?.map((tab, index) => (
                      !tab?.hidden && tab.toggleList &&
                      (
                        <ListItemContainer key={index}>
                          <MoreOptionsElement onClick={(event: PointerEvent<HTMLDivElement>) => { event.preventDefault(); event.stopPropagation();; }}>
                            <MoreOptions onClick={(event: PointerEvent<HTMLDivElement>) => event.preventDefault()}>
                              <MoreOptionsText
                                $lineHeight={1.5}
                                $stepper={false}
                              >
                                {tab.name}
                              </MoreOptionsText>
                              {tab.toggleList && <ChevronContainer><ChevronDownIcon /></ChevronContainer>}
                            </MoreOptions>

                            {tab.toggleList &&
                              <DropdownList >
                                {tab.toggleList.map((item: any, index: number) => (
                                  (!item.isHidden(step)) &&
                                  <WUDTooltip id={`moreOptions-item-disabled-${step.id}`} content={item?.soonAvailable ? 'Soon available' : ''} place='left' >
                                    <Item disabled={item?.soonAvailable} to={item?.getUrl ? item.getUrl(step) : item?.itemParameters ? item.url(item.itemParameters(step)) : item.url()} key={index} onClick={(event: React.PointerEvent<HTMLAnchorElement>) => handleSelectOption(event, item, step, item?.soonAvailable)}>
                                      {item.name}
                                    </Item>
                                  </WUDTooltip>
                                ))}
                              </DropdownList>
                            }
                          </MoreOptionsElement>
                        </ListItemContainer>
                      )
                    ))}
                  </MoreOptionsContainer>
                </ActionsContainer>
              </StepItemContainer>
            )
          }
          )}
      </ListContainer>
      {reorderMode && <ModalLayout onClick={() => setReorderMode(false)} >
        <StepReorder items={stepsList.data} handleClose={() => setReorderMode(false)} />
      </ModalLayout>}
    </OverviewContainer>
  )
}