import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import Board from '@/components/board/Board'
import { HelpersContainer } from './HelpersStyles'

export default function Helpers() {

  return (
    <Board>
      <HelpersContainer>
        <IframeConnect />
      </HelpersContainer>
    </Board>
  )
}
