import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { routes, urls } from "@/routes/Routes";
import { ModalProps } from "@/components/modal/Modal";
import ChannelOverview from "@/containers/channels/channelOverview/ChannelOverview";
import ChannelTableView from "@/containers/channels/channelTableView/ChannelTableView";
import ChannelMappings from "@/containers/channels/channelMappings/ChannelMappings";
import ChannelFiltering from "@/containers/channels/channelFiltering/ChannelFiltering";
import { useLocation } from "react-router";
import ChannelSource from "@/containers/channels/channelSource/ChannelSource";
import { useQuery } from "./useQuery";
import ChannelMoreOptions from "@/containers/channels/channelMoreOptions/ChannelMoreOptions";
import { IChannel } from "@/interfaces/types";
import { apiClient } from "@/api/extendedAPI";
import { useSessionContext } from "@/contexts/SessionContext";
import { useRequestHandler } from "./useRequestHandler";
import ChannelReview from "@/containers/channels/channelReview/ChannelReview";


const initialModal = {
  title: '',
  description: '',
  confirmAction: () => null,
  cancelAction: () => null
}

export interface TabParameters {
  [key: string]: any;
}

export enum ToggleChannelReviewEnum {
  TableView,
  SingleView
}

export enum ToggleChannelMoreOptionsEnum {
  CompareChannel,
  Clone,
  Settings,
}

export function useChannelContent(toggleSelected: ToggleChannelReviewEnum, toggleMoreOptions: ToggleChannelMoreOptionsEnum): [Array<TabParameters>, Array<TabParameters>, { modalVisible: boolean, modalContent: ModalProps }] {


  const location = useLocation()
  const { firstChannel, exportGuid } = useQuery()
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const { currentProject, currentStep, currentChannel } = useSelector((state: RootState) => state.core)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState<ModalProps>(initialModal)

  const handleModal = async (content: ModalProps) => {
    await setModalContent(content)
    setModalVisible(true)
  }

  const handleHideModal = () => {
    setModalVisible(false)
  }

  const megaTabs = [
    {
      name: 'More options',
      toggleList: [
        {
          name: 'Compare channel',
          type: 'page',
          component: <ChannelMoreOptions />,
          parameters: { channelId: currentChannel?.id },
          url: urls.channel_compare,
          hidden: false,
          isHidden: (channel: IChannel) => false,
          itemParameters: (channel: IChannel) => { return ({ channelId: currentChannel?.id }) },
        },
        {
          name: 'Clone channel',
          type: 'page',
          component: <ChannelMoreOptions />,
          parameters: { channelId: currentChannel?.id },
          url: urls.channel_clone,
          hidden: false,
          isHidden: (channel: IChannel) => false,
          itemParameters: (channel: IChannel) => { return ({ channelId: currentChannel?.id }) },
        },
        {
          name: 'Settings',
          type: 'page',
          component: <ChannelMoreOptions />,
          parameters: { channelId: currentChannel?.id },
          url: urls.channel_settings,
          hidden: false,
          isHidden: (channel: IChannel) => false,
          itemParameters: (channel: IChannel) => { return ({ channelId: currentChannel?.id }) },
        },
        {
          name: `${currentChannel?.active ? 'Disable' : 'Enable'} channel`,
          type: 'modal',
          component: <ChannelMoreOptions />,
          parameters: {},
          url: () => '',
          onClick: (channel?: IChannel) => handleModal({
            title: channel?.active || currentChannel?.active ? 'Disable channel' : 'Enable channel',
            description: '',
            placeholder: 'Write the name of the channel you want to disable',
            hasClipboard: false,
            initialInputValue: '',
            confirmAction: (confirmMessage?: string) => channel?.active || currentChannel?.active ? disableChannel(channel, confirmMessage) : enableChannel(channel, confirmMessage),
            cancelAction: () => handleHideModal()
          }),
          hidden: false,
          isHidden: (channel: IChannel) => false,
          itemParameters: (channel: IChannel) => { return ({ channelId: currentChannel?.id }) },
          getName: (channel: IChannel) => channel.active ? 'Disable channel' : 'Enable channel',
        },
        {
          name: 'Delete channel',
          type: 'modal',
          component: <ChannelMoreOptions />,
          parameters: {},
          url: () => '',
          onClick: (channel?: IChannel) => handleModal({
            title: 'Delete channel',
            description: '',
            placeholder: 'Write the name of the channel you want to delete',
            hasClipboard: false,
            initialInputValue: '',
            confirmAction: (confirmMessage?: string) => deleteChannel(channel, confirmMessage),
            cancelAction: () => handleHideModal()
          }),
          hidden: false,
          isHidden: (channel: IChannel) => false,
          itemParameters: (channel: IChannel) => { return ({ channelId: currentChannel?.id }) },
        },
      ],
      toggleSelected: toggleMoreOptions ?? 0,
    },
  ]

  const subTabs = [
    {
      name: 'Overview',
      component: <ChannelOverview handleModal={handleModal} handleHideModal={handleHideModal} moreOptions={megaTabs} />,
      parameters: { channelId: currentChannel?.id },
      url: urls.channel
    },
    {
      name: 'Review data',
      component: <ChannelTableView />,
      parameters: { channelId: currentChannel?.id },
      url: urls.channel_table,
      toggleList: [
        {
          name: 'Table items view',
          component: <ChannelTableView />,
          parameters: { channelId: currentChannel?.id },
          url: urls.channel_table,
        },
        {
          name: 'Single item view',
          component: <ChannelTableView />,
          parameters: { channelId: currentChannel?.id },
          url: urls.channel_single,
        }
      ],
      toggleSelected: toggleSelected ?? 0,
    },
    {
      name: 'Mappings',
      component: <ChannelMappings />,
      parameters: { exportType: currentChannel?.exportType, channelId: currentChannel?.id },
      url: urls.channel_mappings
    },
    {
      name: 'Filtering',
      component: <ChannelFiltering />,
      parameters: { channelId: currentChannel?.id },
      url: urls.channel_filtering
    },
    {
      name: 'Channel review',
      component: <ChannelReview />,
      parameters: { channelId: currentChannel?.id },
      url: urls.channel_review,
      hidden: currentChannel ? !currentChannel?.type?.includes('Google') : false
    },
    {
      name: 'More Options',
      component: <ChannelMoreOptions />,
      parameters: {},
      url: () => '',
      hidden: true
    },
  ]

  const subTabsNew = [
    {
      name: 'Home',
      component: <ChannelOverview handleModal={handleModal} handleHideModal={handleHideModal} moreOptions={megaTabs} />,
      parameters: { channelId: currentChannel?.id },
      url: urls.channel
    },
    {
      name: 'Source',
      component: <ChannelSource />,
      parameters: { projectId: currentProject?.id, firstChannel: currentProject?.channels?.length ? true : null },
      url: urls.channel_new
    },
    {
      name: 'Mappings',
      component: <ChannelMappings />,
      parameters: { exportType: currentChannel?.exportType, channelId: currentChannel?.id },
      url: urls.channel_mappings
    },
    {
      name: 'More Options',
      component: <ChannelMoreOptions />,
      parameters: {},
      url: () => '',
      hidden: true
    },
  ]

  const logChannel = (channel: IChannel, area = "Channel deleted", message = `Channel has been deleted`) => {
    apiClient.logProjectModification(channel.id ?? currentChannel?.id ?? exportGuid() ?? '', null, null, area,
      message, session.data.accessToken).then(
        (log: { data: {}, error: any }) => {
          const { data, error } = log
          if (error === null) {
            // Here I will have to update the list by calling the project again
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching logChannel:', error)

      })
  }

  const deleteChannel = (channel?: IChannel, confirmMessage = '') => {
    if ((channel && confirmMessage === channel?.name) || (!channel && confirmMessage === currentChannel?.name)) {
      apiClient.deleteChannel(channel?.id ?? channel?.id ?? exportGuid() ?? '', session.data.accessToken).then(
        (channelDeleted: { data: boolean, error: any }) => {
          const { data, error } = channelDeleted
          if (error === null) {
            if (channel) { logChannel(channel, "Channel deleted", "Channel have been deleted") } else if (currentChannel) { logChannel(currentChannel, "Channel deleted", "Channel have been deleted") }
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching deleteChannel:', error)

      })
    }
  }

  const disableChannel = (channel?: IChannel, confirmMessage = '') => {
    if ((channel && confirmMessage === channel?.name) || (!channel && confirmMessage === currentChannel?.name)) {
      apiClient.disableChannel(channel?.id ?? currentChannel?.id ?? exportGuid() ?? '', session.data.accessToken).then(
        (channelDisabled: { data: boolean, error: any }) => {
          const { data, error } = channelDisabled
          if (error === null) {
            if (channel) { logChannel(channel, "Channel disabled", `Channel has been disabled`) } else if (currentChannel) { logChannel(currentChannel, "Channel disabled", `Channel has been disabled`) }
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching disableChannel:', error)

      })
    }
  }

  const enableChannel = (channel?: IChannel, confirmMessage = '') => {
    if ((channel && confirmMessage === channel?.name) || (!channel && confirmMessage === currentChannel?.name)) {
      apiClient.enableChannel(channel?.id ?? currentChannel?.id ?? exportGuid() ?? '', session.data.accessToken).then(
        (channelDisabled: { data: boolean, error: any }) => {
          const { data, error } = channelDisabled
          if (error === null) {
            if (channel) { logChannel(channel, "Channel enabled", `Channel has been enabled`) } else if (currentChannel) { logChannel(currentChannel, "Channel enabled", `Channel has been enabled`) }
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching enableChannel:', error)
      })
    }
  }

  return [location.pathname.includes(routes.channel_new()) || firstChannel() ? subTabsNew : subTabs, megaTabs, { modalVisible, modalContent }];
}
