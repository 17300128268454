import { useEffect, PointerEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from '@/store/store'
import { setCurrentChannel, setCurrentProject, setCurrentStep, setCurrentTab } from '@/store/coreSlice'
import { routes, urls } from '@/routes/Routes'
import { ActionButton, ActionsContainer, CarouselContainer, Divider, HeaderContainer, HomeContainer, InfoContainer, InfoItem, ListContainer, ProjectItem, ProjectItemLink, Tag, Tags, Title, TooltipContainer } from './HomeStyles'
import { useSessionContext } from '@/contexts/SessionContext'
import { IProject } from '@/interfaces/types'
import { setSteps, setStepsLoading } from '@/store/stepsSlice'
import { setChannels, setChannelsLoading } from '@/store/channelsSlice'
import { IApiProjectDetailsResponse, apiClient } from '@/api/extendedAPI'
import { IApiExports, IApiImportSteps } from '@/api/endpointsRoutes'
import { useRequestHandler } from '@/hooks/useRequestHandler'
import { Button } from '@/components/button/Button'
import { AddIcon, EditIcon, ManageDataIcon, WarningIcon } from '@/components/icons/Icons'
import { Label, Span } from '@/components/text/Text'
import { ModalProps } from '@/components/modal/Modal'
import WUDTooltip from '@/components/tooltip/Tooltip'
import { useTranslation } from 'react-i18next'


interface IHome {
  handleModal: (content: ModalProps) => void;
  handleHideModal: () => void;
}


export default function Home({ handleModal, handleHideModal }: IHome) {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { logoutUser } = useRequestHandler()
  const { session } = useSessionContext()
  const { errorsList } = useSelector((state: RootState) => state.users)
  const { currentProject, currentClient, currentPartner } = useSelector((state: RootState) => state.core)
  const projectsList = useSelector((state: RootState) => state.projects.projectsList)
  const adminProjectsList = useSelector((state: RootState) => state.manager.projectsList)
  const isAdmin = session.data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));
  const isPartner = session.data.roles.find((role) => role?.toLowerCase().includes('partner'))

  const selectProject = async (project: IProject) => {
    if (session.data.accessToken) {
      getProject(project.id) // I need to get the project so I can see the information of the steps and channels in the tabs
      dispatch(setCurrentProject(project))
      dispatch(setCurrentStep(null))
      dispatch(setCurrentChannel(null))
      navigate(urls.project({ projectId: project.id }))
    }
  }

  const getProject = (projectId: string) => {
    dispatch(setStepsLoading(true))
    dispatch(setChannelsLoading(true))
    apiClient.getProject2(projectId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (project: IApiProjectDetailsResponse) => {
        const { data, error } = project
        if (!error && data) {
          dispatch(setChannels(data?.Exports.map((channel: IApiExports) => { return { id: channel.ExportGuid, name: channel.Title, type: channel.TemplateName ? `Template: ${channel.TemplateName}` : `Custom: ${channel.Type} - ${channel.SenderType}`, active: channel.Active, totalItems: channel.RowsExported, exportType: channel.TemplateName ? 'SetupTemplateGenerator' : 'SetupGenerator' } })))
          dispatch(setSteps(data?.ImportSteps.map((step: IApiImportSteps) => { return { id: step.ImportStepGuid, name: step.Title, sourceType: step.ParserType, stepNumber: step.StepNumber, merge: step.InsertMethod, importTypeOptionId: step.ImportTypeOptionId } })))
        } else {
          dispatch(setStepsLoading(false))
          dispatch(setChannelsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  const renameProject = (title: string, projectId: string) => {
    apiClient.renameProject(title, projectId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (renameStep: { data: string, error: any }) => {
        const { data, error } = renameStep
        if (!error && data) {
          window.location.reload();
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  const alertIcon = (item: IProject) => {
    const projectWithIssues = errorsList.data.find((error) => item.id === error)
    if (projectWithIssues) {
      return (
        <TooltipContainer>
          <WUDTooltip id={`issue-tooltip-project`}
            place='right'
            customContent={<Label fontWeight='500' fontSize={0.75} style={{ textAlign: 'start' }}>
              {t('common.accordion.warning.issue', { count: 1, type: 'project' })}
            </Label>}
          >
            <WarningIcon
              $size={1}
              color={'var(--warning)'}
              style={{ paddingBottom: '0.2rem' }}
            />
          </WUDTooltip>
        </TooltipContainer>

      );
    }
    return null;
  };

  useEffect(() => {
    dispatch(setCurrentTab('home'))
    if (location.pathname.includes('setactiveuserdone')) navigate(routes.home())
  }, [location.pathname])


  return (
    <HomeContainer>
      {(!projectsList.loading && !adminProjectsList.loading) && <>
        <HeaderContainer>
          <Title fontSize={1.25}>
            {isAdmin ? currentClient?.name ?? currentPartner?.name.replaceAll('--', '') : currentClient?.name ?? 'Your account'}
          </Title>
          <Button $buttonType='creation' $buttonSize='medium' icon={<AddIcon />} text='Create a project' onClick={() => navigate(urls.project_new())} />
        </HeaderContainer>
        {isAdmin ?
          <ListContainer>
            {adminProjectsList.data.map((project, index) =>
              !project.name.includes('deleted') &&
              <ProjectItem key={index} onClick={() => selectProject(project)} >
                <ProjectItemLink to={urls.project({ projectId: project.id })}>
                  <Span style={{ display: 'flex', gap: '0.5rem' }}>
                    <div style={{ backgroundColor: 'var(--secondary)', borderRadius: '2rem', width: '1.5rem', height: '1.5rem' }} ><ManageDataIcon color={'var(--white)'} /></div> <Span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} >{project.name} {alertIcon(project)}</Span>
                    {project.name.includes('(disabled)') && <Tag fontSize={0.5} $lineHeight={0.5} $backgroundColor={'var(--error)'}>{'DISABLED'}</Tag>}
                  </Span>
                </ProjectItemLink>
                <ActionsContainer>
                  <ActionButton onClick={(event: PointerEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                    handleModal({
                      title: `Rename project "${project.name}"`,
                      description: '',
                      confirmAction: (value?: string) => renameProject(value ?? '', project.id),
                      cancelAction: () => handleHideModal(),
                      placeholder: 'Insert a name for your project'
                    })
                  }} >
                    Rename
                    <EditIcon $size={1} />
                  </ActionButton>
                </ActionsContainer>
              </ProjectItem>)}
          </ListContainer>
          :
          <ListContainer>
            {projectsList.data.map((project, index) =>
              !project.name.includes('deleted') &&
              <ProjectItem key={index} onClick={() => selectProject(project)}>
                <ProjectItemLink to={urls.project({ projectId: project.id })} >
                  <Span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} ><div style={{ backgroundColor: 'var(--secondary)', borderRadius: '2rem', width: '1.5rem', height: '1.5rem' }} ><ManageDataIcon color={'var(--white)'} /></div> {project.name} {alertIcon(project)} {!project.active && <Tag fontSize={0.5} $lineHeight={0.5} $backgroundColor={'var(--error)'}>{'DISABLED'}</Tag>}</Span>
                  <InfoContainer>
                    <InfoItem fontWeight='500' fontSize={0.75} $lineHeight={1.5}>TOTAL ITEMS <Divider /> {project.totalItems}</InfoItem>
                    <InfoItem fontWeight='500' fontSize={0.75} $lineHeight={1.5}>TOTAL CHANNELS <Divider /> {project.totalChannels}</InfoItem>
                  </InfoContainer>
                </ProjectItemLink>
                <ActionsContainer>
                  <ActionButton onClick={(event: PointerEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                    handleModal({
                      title: `Rename project "${project.name}"`,
                      description: '',
                      confirmAction: (value?: string) => renameProject(value ?? '', project.id),
                      cancelAction: () => handleHideModal(),
                      placeholder: 'Insert a name for your project'
                    })
                  }} >
                    Rename
                    <EditIcon $size={1} />
                  </ActionButton>
                </ActionsContainer>
              </ProjectItem>)}
          </ListContainer>
        } </>}
    </HomeContainer>
  )
}


// CarouselContainer for a nice grid