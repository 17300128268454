import { createSlice } from '@reduxjs/toolkit'
import { IChannelsState } from '@/interfaces/types';

const initialState: IChannelsState = {
  channelsList: { data: [], loading: false }
}

const channelsSlice = createSlice({
  name: 'channelsList',
  initialState,
  reducers: {
    setChannels: (state, action) => {
      state.channelsList = { data: action.payload, loading: false }
    },
    setChannelsLoading: (state, action) => {
      state.channelsList = { ...state.channelsList, loading: action.payload }
    }
  }
})

export const {
  setChannels,
  setChannelsLoading
} = channelsSlice.actions

export default channelsSlice.reducer