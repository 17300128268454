import styled from 'styled-components';

export const WorkInProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  height: 100vh;
`;


export const LogoContainer = styled.div`
  margin-top: 5rem;
`

export const Logo = styled.img`
  max-height: 5rem; 
`