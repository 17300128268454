import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ChannelTableViewContainer } from './ChannelTableViewStyles'

export default function ChannelTableView() {
  return (
    <ChannelTableViewContainer>
      <IframeConnect />
    </ChannelTableViewContainer>
  )
}
