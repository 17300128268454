import { createSlice } from '@reduxjs/toolkit'
import { ICoreState } from '@/interfaces/types';

const initialState: ICoreState = {
  currentPartner: null,
  currentClient: null,
  currentProject: null,
  currentStep: null,
  currentChannel: null,
  currentTab: '',
  currentSession: {
    accessToken: '',
    active: false,
    activeUserGuid: '',
    isSubaccount: false,
    roles: [],
    subaccounts: [],
    userGuid: '',
    userName: '',
    connectJwt: ''
  }
}

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.assign(state, initialState)
    },
    setCurrentPartner: (state, action) => {
      state.currentPartner = action.payload
    },
    setCurrentClient: (state, action) => {
      state.currentClient = action.payload
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setCurrentChannel: (state, action) => {
      state.currentChannel = action.payload
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload
    },
  }
})

export const {
  resetStore, setCurrentPartner, setCurrentClient, setCurrentProject, setCurrentStep, setCurrentChannel, setCurrentTab
} = coreSlice.actions

export default coreSlice.reducer