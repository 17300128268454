import { urls } from '@/routes/Routes'
import PartnerSelection from './PartnerSelection';
import { AdminContainer, InfoBubble, ModalButton, ShortCut } from './AdminBarStyles'
import WUDTooltip from '@/components/tooltip/Tooltip';
import { apiClient } from '@/api/extendedAPI';
import { useSessionContext } from '@/contexts/SessionContext';
import { useEffect, useState } from 'react';
import { Label } from '@/components/text/Text';
import { useRequestHandler } from '@/hooks/useRequestHandler';


interface AdminSelectionProps {
  openModal: (type: string) => void;
}

const AdminSelection: React.FC<AdminSelectionProps> = ({ openModal }) => {

  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const [numberImports, setNumberImports] = useState<number>(0)
  const [numberCrawlings, setNumberCrawlings] = useState<number>(0)


  const getRunningImports = () => {
    apiClient.getRunningImports(session.data.accessToken).then(
      (runningImports: { data: number, error: any }) => {
        const { data, error } = runningImports
        if (error === null) {
          setNumberImports(data)
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching getRunningImports:', error)

    })
  }

  const getRunningCrawlings = () => {
    apiClient.getRunningCrawlings(session.data.accessToken).then(
      (runningCrawlings: { data: number, error: any }) => {
        const { data, error } = runningCrawlings
        if (error === null) {
          setNumberCrawlings(data)
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching getRunningCrawlings:', error)
    })

  }

  useEffect(() => {
    getRunningImports()
    getRunningCrawlings()
    const intervalId = setInterval(() => {
      getRunningImports();
      getRunningCrawlings();
    }, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [])

  return (
    <div className='step-admin' >
      <AdminContainer>
        <WUDTooltip id={`nav-admin-csm`} content={'CSM Dashboard'} place='bottom-end'>
          <ShortCut fontSize={0.75} to={urls.dashboard_csm()}>
            CSM
          </ShortCut>
        </WUDTooltip>
        <WUDTooltip id={`nav-admin-sal`} content={'Sales Dashboard'} place='bottom-end'>
          <ShortCut fontSize={0.75} to={urls.dashboard_sales()}>
            SAL
          </ShortCut>
        </WUDTooltip>
        <WUDTooltip id={`nav-admin-fin`} content={'Find based on URL'} place='bottom'>
          <ShortCut fontSize={0.75} to={urls.find_owner_export()}>
            FIN
          </ShortCut>
        </WUDTooltip>
        <WUDTooltip id={`nav-admin-mov`} content={'Move import between Projects'} place='bottom'>
          <ShortCut fontSize={0.75} to={urls.move_import()}>
            MOV
          </ShortCut>
        </WUDTooltip>
        <WUDTooltip id={`nav-admin-cli`} content={'Manage Clients'} place='bottom'>
          <ShortCut fontSize={0.75} to={urls.client_management()}>
            CLI
          </ShortCut>
        </WUDTooltip>
        <PartnerSelection />
        <ModalButton title='Load guid' fontWeight='500' fontSize={0.75} onClick={() => openModal('loadGuid')}>
          Load guid
        </ModalButton>
        <WUDTooltip id={`imports-queue`} content={'Imports queue'} place='bottom'>
          <InfoBubble $backgroundColor={'var(--error)'}>
            <Label color='var(--white)' fontSize={0.75} >
              {numberImports < 0 ? ' - ' : numberImports}
            </Label>
          </InfoBubble>
        </WUDTooltip>
        <WUDTooltip id={`crawlings-queue`} content={'Crawlings queue'} place='bottom'>
          <InfoBubble $backgroundColor={'var(--warning-dark)'}>
            <Label color='var(--white)' fontSize={0.75} >
              {numberCrawlings < 0 ? ' - ' : numberCrawlings}
            </Label>
          </InfoBubble>
        </WUDTooltip>

        <WUDTooltip id={`nav-admin-sho`} content={'Shopify shops'} place='bottom'>
          <ShortCut fontSize={0.75} to={urls.shopify_projects()}>
            SHO
          </ShortCut>
        </WUDTooltip>
        <WUDTooltip id={`nav-admin-css`} content={'CSS Feeds'} place='bottom'>
          <ShortCut fontSize={0.75} to={urls.css_management()}>
            CSS
          </ShortCut>
        </WUDTooltip>
        <WUDTooltip id={`nav-admin-too`} content={'Tools'} place='bottom'>
          <ShortCut fontSize={0.75} to={urls.tools()}>
            TOO
          </ShortCut>
        </WUDTooltip>
        <WUDTooltip id={`nav-admin-img`} content={'Image Management'} place='bottom-end'>
          <ShortCut fontSize={0.75} to={urls.image_management()}>
            IMG
          </ShortCut>
        </WUDTooltip>
        <WUDTooltip id={`nav-admin-hlp`} content={'Help text'} place='bottom-end'>
          <ShortCut fontSize={0.75} to={'/'}>
            HLP
          </ShortCut>
        </WUDTooltip>
      </AdminContainer>
    </div>
  )
}

export default AdminSelection