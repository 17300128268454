import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ProjectActionsContainer } from './ProjectActionsStyles'

export default function ProjectActions() {
  return (
    <ProjectActionsContainer>
      <IframeConnect />
    </ProjectActionsContainer>
  )
}
