import { createSlice } from '@reduxjs/toolkit'
import { IStepsState } from '@/interfaces/types';

const initialState: IStepsState = {
  stepsList: { data: [], loading: false }
}

const stepsSlice = createSlice({
  name: 'stepsList',
  initialState,
  reducers: {
    setSteps: (state, action) => {
      state.stepsList = { data: action.payload, loading: false }
    },
    setStepsLoading: (state, action) => {
      state.stepsList = { ...state.stepsList, loading: action.payload }
    }
  }
})

export const {
  setSteps,
  setStepsLoading
} = stepsSlice.actions

export default stepsSlice.reducer