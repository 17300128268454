import styled from 'styled-components';

export const SearchBarContainer = styled.div`
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 1.25rem;
  padding: 0 0.75rem;
  display: flex;
  border: 1px solid var(--grey);
  box-sizing: border-box;
  flex: 1;

  @media (max-width: 1600px) {
    padding: 0 0.5rem;
  }
`;

export const SearchInput = styled.input`
  text-align: left;
  border: 1px solid transparent;
  background-color: var(--white);
  width: 100%;
  box-shadow: none !important;
  outline: none;
  padding: 0.25rem 0.5rem;
  color: var(--main);
  font-family: 'Montserrat', 'sans-serif';
  line-height: 0.7rem;
  font-size: 0.7rem;
  cursor: text;

  &::placeholder {
    color: var(--grey);
    font-family: 'Montserrat', 'sans-serif';
  }

  &:focus {
    border-color: transparent;
  }

  @media (max-width: 1600px) {
    font-size: 0.65rem;
  }
`;
