import React, { useEffect, useState, PointerEvent } from 'react'
import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, TouchSensor, UniqueIdentifier, closestCorners, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Description, HeaderContainer, Item, ScrollContainer, StepReorderContainer, ListItemsContainer, FooterContainer, Note } from './StepReorderStyles'
import { Label, Span } from '@/components/text/Text'
import { BulbIcon, CloseIcon, DragAndDropIcon } from '@/components/icons/Icons'
import { IStep } from '@/interfaces/types';
import { apiClient } from '@/api/extendedAPI'
import { useSessionContext } from '@/contexts/SessionContext'
import { useRequestHandler } from '@/hooks/useRequestHandler'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { Button } from '@/components/button/Button'

interface IReorderList {
  items: Array<IStep>;
  handleClose: () => void;
}

export const DraggableItem = ({ item }: { item: IStep }) => {

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  return (
    <Item ref={setNodeRef} {...attributes} {...listeners} style={style} >
      <DragAndDropIcon color={'var(--grey)'} />
      {item.name}
    </Item>
  )
}

export default function StepReorder({ items, handleClose }: IReorderList) {

  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const { currentProject } = useSelector((state: RootState) => state.core)
  const [list, setList] = useState(items)

  const getItemPosition = (id: UniqueIdentifier | undefined) => list.findIndex((item) => item.id === id)

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active?.id === over?.id) return;

    setList(list => {
      const originalPosition = getItemPosition(active?.id)
      const newPosition = getItemPosition(over?.id)

      return arrayMove(list, originalPosition, newPosition)
    })
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const reorderSteps = (projectId: string, stepsGuids: Array<string>) => {
    apiClient.reorderSteps(projectId, stepsGuids, session.data.accessToken,).then(
      (reorderSteps: { data: boolean, error: any }) => {
        const { data, error } = reorderSteps
        if (error === null) {
        } else {
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching reorderSteps:', error)
    })
  }

  const closeModal = () => {
    if (list !== items) {
      session.getProject(currentProject?.id ?? '')
    }
    handleClose()
  }

  const handleConfirm = async () => {
    console.log({ list, items })
    if (list !== items) {
      const stepGuids = list.map((item) => item.id)
      await reorderSteps(currentProject?.id ?? '', stepGuids)
      session.getProject(currentProject?.id ?? '')
      handleClose()
    } else {
      closeModal()
    }
  }

  return (
    <StepReorderContainer onClick={(event: PointerEvent<HTMLDivElement>) => event.stopPropagation()} >
      <div>
      <HeaderContainer>
        <Label fontSize={1.5}>
          Reorder steps
        </Label>
        <CloseIcon cursor={'pointer'} onClick={closeModal} $size={1.5} hover={'var(--secondary)'} />
      </HeaderContainer>
      <Description fontWeight='500'>
        Drag and drop to reorder steps
      </Description>
      <ScrollContainer>
        <DndContext autoScroll={{ threshold: { x: 0, y: 0 } }} sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners} >
          <ListItemsContainer>
            <SortableContext items={list} strategy={verticalListSortingStrategy} >
              {list.map((item, index) =>
                <DraggableItem key={item.id} item={item} />
              )}
            </SortableContext>
          </ListItemsContainer>
        </DndContext>
      </ScrollContainer>
      <Note color={'var(--secondary)'} $lineHeight={1.5} fontSize={1} ><BulbIcon $size={1} /> Note: <Span fontWeight='500' >Remember to take a look to the merge criterias after reorder the steps</Span></Note>
      </div>
      <div>
      <FooterContainer>
        <Button onClick={closeModal} $buttonType='secondary' text={'Cancel'} />
        <Button onClick={handleConfirm} $buttonType='primary' text={'Confirm'} />
      </FooterContainer>
      </div>
    </StepReorderContainer>
  )
}
