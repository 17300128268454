import React, { useState, useRef, useEffect, FC, RefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SearchBar from '@/components/searchbar/SearchBar';
import { AddIcon, ListIcon } from '@/components/icons/Icons';
import WUDTooltip from '@/components/tooltip/Tooltip';
import {
  ItemsContainer, HeaderContainer, EmptySearchContainer, EmptySearchLabel
} from './SearchableListStyles';
import SearchableItem from './SearchableItem';

interface SearchableListProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  items: any[];
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  type: string;
  isLink: boolean;
  getUrl: (parameter: any) => string;
  labelToSearch: string;
  headerActions?: Array<{ type: 'create' | 'list', link: string, action: () => void, disabled: boolean }>;
  isLoading?: boolean;
}

const SearchableList: FC<SearchableListProps> = ({
  isOpen, setIsOpen, items, setSelected, type, isLink, getUrl, labelToSearch, headerActions, isLoading = false
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [filteredItems, setFilteredItems] = useState<Array<any>>([]);
  const [query, setQuery] = useState("");
  const itemsContainerRef = useRef<HTMLUListElement>(null);

  const handleIconClick = (link: string, action: () => void) => {
    action()
    navigate(link)
  };

  const filter = (options: any[]) => {
    return options?.filter(
      (option) => option[labelToSearch]?.toLowerCase().indexOf(query?.toLowerCase()) > -1
    );
  };

  const getDisplayValue = () => {
    if (isOpen) {
      if (query) return query;
    }
    return '';
  };

  const handleSelect = (item: any, ref: RefObject<HTMLElement>) => {
    setSelected(item);
    const timeoutId = setTimeout(() => {
      setIsOpen(false);
      setQuery('');
      if (ref.current) {
        ref.current.scrollTop = 0;
      }
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  const emptySearchComponent = () => {
    return <EmptySearchContainer><EmptySearchLabel fontWeight={'normal'}>
      {t('common.search.emptyMessage', { type, query })}
    </EmptySearchLabel></EmptySearchContainer>;
  };

  const searchBarComponent = () => {
    return <SearchBar
      getDisplayValue={getDisplayValue}
      placeholder={t('common.search.placeholder', { type: type.replace(/s+$/, '') })}
      setQuery={setQuery}
      isOpen={isOpen}
      disabled={items?.length < 1} />;
  };

  const listItemsComponent = () => {
    return (
      (items?.length > 0 && filteredItems?.length > 0) ?
        filteredItems.map((item: any, index: number) =>
          <SearchableItem
            item={item}
            key={index}
            type={type}
            isLink={isLink}
            getUrl={getUrl}
            handleSelect={() => handleSelect(item, itemsContainerRef)}
          />
        )
        :
        (items?.length > 0 && !(filteredItems.length) && emptySearchComponent())
    );
  };

  useEffect(() => {
    const filtered = filter(items);
    if (filtered) {
      setFilteredItems(filtered);
    }
  }, [query, items]);

  useEffect(() => {
    const handleClickOutside = (event: PointerEvent) => {
      if (itemsContainerRef.current && !itemsContainerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setQuery('');
      }
    };
    document.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <ItemsContainer ref={itemsContainerRef} $open={isOpen} $account={type === 'clients' || type === 'projects'} role="listbox">
        <HeaderContainer>
          {searchBarComponent()}
          {headerActions?.map((item, index) => {
            switch (item.type) {
              case 'create':
                return (
                  <WUDTooltip id={`create-tooltip-${type}`} content={!item.disabled ? t('common.accordion.createNew', { type: type.replace(/s+$/, '') }) : ''} key={index} >
                    <AddIcon
                      $disabled={item.disabled}
                      onClick={() => !item.disabled && handleIconClick(item.link, item.action)}
                      $size={1}
                      hover={'var(--secondary)'}
                      cursor={'pointer'} />
                  </WUDTooltip>
                )
              case 'list':
                return <WUDTooltip id={`list-tooltip-${type}`} content={!item.disabled ? t('common.accordion.seeAll', { type }) : ''} key={index} >
                  <ListIcon
                    $disabled={item.disabled}
                    onClick={() => !item.disabled && handleIconClick(item.link, item.action)}
                    $size={1}
                    hover={'var(--secondary)'}
                    cursor={'pointer'} />
                </WUDTooltip>
              default:
                return <></>
            }
          })}
        </HeaderContainer>
        {isLoading ?
        <div style={{padding: '0 0.833vw'}}>
          <SkeletonTheme baseColor={'var(--secondary-lighter)'} highlightColor={'var(--grey-light)'}>
            <Skeleton height={'1.5rem'} count={5} style={{ margin: '0.25rem 0' }} />
          </SkeletonTheme>
        </div>


          : listItemsComponent()}
      </ItemsContainer >
    </>
  );
};

export default SearchableList;
