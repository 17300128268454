import { routes, urls } from '@/routes/Routes'
import Board from '@/components/board/Board'
import { useSessionContext } from '@/contexts/SessionContext'
import SubMenu from '@/components/menu/submenu/SubMenu'
import Home from './Home';
import BillingCsm from '../admin/billingCsm/BillingCsm';
import BillingSales from '../admin/billingSales/BillingSales';
import { tabTypes } from '@/constants/const';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useEffect } from 'react';
import { setCurrentTab } from '@/store/coreSlice';
import AdminBoard from '../admin/adminBoard/AdminBoard';
import { HomeLayoutContainer } from './HomeLayoutStyles';
import Modal from '@/components/modal/Modal';

interface HomeLayoutProps {
  toggleTab: number;
}

export enum ToggleHomeEnum {
  Home,
  CsmDashboard,
  SalesDashboard,
  Board
}

export default function HomeLayout({ toggleTab }: HomeLayoutProps) {

  const dispatch = useDispatch()
  const { data, modal } = useSessionContext().session
  const { currentTab } = useSelector((state: RootState) => state.core)
  const isAdmin = data.roles.find((role) => role?.toLowerCase().includes('administrator') || role?.toLowerCase().includes('timetracking'));
  const adminElements = [
    {
      name: 'Projects',
      component: <Home handleModal={modal.confirmModal} handleHideModal={modal.hideModal} />,
      url: routes.home
    },
    {
      name: 'CSM',
      component: <BillingCsm />,
      url: routes.dashboard_csm,
      isAdmin: true
    },
    {
      name: 'Sales',
      component: <BillingSales />,
      url: routes.dashboard_sales,
      isAdmin: true
    },
    {
      name: 'Board',
      component: <AdminBoard />,
      url: routes.admin_board,
      isAdmin: true
    },
  ]

  const elements = [
    {
      name: 'Projects',
      component: <Home handleModal={modal.confirmModal} handleHideModal={modal.hideModal} />,
      url: routes.home
    },
  ]
  const { component } = isAdmin ? adminElements[toggleTab] : elements[toggleTab]

  useEffect(() => {
    if (currentTab !== tabTypes.HOME)
      dispatch(setCurrentTab(tabTypes.HOME))
  }, [dispatch])

  return (
    <Board
      subMenu={<SubMenu
        currentSubTab={toggleTab}
        leftOption={<></>}
        elements={isAdmin ? adminElements : elements}
      />} >
      <HomeLayoutContainer>
        {component}
      </HomeLayoutContainer>
      {modal.modalVisible &&
        <Modal title={modal.modalContent.title} description={modal.modalContent.description} confirmAction={modal.modalContent.confirmAction} cancelAction={modal.modalContent.cancelAction} type='input' placeholder={modal.modalContent.placeholder} />
      }
    </Board>
  )
}
