import styled, { css } from 'styled-components';
import { Link } from '@/components/text/Text';

interface TabProps {
  $hover?: boolean;
  $selected?: boolean;
}

export const Tab = styled(Link) <TabProps>`
  background-color: var(--main);
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  text-align: center;
  padding: 0 4rem;
  display: flex;
  gap: 0.5rem;

  &:hover {
    background-color: var(--white);
    color: var(--main-dark);
    cursor: pointer;
  }

  ${(props) =>
    props.$hover &&
    css`
      background-color: var(--white);
      color: var(--main-dark);
      cursor: pointer;
    `}

  ${(props) =>
    props.$selected &&
    css`
      color: var(--main);
      background-color: var(--white);
    `}

  @media (max-width: 1600px) {
    padding: 0 2.5rem;
  }

  @media (max-width: 960px) {
    padding: 0 1rem;
  }
`;
