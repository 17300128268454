import { get, post, put, remove } from "./apiService";
import { WakeupDataGenerated as Base } from "./autogeneratedAPI";
import { IApiChannelsForPartners, IApiClient, IApiClientsForPartners, IApiPartners, IApiProject, IApiProjectDetails, IApiProjectsForPartners, IApiUsers, endpoints } from "./endpointsRoutes";

export interface IApiProjectDetailsResponse {
    data: IApiProjectDetails | undefined;
    error: any;
}

export interface IApiProjectsResponse {
    data: IApiProject[]
    error: any;
}

export interface IApiClientsResponse {
    data: IApiClient[]
    error: any;
}

export interface IApiPartnersResponse {
    data: IApiPartners[];
    error: any;
}

export interface IApiClientsForPartnersResponse {
    data: IApiClientsForPartners[];
    error: any;
}

export interface IApiProjectsForPartnersResponse {
    data: IApiProjectsForPartners[];
    error: any;
}

export interface IApiChannelsForPartnersResponse {
    data: IApiChannelsForPartners[];
    error: any;
}

export interface IApiUsersResponse {
    data: IApiUsers[];
    error: any;
}

export class WakeupDataConnectApiClient extends Base.WakeupDataConnectApiClientBase {

    /*  listProjects(): Promise<Base.ProjectListItem[]> {
 
         const fakeProjects: Base.ProjectListItem[] = [
             { projectName: "Project 1", importGuid: "guid1", steps: [], init: () => { }, toJSON: () => { } },
             {
                 projectName: "Project 2", importGuid: "guid2", steps: [], init: () => { },
                 toJSON: () => { }
             }
         ];
 
         return Promise.resolve<Base.ProjectListItem[]>(fakeProjects);
     } */
    /* 
        async authenticate(sessionKey: string): Promise<IAuthenticateResponse> {
            try {
                const data = await auth('/users', sessionKey, {});
                return { data }
            } catch (error: any) {
                console.error('Error fetching data:', error);
                return { error }
            }
        } */

    async listClients(token: string): Promise<IApiClientsResponse> {
        try {
            const data = await get(endpoints.list_clients(), token);
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: [], error: { message: error.message, status: error.status } }
        }
    }

    async listProjects2(userId: string, token: string): Promise<IApiProjectsResponse> {
        try {
            const data = await get(endpoints.list_projects({ userId }), token);
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: [], error: { message: error.message, status: error.status } }
        }
    }

    async getProject2(projectId: string, token: string): Promise<IApiProjectDetailsResponse> {
        try {
            const data = await get(endpoints.get_project({ projectId }), token);
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: undefined, error: { message: error.message, status: error.status } }
        }
    }

    async getRunningImports(token: string): Promise<{ data: number, error: any }> {
        try {
            const data = await get(endpoints.get_running_imports(), token);
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: -1, error: { message: error.message, status: error.status } }
        }
    }

    async getRunningCrawlings(token: string): Promise<{ data: number, error: any }> {
        try {
            const data = await get(endpoints.get_running_crawlers(), token);
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: -1, error: { message: error.message, status: error.status } }
        }
    }

    async listPartners(token: string): Promise<IApiPartnersResponse> {
        try {
            const data = await post(endpoints.get_partners(), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: [], error: { message: error.message, status: error.status } }
        }
    }

    async listClientsForPartners(partnerId: string, token: string): Promise<IApiClientsForPartnersResponse> {
        try {
            const data = await post(endpoints.list_clients_for_partner({ partnerId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: [], error: { message: error.message, status: error.status } }
        }
    }

    async listProjectsForPartners(userId: string, token: string): Promise<IApiProjectsForPartnersResponse> {
        try {
            const data = await post(endpoints.list_projects_for_partner({ userId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: [], error: { message: error.message, status: error.status } }
        }
    }

    async listChannelsForPartners(userId: string, projectId: string, token: string): Promise<IApiChannelsForPartnersResponse> {
        try {
            const data = await post(endpoints.list_channels_for_partner({ userId, projectId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: [], error: { message: error.message, status: error.status } }
        }
    }

    async listUsers(token: string): Promise<IApiUsersResponse> {
        try {
            const data = await get(endpoints.list_users(), token, {})
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: [], error: { message: error.message, status: error.status } }
        }
    }

    async getImportsNotScheduled(userId: string, token: string): Promise<{
        data: Array<{
            Company: string;
            Username: string;
            ImportGuid: string;
            Title: string;
            SavedRows: number;
            Active: boolean;
            InProgress: boolean;
            LastImportUTC: string;
        }>,
        error: any
    }> {
        try {
            const data = await get(endpoints.imports_not_scheduled({ userId }), token, {})
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: [], error: { message: error.message, status: error.status } }
        }
    }

    async renameStep(title: string, projectId: string, stepId: string, token: string): Promise<{ data: string, error: any }> {
        try {
            const data = await put(endpoints.rename_step(), token, { Title: title, ImportGuid: projectId, ImportStepGuid: stepId });
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: '', error: { message: error.message, status: error.status } }
        }
    }

    async renameProject(title: string, projectId: string, token: string): Promise<{ data: string, error: any }> {
        try {
            const data = await put(endpoints.rename_project(), token, { Title: title, ImportGuid: projectId });
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: '', error: { message: error.message, status: error.status } }
        }
    }

    async renameChannel(title: string, channelId: string, token: string): Promise<{ data: string, error: any }> {
        try {
            const data = await put(endpoints.rename_channel(), token, { Title: title, ExportGuid: channelId });
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: '', error: { message: error.message, status: error.status } }
        }
    }


    async getStepUrl(stepId: string, token: string): Promise<{ data: string, error: any }> {
        try {
            const data = await get(endpoints.get_step_url({ stepId }), token, {})
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: '', error: { message: error.message, status: error.status } }
        }
    }

    async updateStepUrl(url: string, stepId: string, token: string): Promise<{ data: string, error: any }> {
        try {
            const data = await put(endpoints.update_step_url(), token, { url, stepGuid: stepId });
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: '', error: { message: error.message, status: error.status } }
        }
    }

    async reorderSteps(projectId: string, stepGuids: Array<string>, token: string): Promise<{ data: boolean; error: any }> {
        try {
            const data = await post(endpoints.reorder_steps(), token, { ImportGuid: projectId, ImportStepGuids: stepGuids });
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: false, error: { message: error.message, status: error.status } }
        }
    }

    async logProjectModification(projectId: string, channelId: string | null, stepId: string | null, area: string, message: string, token: string): Promise<{ data: {}, error: any }> {
        try {
            const data = await post(endpoints.log_project_modification(), token, { importguid: projectId, importstepguid: stepId, exportguid: channelId, area, message });
            return { data, error: null }

        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async deleteStep(stepId: string, token: string): Promise<{ data: boolean, error: any }> {
        try {
            const data = await remove(endpoints.delete_step({ stepId }), token);
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: false, error: { message: error.message, status: error.status } }
        }
    }

    async disableProject(projectId: string, token: string): Promise<{ data: any, error: any }> {
        try {
            const data = await post(endpoints.disable_project({ projectId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async enableProject(projectId: string, token: string): Promise<{ data: any, error: any }> {
        try {
            const data = await post(endpoints.enable_project({ projectId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async deleteProject(projectId: string, token: string): Promise<{ data: any, error: any }> {
        try {
            const data = await post(endpoints.delete_project({ projectId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async disableChannel(channelId: string, token: string): Promise<{ data: any, error: any }> {
        try {
            const data = await post(endpoints.disable_channel({ channelId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async enableChannel(channelId: string, token: string): Promise<{ data: any, error: any }> {
        try {
            const data = await post(endpoints.enable_channel({ channelId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async deleteChannel(channelId: string, token: string): Promise<{ data: any, error: any }> {
        try {
            const data = await post(endpoints.delete_channel({ channelId }), token, {});
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

    async getStepDetails(importTypeOptionId: number, token: string): Promise<{ data: Object, error: any }> {
        try {
            const data = await get(endpoints.step_details({ importTypeOptionId }), token, {})
            return { data, error: null }
        } catch (error: any) {
            console.error('Error fetching data:', error);
            return { data: {}, error: { message: error.message, status: error.status } }
        }
    }

}

export interface ProjectListItem extends Base.IProjectListItem {

}

export const apiClient = new WakeupDataConnectApiClient()