import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ChannelMappingsContainer } from './ChannelMappingsStyles'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSteps, setStepsLoading } from '@/store/stepsSlice'
import { setChannels, setChannelsLoading } from '@/store/channelsSlice'
import { IApiProjectDetailsResponse, apiClient } from '@/api/extendedAPI'
import { useRequestHandler } from '@/hooks/useRequestHandler'
import { RootState } from '@/store/store'
import { IApiExports, IApiImportSteps } from '@/api/endpointsRoutes'
import { useSessionContext } from '@/contexts/SessionContext'
import { useQuery } from '@/hooks/useQuery'
import { setCurrentChannel } from '@/store/coreSlice'

export default function ChannelMappings() {
  const dispatch = useDispatch()
  const { importGuid, exportGuid } = useQuery()
  const { logoutUser } = useRequestHandler()
  const { session } = useSessionContext()
  const { currentProject, currentChannel } = useSelector((state: RootState) => state.core)
  const { channelsList } = useSelector((state: RootState) => state.channels)
  const intervalIdRef = useRef<NodeJS.Timer | number>()



  const getProject = async (projectId: string) => {
    dispatch(setStepsLoading(true))
    dispatch(setChannelsLoading(true))
    apiClient.getProject2(projectId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (project: IApiProjectDetailsResponse) => {
        const { data, error } = project
        if (!error) {
          dispatch(setChannels(data?.Exports.map((channel: IApiExports) => { return { id: channel.ExportGuid, name: channel.Title, type: channel.TemplateName ? `Template: ${channel.TemplateName}` : `Custom: ${channel.Type} - ${channel.SenderType}`, active: channel.Active, totalItems: channel.RowsExported, exportType: channel.TemplateName ? 'SetupTemplateGenerator' : 'SetupGenerator' } })))
          dispatch(setSteps(data?.ImportSteps.map((step: IApiImportSteps) => { return { id: step.ImportStepGuid, name: step.Title, sourceType: step.ParserType, stepNumber: step.StepNumber, merge: step.InsertMethod, importTypeOptionId: step.ImportTypeOptionId } })))
        } else {
          dispatch(setStepsLoading(false))
          dispatch(setChannelsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  useEffect(() => {

    const fetchChannelData = async () => {

      if (currentChannel) clearInterval(intervalIdRef.current)

      await getProject(currentProject?.id ?? importGuid() ?? '')

      if (!channelsList.loading && channelsList.data) {
        const channel = channelsList?.data.find((channel) => channel.id === exportGuid())
        if (channel) {
          dispatch(setCurrentChannel(channel))
          clearInterval(intervalIdRef.current)
        }
      }
    }

    if (currentChannel?.id !== exportGuid())
      intervalIdRef.current = setInterval(fetchChannelData, 5000)

    return () => {
      clearInterval(intervalIdRef.current)
    }

  }, [channelsList])


  return (
    <ChannelMappingsContainer>
      <IframeConnect height={82}/>
    </ChannelMappingsContainer>
  )
}