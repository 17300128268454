import styled, { css } from 'styled-components';
import { Label, Span } from '@/components/text/Text';

interface EmptySearchContainerProps { }

export const EmptySearchContainer = styled.div<EmptySearchContainerProps>`
`;

interface EmptySearchLabelProps { }

export const EmptySearchLabel = styled(Label) <EmptySearchLabelProps>`
`;

interface ItemsContainerProps {
  $custom?: boolean;
  $open?: boolean;
  $account?: boolean;
}

export const ItemsContainer = styled.ul<ItemsContainerProps & React.HTMLAttributes<HTMLUListElement>>`
  position: absolute;
  margin: 0;
  background-color: var(--white);
  list-style: none;
  width: ${props => props.$account ? '95%' : '92%'};
  max-width: 50vw;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  padding: 0; // 0.625rem;
  max-height: 0;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  border: 1px solid var(--grey);
  border-top: none;
  border-radius: 0 0 0.625rem 0.625rem;

  &:focus-within {
    //box-shadow: 0 16px 18px rgba(59, 118, 149, 0.6);
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--grey-light);
    border-radius: 25px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary-light);
    border-radius: 25px;
  }

  ${props =>
    props.$custom &&
    css`
      margin-top: 0;
    `}

  ${props =>
    props.$open &&
    css`
      opacity: 1;
      visibility: visible;
      max-height: 50vh;
    `}
`;

interface HeaderContainerProps { }

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 0.625rem;
  position: sticky;
  top: 0;
  z-index: 12;
  gap: 0.25rem;
`;

interface TooltipTextProps {
  $left?: string;
}

export const TooltipText = styled(Span) <TooltipTextProps>`
  position: absolute;
  background-color: #333;
  text-align: center;
  border-radius: 0.25rem;
  padding: 0.1rem 0.25rem;
  transition: opacity 0.3s;
  left: ${props => props.$left || '50%'};
  color: var(--white);
  font-size: 0.75rem;
  white-space: normal;
  opacity: 1;
  visibility: visible;
  z-index: 10;
`;

interface TooltipContainerProps { }

export const TooltipContainer = styled.div<TooltipContainerProps>`
  position: relative;
  display: inline-block;
  padding: 0 0.2rem;
  cursor: pointer;
  z-index: 8;

  &:hover > ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;
