import IframeConnect from '@/containers/iframeConnect/IframeConnect'
import { ProjectFieldConversionsContainer } from './ProjectFieldConversionsStyles'

export default function ProjectFieldConversions() {
  return (
    <ProjectFieldConversionsContainer>
      <IframeConnect />
    </ProjectFieldConversionsContainer>
  )
}
