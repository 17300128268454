import { Navigate, Outlet, useOutletContext } from "react-router";
import { SessionHookResult } from "./hooks/useSession"; // Import your session context
import { urls } from "./routes/Routes";
import { useSessionContext } from "./contexts/SessionContext";
import { useQuery } from "./hooks/useQuery";
import { useLocation } from "react-router-dom";

interface PublicRouteProps {
  element?: any;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const { session } = useSessionContext()
  const location = useLocation()

  // If token exists, redirect to home route
  if (session.data.accessToken && !location.state?.from) {
    return <Navigate to={urls.home()} replace />;
  }

  // Otherwise, render the provided element or outlet
  return element || <Outlet />;
};