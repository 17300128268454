import { useRef, FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon, ChevronUpIcon, ChevronDownIcon } from '@/components/icons/Icons';
import {
  AccordionContainer, AccordionSingleContent, AccordionContent, ArrowContainer, AccordionLabel, CustomAccordionContainer, LeftIconContainer, RightIconContainer, CustomSelect,
} from './SearchableDropdownStyles';
import SearchableList from './SearchableList';

interface SearchableDropdownProps {
  labelType?: 'default' | 'hidden' | 'medium' | 'none'; // to decide if I show or not the label. hidden to dont put the label and none with only the list of items
  isLink?: boolean;
  getUrl?: (parameter: any) => string;
  labelToSearch?: string;
  type: string;
  items: any[]; // replace 'any' with the actual type of 'items' if known
  selected: any; // replace 'any' with the actual type of 'selected' if known
  setSelected: Dispatch<SetStateAction<any>>; // replace 'any' with the actual type of 'setSelected' if known
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  iconSelected?: boolean;
  setIconSelected?: () => void; // replace with actual function signature
  headerActions?: Array<{ type: 'create' | 'list', link: string, action: () => void, disabled: boolean }>;
  isLoading?: boolean;
}

const SearchableDropdown: FC<SearchableDropdownProps> = (props) => {
  const {
    labelType = 'default',
    isLink = true,
    getUrl = () => '',
    labelToSearch = 'name', type, items, selected,
    setSelected, isOpen, setIsOpen, iconSelected, setIconSelected, headerActions = [],
    isLoading = false
  } = props;

  const { t } = useTranslation()
  const accordionRef = useRef(null);

  const handleOnClick = (value: boolean) => {
    setIsOpen(value)
  }

  const defaultComponent = () => {
    return (
      <>
        <AccordionContainer
          onClick={() => handleOnClick(!isOpen)} >
          <AccordionContent>
            <AccordionLabel color={'var(--white)'} fontWeight={'normal'} fontSize={0.7}>
              {selected ? t(`${type}.accountSelection.label`) : ''}
            </AccordionLabel>
            <AccordionLabel fontSize={0.75} color={'var(--white)'} >
              {selected?.[labelToSearch] ?? t(`${type}.accountSelection.placeholder`)}
            </AccordionLabel>
          </AccordionContent>
          <ArrowContainer>
            {(isOpen) ? <ChevronUpIcon color={'var(--white)'} /> : <ChevronDownIcon color={'var(--white)'} />}
          </ArrowContainer>
        </AccordionContainer>
        <SearchableList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          items={items}
          labelToSearch={labelToSearch}
          setSelected={setSelected}
          type={type}
          isLink={isLink}
          getUrl={getUrl}
          headerActions={headerActions}
          isLoading={isLoading}
        />
      </>
    );
  };

  const labelComponent = () => {
    return (
      <>
        <CustomAccordionContainer style={{ color: iconSelected ? 'var(--secondary)' : 'var(--main)' }}>
          <AccordionSingleContent onClick={() => handleOnClick(true)}>
            <HomeIcon onClick={setIconSelected} />
            <AccordionLabel $custom={true}>
              {selected?.[labelToSearch] ?? t(`${type}.accountSelection.placeholder`)}
            </AccordionLabel>
            {(isOpen) ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </AccordionSingleContent>
        </CustomAccordionContainer>
        <SearchableList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          items={items}
          labelToSearch={labelToSearch}
          setSelected={setSelected}
          type={type}
          isLink={isLink}
          getUrl={getUrl}
          headerActions={headerActions}
          isLoading={isLoading}
        />
      </>
    );
  };

  switch (labelType) {
    case 'hidden':
      return <CustomSelect
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        ref={accordionRef}
        $labelType={labelType}
      >
        {labelComponent()}
      </CustomSelect>
    case 'none':
      return <SearchableList
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        items={items}
        labelToSearch={labelToSearch}
        setSelected={setSelected}
        type={type}
        isLink={isLink}
        getUrl={getUrl}
        headerActions={headerActions}
        isLoading={isLoading}
      />
    case 'medium':
      return <CustomSelect
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        ref={accordionRef}
        $labelType={labelType}
      >
        {defaultComponent()}
      </CustomSelect>
    case 'default':
      return <CustomSelect
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        ref={accordionRef}
        $labelType={labelType}
      >
        {defaultComponent()}
      </CustomSelect>
    default:
      return <CustomSelect
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        ref={accordionRef}
        $labelType={labelType}
      >
        {defaultComponent()}
      </CustomSelect>
  }

};

export default SearchableDropdown;
