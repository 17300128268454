import styled from 'styled-components';
import { Label } from '@/components/text/Text';


export const ShopifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 1rem;
`

export const ShopifyTitle = styled(Label)`
  font-size: 2rem;
  line-height: 3rem;
`