function getUrl(url: string, vars: any) {
  let resultUrl = url;
  Object.keys(vars).forEach(key => {
    resultUrl = resultUrl.replace(':' + key, vars[key]);
  });
  return resultUrl;
}

const LIST_PARTNERS = ''
const LIST_CLIENTS = '/api/users/GetClientsForPartner'
const LIST_PROJECTS = '/api/imports/GetDashboard?api-version=2&userGuid=:userId&viewAllClientIntegrations=false&isSubaccount=false&userList=undefined&&{}'
const GET_PROJECT = '/api/imports?api-version=2&guid=:projectId&exports=100&importsteps=100&importversions=11&importversionsteps=0&schedules=100&coreType=true&getReceiverAndParserGuid=false&getImportState=false&importType=true&exportData=true&forceImport=false&getInsights=true&&{}'
const GET_RUNNING_IMPORTS = '/api/adminmonitoring/runningimports?api-version=1&&{}'
const GET_RUNNING_CRAWLERS = '/api/adminmonitoring/runningcrawlers?api-version=1&&{}'
// export const GET_CSMS = '/AccessManager/getcsms'
const GET_PARTNERS = '/AccessManager/getpartners'
const LIST_CLIENTS_FOR_PARTNER = '/AccessManager/GetClientsForPartner?partnerGuid=:partnerId'
const LIST_PROJECTS_FOR_PARTNER = '/AccessManager/GetImports?userGuid=:userId'
const LIST_CHANNELS_FOR_PARTNER = '/AccessManager/GetExports?userGuid=:userId&importGuid=:projectId'
const LIST_USERS = '/api/users/GetSubaccounts'
const LOG_PROJECT_MODIFICATION = '/Management/Log/AddPost'
const IMPORTS_NOT_SCHEDULED = '/api/feedoverview/importsnotscheduled?userguid=:userId&api-version=1&&{}' //GET Array of  {
/*  "Company": "WakeupData",
 "Username": "dvs@wakeupdata.com",
 "ImportGuid": "ff5594d8-634b-41b2-831e-e9bfbeecabe4",
 "Title": "Main project",
 "SavedRows": 200,
 "Active": true,
 "InProgress": true,
 "LastImportUTC": "2023-12-07T13:29:04"
}, */
const CHANNELS_BELOW_THRESHOLD = '/api/feedoverview/channelsBelowThreshold?userguid=:userId&api-version=1&&{}' // GET ARRay of idk
/* 
{
public string Company { get; set; }
public string Title { get; set; }
public string ExportGuid { get; set; }
public string ImportGuid { get; set; }
        }
*/
const IMPORTS_ZERO_RUNS = '/api/feedoverview/importszeroruns?userguid=:userId&api-version=1&&{}' // GET Array of idk
/* {
public string Company { get; set; }
public string Title { get; set; }
public int SavedRows { get; set; }
public bool Active { get; set; }
public string ImportGuid { get; set; }
public bool InProgress { get; set; }
public DateTime Timestamp { get; set; }
public int Savedrows2 { get; set; }
public DateTime Timestamp2 { get; set; }
 } */

/* PROJECT DISABLE, ENABLE, DELETE: NOT IMPLEMENTED IN THE EXTENDED API, NOT USING YET */
const PROJECT_RENAME = '/api/Imports/updatetitle/?api-version=2'
const PROJECT_DISABLE = '/api/Imports/ImportDisable/:projectId?api-version=2'
const PROJECT_ENABLE = '/api/Imports/ImportEnable/:projectId?api-version=2'
const PROJECT_DELETE = '/api/Imports/ImportDelete/:projectId?api-version=2'
/* STEPS */
const STEP_RENAME = '/api/Imports/updateimportsteptitle/?api-version=2'
const GET_STEP_URL = '/api/Imports/importStepGetUrl/:stepId?api-version=2&&{}'
const UPDATE_STEP_URL = '/api/Imports/importStepUpdateUrl?api-version=2'
const DELETE_STEP = '/api/imports/importsteps/:stepId?api-version=2 '
const REORDER_STEPS = '/api/imports/reorderSteps?api-version=2'
const GET_STEP_DETAILS = '/api/importtypeoption/:importTypeOptionId?api-version=1&&{}'
/* CHANNELS */
const CHANNEL_RENAME = '/api/Exports/updatetitle/?api-version=2'
const CHANNEL_DISABLE = '/data/exports/disableexport?exportguid=:channelId&api-version=2'
const CHANNEL_ENABLE = '/data/exports/enableexport?exportguid=:channelId&api-version=2'
const CHANNEL_DELETE = '/data/exports/deleteexport?exportguid=:channelId&api-version=2'


export const endpoints = {
  list_partners: () => '',
  list_clients: () => LIST_CLIENTS,
  list_projects: (parameters: { userId: string }) => getUrl(LIST_PROJECTS, parameters),
  get_project: (parameters: { projectId: string }) => getUrl(GET_PROJECT, parameters),
  get_running_imports: () => GET_RUNNING_IMPORTS,
  get_running_crawlers: () => GET_RUNNING_CRAWLERS,
  get_partners: () => GET_PARTNERS,
  list_clients_for_partner: (parameters: { partnerId: string }) => getUrl(LIST_CLIENTS_FOR_PARTNER, parameters),
  list_projects_for_partner: (parameters: { userId: string }) => getUrl(LIST_PROJECTS_FOR_PARTNER, parameters),
  list_channels_for_partner: (parameters: { userId: string, projectId: string }) => getUrl(LIST_CHANNELS_FOR_PARTNER, parameters),
  list_users: () => LIST_USERS,
  log_project_modification: () => LOG_PROJECT_MODIFICATION,
  imports_not_scheduled: (parameters: { userId: string }) => getUrl(IMPORTS_NOT_SCHEDULED, parameters),
  channels_below_threshold: (parameters: { userId: string }) => getUrl(CHANNELS_BELOW_THRESHOLD, parameters),
  imports_zero_runs: (parameters: { userId: string }) => getUrl(IMPORTS_ZERO_RUNS, parameters),
  rename_project: () => PROJECT_RENAME,
  disable_project: (parameters: { projectId: string }) => getUrl(PROJECT_DISABLE, parameters),
  enable_project: (parameters: { projectId: string }) => getUrl(PROJECT_ENABLE, parameters),
  delete_project: (parameters: { projectId: string }) => getUrl(PROJECT_DELETE, parameters),
  rename_step: () => STEP_RENAME,
  get_step_url: (parameters: { stepId: string }) => getUrl(GET_STEP_URL, parameters),
  update_step_url: () => UPDATE_STEP_URL,
  delete_step: (parameters: { stepId: string }) => getUrl(DELETE_STEP, parameters),
  reorder_steps: () => REORDER_STEPS,
  step_details: (parameters: { importTypeOptionId: number }) => getUrl(GET_STEP_DETAILS, parameters),
  rename_channel: () => CHANNEL_RENAME,
  disable_channel: (parameters: { channelId: string }) => getUrl(CHANNEL_DISABLE, parameters),
  enable_channel: (parameters: { channelId: string }) => getUrl(CHANNEL_ENABLE, parameters),
  delete_channel: (parameters: { channelId: string }) => getUrl(CHANNEL_DELETE, parameters),
}

export interface IApiClient {
  AccountType: number;
  ClientName: string | null;
  Company: string; // name in list
  Country: string | null;
  CreateDate: string;
  Deactivated: boolean;
  Email: string;
  Firstname: string;
  IsConfirmed: boolean | null;
  Lastname: string;
  Modified: string;
  PhoneNumber: string | null;
  PrimaryTimezone: string;
  ReferralCode: string;
  Referrer: string | null;
  Timezone: string | null;
  UserGuid: string; // id of the client
  UserName: string;
  UserType: string | null;
  UserVerificationCode: string;
  UserVerified: boolean;
  WebsiteIndustry: string | null;
}

export interface IApiProject {
  Active: boolean;
  Count: number;
  Created: string;
  Exports: Array<any>;
  ImportGuid: string;
  ImportTime: string;
  ImportTypeId: number;
  MinutesSince: number;
  Modified: string;
  Title: string;
  UseInsights: boolean;
}

export interface IApiProjectDetails {
  Active: boolean;
  CoreType: any;
  Created: string;
  Deleted: boolean;
  Description: string;
  EmailLevel: number;
  Exports: Array<IApiExports>;
  FCNewSetting: any;
  HasInsights: boolean;
  IfStopped: number;
  ImportGuid: string;
  ImportMappingSetting: any;
  ImportSteps: Array<IApiImportSteps>;
  ImportType: any;
  ImportTypeId: number;
  ImportTypeOption: any;
  ImportTypeOptionId: any;
  ImportVersions: any;
  MaxRows: number;
  Modified: string;
  MonitorLevel: number;
  RetryCount: number;
  RetryInterval: number;
  Schedules: Array<any>;
  StateHasExports: any;
  StateHasSchedules: any;
  StateHasSteps: any;
  StateHasVersions: any;
  StopIfRowCountBelow: number;
  Title: string;
  TrialEndDate: string;
}

export interface IApiExports {
  Active: boolean;
  Created: string;
  Deleted: boolean;
  Description: string;
  EmailLevel: number;
  ErrorCount: number;
  ExportGuid: string;
  LastCalled: any;
  MaxRows: number;
  MinutesSince: any;
  MonitorLevel: number;
  Posttransformations: boolean;
  RowsExported: number;
  RowsFiltered: number;
  SenderType: string;
  SenderTypeGuid: any;
  SummaryPassword: string;
  SummaryUrl: string;
  TemplateExportVersionGuid: any;
  TemplateExportVersionId: any;
  TemplateName: string;
  Title: string;
  Type: string;
  TypeGuid: any;
}

export interface IApiImportSteps {
  CombiningMehodCoreFieldCurrentGuid: string;
  CombiningMehodCoreFieldPreviousGuid: string;
  CombiningParserExpressionCurrent: any;
  CombiningParserExpressionPrevious: any;
  CustomGuid: string;
  Description: string;
  IfNoData: number;
  ImportStepGuid: string;
  ImportTypeOptionField1: string;
  ImportTypeOptionField2: string;
  ImportTypeOptionField3: string;
  ImportTypeOptionField4: string;
  ImportTypeOptionField5: string;
  ImportTypeOptionId: number;
  ImportVersions: any;
  InsertMethod: number;
  Operations: number;
  ParringType: any;
  ParserGuid: any;
  ParserType: string;
  Pretransformations: boolean;
  ReceiverGuid: any;
  ReceiverType: string;
  StateHasFilters: any;
  StateHasMappings: any;
  StepNumber: number;
  Title: string;
}

export interface IApiPartners {
  Key: string;
  Value: string;
}

export interface IApiClientsForPartners {
  Key: string;
  Value: string;
}

export interface IApiProjectsForPartners {
  Key: string;
  Value: string;
}

export interface IApiChannelsForPartners {
  Key: string;
  Value: string;
}

export interface IApiUsers {
  AccountType: number;
  ClientName: string | null;
  Company: string;
  Country: string | null;
  CreateDate: string;
  Deactivated: boolean;
  Email: string;
  Firstname: string;
  IsConfirmed: boolean | null;
  Lastname: string;
  Modified: string;
  PhoneNumber: string | null;
  PrimaryTimezone: string;
  ReferralCode: string;
  Referrer: string | null;
  Timezone: string | null;
  UserGuid: string;
  UserName: string;
  UserType: string | null;
  UserVerificationCode: string;
  UserVerified: boolean;
  WebsiteIndustry: string | null;
}