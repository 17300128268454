const { REACT_APP_BASE_CONNECT_URL } = process.env

const BASE_URL: string = REACT_APP_BASE_CONNECT_URL ?? 'https://beta.wakeupdata.com'

async function request(endpoint: string, options: RequestInit, token: string): Promise<any> {
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    ...options,
    headers: {
      ...(options.headers || {}),
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorResponse = await response.json();
    const error = new Error(`Request failed with status ${response.status}: ${JSON.stringify(errorResponse)}`);
    (error as any).status = response.status;
    throw error
  }
  return await response.json();
}


async function get(endpoint: string, token: string, params?: { [key: string]: string }): Promise<any> {
  const queryString = params ? `?${new URLSearchParams(params)}` : '';
  return await request(endpoint + queryString, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // Add any additional headers if needed
    },
  }, token);
}

async function post(endpoint: string, token: string, data: any): Promise<any> {
  return await request(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any additional headers if needed
    },
    body: JSON.stringify(data),
  }, token);
}

async function put(endpoint: string, token: string, data: any): Promise<any> {
  return await request(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      // Add any additional headers if needed
    },
    body: JSON.stringify(data),
  }, token);
}

async function remove(endpoint: string, token: string): Promise<any> {
  return await request(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      // Add any additional headers if needed
    },
    body: JSON.stringify(''),
  }, token);
}

export { get, post, put, remove };
