import { Dispatch, SetStateAction, useState, PointerEvent } from 'react';
import { routes } from '@/routes/Routes';
import { Label } from '../text/Text';
import ModalLayout from './ModalLayout';
import { ChartLineIcon, ChevronDownIcon, ChevronRightIcon, CloseIcon, FileCodeIcon, FileInvoiceIcon, GridIcon, ToolIcon } from '../icons/Icons';
import { FloatingElement, FloatingMenuContainer, FloatingMenuGrid, FloatingMenuGridItem, LeftContent, RightContent, SecondaryFloatingElement } from './FloatingMenuStyles';

export interface FloatingMenuProps {
  items: Array<{ icon: any, label: string, action: any }>
  onDismiss: Dispatch<SetStateAction<boolean>>
}

export default function FloatingMenu({ items, onDismiss }: FloatingMenuProps) {
  const [toolsMenuOpen, setToolsMenuOpen] = useState(false)

  const onToolsClick = (event: PointerEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setToolsMenuOpen(!toolsMenuOpen)
  }

  return (
    <ModalLayout onClick={onDismiss}>
      <div style={{ display: 'flex', gap: '1rem', position: 'absolute', alignItems: 'center', width: '100vw', justifyContent: 'center' }} >
        <FloatingMenuContainer>
          <FloatingElement to={routes.admin_board()} >
            <GridIcon color={'var(--main)'} />
            BOARD
          </FloatingElement>
          <FloatingElement to={routes.template_management_list()}>
            <FileCodeIcon $size={2} color={'var(--main)'} />
            TEMPLATE MANAGEMENT [NEW]
          </FloatingElement>
          <SecondaryFloatingElement onClick={onToolsClick}>
            <LeftContent>
              <ToolIcon $size={2} color={toolsMenuOpen ? 'var(--secondary)' : 'var(--main)'} />
              <Label color={toolsMenuOpen ? 'var(--secondary)' : 'var(--main)'} >
                TOOLS
              </Label>
            </LeftContent>
            <RightContent>
              {toolsMenuOpen ? <ChevronRightIcon $size={1.5} color={'var(--secondary)'} /> : <ChevronDownIcon $size={1.5} color={'var(--main)'} />}
            </RightContent>
          </SecondaryFloatingElement>
          <FloatingElement to={routes.dashboard_csm()} >
            <ChartLineIcon $size={2} color={'var(--main)'} />
            CSM
          </FloatingElement>
          <FloatingElement to={routes.dashboard_sales()} >
            <FileInvoiceIcon $size={2} color={'var(--main)'} />
            SALES
          </FloatingElement>
          <SecondaryFloatingElement>
            <CloseIcon $size={2} color={'var(--main)'} />
            <Label>
              CLOSE
            </Label>
          </SecondaryFloatingElement>
        </FloatingMenuContainer>
        {/* <FloatingMenuContainer>
        <FloatingElement to={routes.dashboard_sales()} >
          <FileInvoiceIcon $size={2} color={'var(--main)'} />
          SALES
        </FloatingElement>
      </FloatingMenuContainer> */}



        {toolsMenuOpen && <FloatingMenuGrid>
          <FloatingMenuGridItem to={routes.move_import()} >
            Move import between accounts
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.client_management()} >
            Manage users
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.client_management_new()} >
            Create user
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.shopify_projects()} >
            Shopify
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.css_management()} >
           CSS
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.manage_active_imports()} >
            Manage active imports
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.manage_crawlers()} >
            Manage crawlers
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.image_management()} >
            Manage image transformations
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.template_management()} >
            Template management [OLD]
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.platform_log()} >
            Platform log
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.tools()} >
            Tools
          </FloatingMenuGridItem>
          <FloatingMenuGridItem to={routes.help_texts()} >
            Help texts
          </FloatingMenuGridItem>
        </FloatingMenuGrid>}

      </div>
    </ModalLayout>
  )
}
