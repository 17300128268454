import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { setCurrentProject, setCurrentTab } from '@/store/coreSlice';
import { ToggleProjectCustomFieldsEnum, ToggleProjectMoreOptionsEnum, ToggleProjectReviewEnum, useProjectContent } from '@/hooks/useProjectContent';
import { useAccountSelection } from '@/hooks/useAccountSelection';
import { tabTypes } from '@/constants/const';
import Board from '@/components/board/Board';
import SubMenu from '@/components/menu/submenu/SubMenu';
import Modal from '@/components/modal/Modal';
import { ProjectLayoutContainer } from './ProjectLayoutStyles';
import { useQuery } from '@/hooks/useQuery'
import { Label } from '@/components/text/Text'
import { ClipboardIcon } from '@/components/icons/Icons'
import WUDTooltip from '@/components/tooltip/Tooltip'
import { useSessionContext } from '@/contexts/SessionContext';
import { setSteps, setStepsLoading } from '@/store/stepsSlice';
import { setChannels, setChannelsLoading } from '@/store/channelsSlice';
import { IApiProjectDetailsResponse, apiClient } from '@/api/extendedAPI';
import { IApiExports, IApiImportSteps } from '@/api/endpointsRoutes';
import { useRequestHandler } from '@/hooks/useRequestHandler';

interface ProjectLayoutProps {
  enumTab: ProjectTabs;
  toggleSubTab?: ToggleProjectReviewEnum ;
  toggleCFSubTab?: ToggleProjectCustomFieldsEnum;
  toggleMoreOptions: ToggleProjectMoreOptionsEnum;
}

export enum ProjectTabs {
  Home,
  ReviewData,
  FieldConversions,
  Actions,
  ImageTransformation,
  MoreOptions
}

const ProjectLayout = ({ enumTab, toggleSubTab, toggleCFSubTab, toggleMoreOptions }: ProjectLayoutProps) => {
  const { checkAndRedirect } = useAccountSelection()
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const [subTabs, megaTabs, { modalVisible, modalContent }] = useProjectContent(toggleSubTab ?? 0, toggleCFSubTab ?? 0, toggleMoreOptions )
  const dispatch = useDispatch()
  const { importGuid } = useQuery()
  const { currentTab, currentProject, currentClient } = useSelector((state: RootState) => state.core)
  const { component } = subTabs[enumTab]
  const guid = currentProject?.id ?? importGuid()

  const copyGuidToClipboard = () => {
    navigator.clipboard.writeText(guid ?? '');
  }

  const getProject = (projectId: string) => {
    dispatch(setStepsLoading(true))
    dispatch(setChannelsLoading(true))
    apiClient.getProject2(projectId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (project: IApiProjectDetailsResponse) => {
        const { data, error } = project
        if (!error && data) {
          dispatch(setChannels(data?.Exports.map((channel: IApiExports) => { return { id: channel.ExportGuid, name: channel.Title, type: channel.TemplateName ? `Template: ${channel.TemplateName}` : `Custom: ${channel.Type} - ${channel.SenderType}`, active: channel.Active, totalItems: channel.RowsExported, exportType: channel.TemplateName ? 'SetupTemplateGenerator' : 'SetupGenerator' } })))
          dispatch(setSteps(data?.ImportSteps.map((step: IApiImportSteps) => { return { id: step.ImportStepGuid, name: step.Title, sourceType: step.ParserType, stepNumber: step.StepNumber, merge: step.InsertMethod, importTypeOptionId: step.ImportTypeOptionId } })))
        } else {
          dispatch(setStepsLoading(false))
          dispatch(setChannelsLoading(false))
          if (error.status === 401) {
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  useEffect(() => {
    if (currentTab !== tabTypes.MANAGE)
    {
      dispatch(setCurrentTab(tabTypes.MANAGE))
    }
    getProject(currentProject?.id ?? importGuid() ?? '')
  }, [])

  return (
    <>
      <Board subMenu={<SubMenu
        currentSubTab={enumTab}
        elements={subTabs}
        megaTabs={megaTabs}
      />} >

        <ProjectLayoutContainer>
          {component}
        </ProjectLayoutContainer>
      </Board>
      {modalVisible &&
        <Modal title={modalContent.title} description={modalContent.description} confirmAction={modalContent.confirmAction} cancelAction={modalContent.cancelAction} type='input' placeholder={modalContent.placeholder} initialInputValue={modalContent.initialInputValue} hasClipboard={modalContent.hasClipboard} />
      }
      {/* {guid &&
        <div style={{
          backgroundColor: 'var(--grey)',
          position: 'absolute',
          bottom: '5vh',
          left: '4vw',
          display: 'flex',
          gap: '0.5rem',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          zIndex: 200,
          alignItems: 'center'
        }}>
          <Label>
            KEY:
          </Label>
          <Label fontWeight='500'>
            {guid}
          </Label>
          <WUDTooltip id='clipboard-guid' content='Copy guid to clipboard' >
            <div style={{ cursor: 'pointer', borderRadius: '0.25rem', border: '1px solid var(--secondary)', padding: '0.25rem 0.5rem', display: 'flex' }}>
              <ClipboardIcon $size={1.25} color={'var(--secondary)'} onClick={copyGuidToClipboard} />
            </div>
          </WUDTooltip>
        </div>
      } */}
    </>
  )
}

export default ProjectLayout;