import styled from 'styled-components';
import { Label } from '@/components/text/Text';

export const ProjectLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const ProjectName = styled(Label)`
  padding: 0.5rem 0;
  text-decoration: none;
  color: var(--main);
  font-weight: 700;
  font-size: 2rem;
`