import React from 'react';
import AccountSelection from './account/AccountSelection';
import TabSelection from './tabs/TabSelection';
import { MenuContainer, TabsContainer, TabsLeftContainer, TabsRightContainer } from './MenuStyles';

interface MenuProps {
}

const Menu: React.FC<MenuProps> = () => {

  return (

    <MenuContainer className='step-top-menu'>
      <TabsContainer>
        <TabsLeftContainer>
          <TabSelection />
        </TabsLeftContainer>
        <TabsRightContainer>
          <AccountSelection />
        </TabsRightContainer>
      </TabsContainer>
    </MenuContainer>
  )
};

export default Menu;
