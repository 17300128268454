import { Label, Link } from '@/components/text/Text';
import styled from 'styled-components';

export const AdminContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  width: 100%;
  gap: 0.5rem;
`
export const ShortCut = styled(Link)`
  display: flex;
  background-color: var(--grey);
  color: var(--main);
  width: 2.16rem;
  height: 2.16rem;
  border-radius: 1.08rem;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: var(--grey-light);
  }

  @media (max-width: 1600px) {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
  }

  @media (max-width: 800px) {
   display: none;
  }
`

export const ModalButton = styled(Label)`
  color: var(--white);
  background-color: var(--main-darker);
  padding: 0.25rem 0.261rem;
  border-radius: 0.25rem;
  cursor: pointer;

  &:hover {
    background-color: var(--main-dark);
  }

  @media (max-width: 1250px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 800px) {
   display: none;
  }

`

export const InfoBubble = styled.div<{ $backgroundColor?: string }>`
  background-color: ${props => props.$backgroundColor ?? 'var(--secondary)'};
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  cursor: default;

  @media (max-width: 800px) {
   display: none;
  }
`