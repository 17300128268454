import { createSlice } from '@reduxjs/toolkit'
import { IUsersState } from '@/interfaces/types';

const initialState: IUsersState = {
  usersList: { data: [], loading: false },
  errorsList: { data: [], loading: false },
}

const usersSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.usersList = { data: action.payload, loading: false }
    },
    setUsersLoading: (state, action) => {
      state.usersList = { ...state.usersList, loading: action.payload }
    },
    setProjectsErrors: (state, action) => {
      state.errorsList = { data: action.payload, loading: false }
    }
  }
})

export const {
  setUsers,
  setUsersLoading,
  setProjectsErrors
} = usersSlice.actions

export default usersSlice.reducer