import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IApiClientsResponse, IApiProjectDetailsResponse, IApiProjectsResponse, apiClient } from '@/api/extendedAPI'
import { RootState } from '@/store/store'
import { setCurrentChannel, setCurrentClient, setCurrentProject, setCurrentStep } from '@/store/coreSlice'
import { setSteps, setStepsLoading } from '@/store/stepsSlice'
import { setChannels, setChannelsLoading } from '@/store/channelsSlice'
import { setProjects, setProjectsLoading } from '@/store/projectsSlice'
import { urls } from '@/routes/Routes'
import { IClient, IProject } from '@/interfaces/types'
import { accountConst } from '@/constants/const'
import { DashedLine } from './AccountSelectionStyles'
import { useNavigate } from 'react-router'
import { useSessionContext } from '@/contexts/SessionContext'
import { setClients, setClientsLoading } from '@/store/clientsSlice'
import { IApiClient, IApiExports, IApiImportSteps, IApiProject, IApiProjectDetails } from '@/api/endpointsRoutes'
import SearchableDropdown from '@/components/searchableDropdown/SearchableDropdown'
import { useRequestHandler } from '@/hooks/useRequestHandler'
import { useQuery } from '@/hooks/useQuery'
import { setProjectsErrors } from '@/store/usersSlice'

export default function ClientSelection() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { session } = useSessionContext()
  const { logoutUser } = useRequestHandler()
  const { importGuid } = useQuery()
  const { clientsList } = useSelector((state: RootState) => state.clients)
  const { projectsList } = useSelector((state: RootState) => state.projects)
  const { currentClient, currentProject } = useSelector((state: RootState) => state.core)
  const [isClientsVisible, setIsClientsVisible] = useState(false);
  const [isProjectsVisible, setIsProjectsVisible] = useState(false);

  const listClients = () => {
    dispatch(setClientsLoading(true))
    apiClient.listClients(session.data.accessToken).then(
      (clients: IApiClientsResponse) => {
        const { data, error } = clients
        if (error === null) {
          const newClientsList = data?.map((client: IApiClient) => { return { id: client.UserGuid, name: client.Company, email: client.Email } })
          dispatch(setClients([{ id: session.data.userGuid, name: 'Your account', email: session.data.userName }, ...newClientsList]))
          if (!currentClient) dispatch(setCurrentClient({ id: session.data.userGuid, name: 'Your account' }))
        } else {
          dispatch(setClientsLoading(false))
          if (error.status === 401) {
            // NAVIGATE TO LOGOUT AND GET A PARAMETER WITH THE CURRENT URL TO REDIRECT AFTER LOGIN 
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching clients:', error)
    })
  }

  const listProjects = (userId: string) => {
    dispatch(setProjectsLoading(true))
    apiClient.listProjects2(userId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (projects: IApiProjectsResponse) => {
        const { data, error } = projects
        if (error === null) { dispatch(setProjects(data?.map((project: IApiProject) => { return { id: project.ImportGuid, name: project.Title, active: project.Active, totalItems: project.Count ? project.Count.toFixed() : 0, totalChannels: project.Exports.length } }))) }
        else {
          dispatch(setProjectsLoading(false))
          if (error.status === 401) {
            // NAVIGATE TO LOGOUT AND GET A PARAMETER WITH THE CURRENT URL TO REDIRECT AFTER LOGIN 
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching projects:', error)
    })
  }

  const getProject = (projectId: string) => {
    dispatch(setStepsLoading(true))
    dispatch(setChannelsLoading(true))
    apiClient.getProject2(projectId, session.data.accessToken).then( // activeUserGuid as a parameter ?
      (project: IApiProjectDetailsResponse) => {
        const { data, error } = project
        if (!error) {
          dispatch(setChannels(data?.Exports.map((channel: IApiExports) => { return { id: channel.ExportGuid, name: channel.Title, type: channel.TemplateName ? `Template: ${channel.TemplateName}` : `Custom: ${channel.Type} - ${channel.SenderType}`, active: channel.Active, totalItems: channel.RowsExported, exportType: channel.TemplateName ? 'SetupTemplateGenerator' : 'SetupGenerator' } })))
          dispatch(setSteps(data?.ImportSteps.map((step: IApiImportSteps) => { return { id: step.ImportStepGuid, name: step.Title, sourceType: step.ParserType, stepNumber: step.StepNumber, merge: step.InsertMethod, importTypeOptionId: step.ImportTypeOptionId } })))
        } else {
          dispatch(setStepsLoading(false))
          dispatch(setChannelsLoading(false))
          if (error.status === 401) {
            // NAVIGATE TO LOGOUT AND GET A PARAMETER WITH THE CURRENT URL TO REDIRECT AFTER LOGIN 
            logoutUser()
          }
        }
      }
    ).catch((error) => {
      console.error('Error fetching a project:', error)
    })
  }

  const selectClient = async (client: IClient) => {
    dispatch(setCurrentClient(client))
    dispatch(setCurrentProject(null))
    navigate(urls.connect_active_user({ clientId: client.id }))
  }

  const selectProject = async (project: IProject) => {
    if (session.data.accessToken) {
      getProject(project.id)
      dispatch(setCurrentProject(project))
      dispatch(setCurrentStep(null))
      dispatch(setCurrentChannel(null))
    }

  }

  useEffect(() => {
    if (session.data.accessToken) listClients()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (session.data.accessToken && currentClient && !currentProject) {
      listProjects(currentClient.id)
      setIsProjectsVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient, currentProject])

  useEffect(() => {
    if (importGuid()) {
      const project = projectsList?.data.find((item, index) => item.id === importGuid())
      if (project) {
        dispatch(setCurrentProject(project))
        selectProject(project)
      }
    } else {
      dispatch(setCurrentProject(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsList])

  useEffect(() => {
    if (projectsList.data.length) {
      apiClient.getImportsNotScheduled(session.data.userGuid, session.data.accessToken).then(
        (importIssues: any) => {
          const { data, error } = importIssues
          if (!error && data.length) {
            const guidsErrors = data.map((item: any) => item.ImportGuid)
            dispatch(setProjectsErrors(guidsErrors))
          } else {
            if (error.status === 401) {
              logoutUser()
            }
          }
        }
      ).catch((error) => {
        console.error('Error fetching getImportsNotScheduled:', error)
      })
    }
  }, [projectsList])

  return (
    <div className='step-top-menu-client-project-selection' style={{ display: 'flex' }}>
      <SearchableDropdown
        type={accountConst.CLIENTS}
        isLink={false}
        items={clientsList.data}
        selected={currentClient}
        setSelected={selectClient}
        isOpen={isClientsVisible}
        setIsOpen={setIsClientsVisible}
        headerActions={[{ type: 'list', link: urls.home(), action: () => null, disabled: true }, { type: 'create', link: urls.home(), action: () => null, disabled: true, }]}
        isLoading={clientsList.loading}
      />
      {currentClient && <DashedLine />}
      <SearchableDropdown
        type={accountConst.PROJECTS}
        isLink={true}
        getUrl={(item: any) => urls.project({ projectId: item.id })}
        items={projectsList.data}
        selected={currentProject}
        setSelected={selectProject}
        isOpen={isProjectsVisible}
        setIsOpen={setIsProjectsVisible}
        headerActions={[{ type: 'list', link: urls.home(), action: () => null, disabled: false }, { type: 'create', link: urls.project_new(), action: () => null, disabled: false, }]}
        isLoading={projectsList.loading}
      />
    </div>
  )
}
